
import moment from 'moment';
import ApiService from '@/services/settings-service.js';
// import DualListBox from 'dual-listbox-vue';
// import 'dual-listbox-vue/dist/dual-listbox.css';

export default {
  filters: {
    formatYear(date) {
      return moment(date).format('YYYY');
    },
  },
  components: {
    // DualListBox,
  },
  props: {
    value: Boolean,
    updateValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 4),
      menu: false,
      isAdd: false,
      // 3333
      vehicleGroupId: '',
      groupName: '',
      department: '',
      groupDescription: '',
      id: '',
      // test
      departmentOption: [],
      checkDepartmentOption: [],
      filterInput: '',
      filterTypeInput: '',
      // testttt
      source: [],
      // destination: [],
      vGroup: [],
      rules: {
        required: (value) => !!value || this.$t('Required'),
      },
    };
  },
  computed: {
    myList: {
      get() {
        return this.$store.state.myList;
      },
      set(value) {
        this.$store.commit('updateList', value);
      },
    },
    Active() {
      return [
        { text: this.$t('Setting.Active'), value: true },
        { text: this.$t('Setting.Inactive'), value: false },
      ];
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    show(val) {
      this.$refs.form.resetValidation();
      this.vehicleGroupId = '';
      this.groupName = '';
      this.groupDescription = '';
      this.department = '';
      this.source = [];
      this.destination = [];
      this.id = '';
      if (val) {
        if (Object.keys(this.updateValue).length === 0) {
          this.isAdd = true;
          ApiService.getDrivers(this.$axios).then((response) => {
            this.source = response.items.map((d) => {
              return { driverId: d.id, name: d.name };
            });

            this.$nextTick(() => {
              this.vGroup = null;
            });
          });
        } else {
          ApiService.getDriverGroupsID(this.$axios, this.updateValue.id).then(
            (response) => {
              this.source = response.total;
              this.vGroup = response.selected;
            }
          );
          this.id = this.updateValue.id;
          this.vehicleGroupId = this.updateValue.companyGroupId;
          this.groupName = this.updateValue.name;
          this.groupDescription = this.updateValue.description;
          this.department = this.updateValue.companyGroupId;
        }
        this.getCompanyGroups();
      } else {
        this.isAdd = false;
        this.$emit('clearUpdateValue');
      }
    },
    filterInput(value) {
      this.departmentOption = this.checkDepartmentOption;
      this.departmentOption = this.departmentOption.filter((post) => {
        return post.text.toLowerCase().trim().includes(value.toLowerCase());
      });
    },
  },
  mounted() {
    // this.getCompanyGroups();
  },
  methods: {
    saveYear(year) {
      this.$refs.menu.save(year);
      this.$refs.picker.activePicker = 'YEAR';
      this.menu = false;
    },
    getCompanyGroups() {
      ApiService.getCompanyGroups(this.$axios)
        .then((response) => {
          this.departmentOption = response.items.map((element) => ({
            text: element.name,
            value: element.id,
          }));
          /*
          response.items.forEach((element) => {
            this.departmentOption.push({
              text: element.name,
              value: element.id,
            });
          });
          */
        })
        .catch({});
      this.checkDepartmentOption = this.departmentOption;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const query = {
          name: this.groupName,
          description: this.groupDescription,
          companyGroupId: this.department ? this.department : null,
        };
        // const data = this.selectedDriverLists(this.vGroup);
        let resp;
        let msg = '';
        if (this.isAdd) {
          console.log('add');
          // console.log(query);
          resp = await ApiService.addDriverGroup(this.$axios, query);
        } else {
          console.log('update');
          query.id = this.id;
          // console.log(query);
          resp = await ApiService.updateDriverGroupDetails(this.$axios, query);
        }
        if (!resp.success) {
          this.$emit('forceRerenderOnSubmit', resp);
          return;
        } else if (this.isAdd) {
          this.id = resp.message;
        } else {
          msg += resp.message;
        }

        let data = this.vGroup || [];

        if (data.length > 0) {
          if (data[0] instanceof Object) {
            data = data.map((g) => g.driverId);
          }
        }
        resp = await ApiService.updateDriverGroupsID(
          this.$axios,
          this.id,
          data
        );

        if (resp.success) {
          this.show = false;
        }
        if (msg !== '') {
          msg += ' , ';
        }
        msg += resp.message;
        this.$emit('forceRerenderOnSubmit', { message: msg });
      }
    },
    onChangeList({ source, destination }) {
      this.source = source;
      this.destination = destination;
    },
    selectedDriverLists(array) {
      // eslint-disable-next-line
      let driverList = [];
      array.forEach((element) => {
        driverList.push(element.driverId);
      });
      return driverList;
    },
  },
};
