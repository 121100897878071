
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import ApiService from '@/services/api-service.js';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { guageColor } from '@/plugins/config';
import SpeedingWarningList from '@/components/SpeedingWarningList';
import TDRoutesOnMap from '@/components/TDRoutesOnMap';

export default {
  name: 'WarningHeatMap',
  components: {
    TDRoutesOnMap,
    SpeedingWarningList,
    Gmap: () => {
      if (process.client) {
        return import('@/components/SpeedingGoogleMap');
      }
    },
    Treeselect: () => {
      if (process.client) {
        return import('@riophae/vue-treeselect');
      }
    },
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  data() {
    return {
      panel: 0,
      wayListCheckAllWarningDialog: false,
      wayExceedMaxAllowSelectionDialog: false,
      speedLimitQuery: 50,
      selectAllWays: false,
      activeWarningsListWayId: null,
      query: {},
      selectedWayColor: [],
      // wayWarningLocations: [],
      warningLocationsByWayId: {},
      circlesData: [],
      tabs: null,
      waySummaryTabs: [],
      checkedWays: {},
      wayList: [],
      warningPercentage: 20.0,
      warningCount: 10,
      waylistSortBy: 'warningPctg',
      dateRange: null,
      dateRangeKey: 0,
      drawerListLoading: false,
      warningComboBoxKey: 0,
      setOptionsCalled: false, // hack to prevent called twice

      usedTagColor: [],
      // ==============================
      banner: false,
      leftDrawer: true,
      getWarning: [],

      optionType: [],
      optionsets: [],
      getOptions: null,
      optionsList: [],

      normalizer(node) {
        if (node.children == null || node.children.length === 0) {
          delete node.children;
        }
        return {
          id: node.name,
          label: node.name,
          children: node.children,
        };
      },
      noRecord: false,
      getheatList: [],
    };
  },
  computed: {
    wayListDownloadCsv: {
      get() {
        return this.convertToCsv(this.wayList);
      },
    },
    waylistSortByOptions: {
      get() {
        return ['warningPctg', 'warningCount'].map((w) => ({
          text: this.$t(`SpeedingHeatMap.${w}`),
          value: w,
        }));
      },
    },
    // ===================================
    likesAllItems() {
      return this.getOptions.length === this.optionsList.length;
    },
    likesSomeItems() {
      return this.getOptions.length > 0 && !this.likesAllItems;
    },

    icon() {
      if (this.likesAllItems) return 'mdi-close-box';

      if (this.likesSomeItems) return 'mdi-minus-box';

      return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    waySummaryTabs(val) {
      this.banner = val.length > 0;

      const oldSelectedWayColor = this.selectedWayColor.slice();
      this.selectedWayColor = [];
      this.usedTagColor = [];
      for (const ind in val) {
        for (const old of oldSelectedWayColor) {
          if (old.itemId === val[ind].wayId) {
            this.selectedWayColor.push(old);
            this.usedTagColor.push(old.color);
            break;
          }
        }
      }

      if (val.length === 0) {
        this.warningLocationsByWayId = {};
      }
    },
    '$store.state.app.locale'(val) {
      // to trigger re render to call api to get list
      this.warningComboBoxKey += 1;
    },
    selectAllWays(checked) {
      if (checked) {
        this.wayListCheckAllWarningDialog = true;
      } else {
        for (const key of Object.keys(this.checkedWays)) {
          this.checkedWays[key] = false;
        }
        this.wayItemChecked();
      }
    },
    // ===================================
    optionType(val) {
      //! should be useless
      // this.optionsList = [];
      // ! because single selection
      // this.getOptions = [];
      // this.getOptions = null;
      // if (
      //   val.value === 'driverdepartment' ||
      //   val.value === 'vehicledepartment'
      // ) {
      //   this.getOptions = null;
      // } else {
      //   this.getOptions = [];
      // }
    },
    '$store.state.app.company'(val) {
      this.optionType = [];
      this.setOptions('company');
      // to trigger re render to call api to get list
      this.warningComboBoxKey += 1;
    },
    tabs(val) {
      console.log('tab change to %d', val);
      this.activeWarningsListWayId = this.waySummaryTabs[val]?.wayId;
      console.log('shown tab: %d %s', val, this.activeWarningsListWayId);
    },
  },
  mounted() {
    this.setOptions('mounted');
  },
  methods: {
    dummy() {
      console.log(this.getWarning);
      this.getWarning = ['SPW_L'];
      this.dateRange = {
        type: 'day',
        date: moment('2022-09-13', 'YYYY-MM-DD').toDate(),
      };
      this.dateRangeKey += 1;
    },

    closeDialog(yes) {
      this.wayListCheckAllWarningDialog = false;
      if (yes) {
        const checked = this.selectAllWays;
        if (checked) {
          for (const way of this.wayList) {
            this.checkedWays[way.wayId] = true;
          }
        } else {
          for (const key of Object.keys(this.checkedWays)) {
            this.checkedWays[key] = false;
          }
        }
        this.wayItemChecked();
      } else {
        this.selectAllWays = false;
      }
    },
    openNewTab(data) {
      console.log(data);
      let href = window.location.href;
      let newQuery = {
        TargetType: data.type,
        Date: moment(this.dateRange.date).format('YYYY-MM-DD'),
        ProfileType: this.dateRange.type,
      };

      if (data.tab === 'spwheatmap') {
        newQuery = {
          ...newQuery,
          ...{
            WarningTypes: this.getWarning,
            TargetItems: [data.id],
            SortBy: this.waylistSortBy,
            //* 256
            // default to 0
            WarningCount: 0, // parseInt(this.warningCount),
            // default to 0
            WarningPctg: 0, // parseFloat(this.warningPercentage),
            SpeedLimit: parseInt(this.speedLimitQuery),
          },
        };
      } else {
        //* profile query
        // console.log(window.location);
        href = window.location.origin + '/Profile';
        newQuery = {
          ...newQuery,
          ...{
            TargetId: data.id,
            OutputType: data.type,
            Distance: 20,
          },
        };
      }
      const opener = 'spwheatmap';
      localStorage.setItem('clickedQuery', JSON.stringify(newQuery));
      window.open(href, opener);
      console.log(href, newQuery);
    },
    circleClick(way) {
      // TODO may remove, because overlapping problem
      this.wayClicked({ item: way });
    },
    convertToCsv(items) {
      const _this = this;
      return items.map((i) => {
        const addr =
          _this.$store.state.app.locale === 'en' ? i.address_EN : i.address_HK;
        const retObj = {};

        retObj[_this.$t('SpeedingHeatMap.wayId')] = i.wayId;
        retObj[_this.$t('SpeedingHeatMap.wayAddress')] = addr ?? '';
        retObj[_this.$t('SpeedingHeatMap.lat')] = i.lat;
        retObj[_this.$t('SpeedingHeatMap.lon')] = i.lon;
        retObj[_this.$t('SpeedingHeatMap.warningCount')] = i.warningCount;
        retObj[_this.$t('SpeedingHeatMap.ViaCount')] = i.passingCount;
        retObj[_this.$t('SpeedingHeatMap.warningPctg')] = `${i.warningPctg}%`;
        retObj[_this.$t('speedLimit')] = i.speedLimit;

        return retObj;
      });
    },
    setMarkerDataForGmap(data) {
      // this.wayWarningLocations.push(data);

      const addObj = {};
      addObj[data.wayId] = data;
      this.warningLocationsByWayId = {
        ...this.warningLocationsByWayId,
        ...addObj,
      };

      console.log('setMarkerDataForGmap');
    },
    getTagColor(index) {
      let c = guageColor[index];
      let i = index;
      while (this.usedTagColor.includes(c)) {
        i = i + 1;
        if (i >= 4) i = 0;
        c = guageColor[i];
      }
      this.usedTagColor.push(c);
      return c;
    },
    removeWayTab(wayId, index) {
      this.waySummaryTabs.splice(index, 1);
      // this.wayWarningLocations.splice(index, 1);

      const tempobj = { ...this.warningLocationsByWayId };
      delete tempobj[wayId];
      this.warningLocationsByWayId = { ...tempobj };
    },
    wayClicked(item) {
      // * add to show in tab
      if (this.waySummaryTabs.length >= 4) {
        this.wayExceedMaxAllowSelectionDialog = true;
        return false;
      }
      let shouldAdd = true;
      for (const tab of this.waySummaryTabs) {
        if (tab.wayId === item.item.wayId) {
          shouldAdd = false;
          break;
        }
      }
      if (shouldAdd) {
        this.waySummaryTabs.push(item.item);
        this.tabs = this.waySummaryTabs.length - 1;
        this.activeWarningsListWayId = this.waySummaryTabs[this.tabs]?.wayId;
        this.selectedWayColor.push({
          itemId: item.item.wayId,
          color: this.getTagColor(this.tabs),
        });
      }
    },
    wayItemChecked(item) {
      // console.log(this.checkedWays);
      // console.log(item);
      const selectedIds = Object.keys(this.checkedWays).filter(
        (id) => this.checkedWays[id]
      );
      // console.log(selectedIds);
      const selectedWays = this.wayList.filter((w) =>
        selectedIds.includes(w.wayId)
      );

      const maxWarningCount = Math.max(
        ...selectedWays.map((w) => w.warningCount)
      );

      this.circlesData = selectedWays.map((w) => {
        //* 2km is 100%
        const fullRadius = 500;
        const min = 100;
        // TODO if want to compare only selected, should use selected max
        // TODO otherwise use all way,
        // TODO use limited max , or proportional max ?
        w.info = `${w.warningPctg}%`;
        w.radius =
          min + ((fullRadius - min) * w.warningCount) / maxWarningCount;
        w.center = { lat: w.lat, lng: w.lon };
        w.fillColor =
          w.warningPctg <= 50
            ? //* 255 change color
              '#B300F5' // '#00FFFF' // '#FFFF00'
            : w.warningPctg <= 90
            ? '#F50000' // '#FF8C00' // darkorange
            : '#000000'; // '#FF0000';
        return w;
      });
      // console.log(this.circlesData);
    },
    // ===================================

    getLang(item) {
      return this.$t(item.text);
    },
    onChange() {
      if (this.optionType.value !== 'company') {
        this.getOptionList(this.optionType.url);
      }
    },

    getOptionList(url) {
      ApiService.getWarningDataOptionList(this.$axios, url).then((response) => {
        if (response.items) {
          this.optionsList = [];
          response.items.forEach((item) => {
            this.optionsList.push(item);
          });
        }
      });
    },

    selectAll() {
      this.$nextTick(() => {
        if (this.likesAllItems) {
          this.getOptions = [];
        } else {
          this.getOptions = this.optionsList.slice();
        }
      });
    },

    setOptions(from) {
      //! set company also call, so quick hack to prevent
      if (this.setOptionsCalled) return;
      this.setOptionsCalled = true;
      console.log('setOptions from %s', from);
      this.optionsets = [];
      this.optionsets.push({
        text: 'Company',
        value: 'company',
        type: 'company',
        url: '',
        active: true,
        disabled: false,
      });
      ApiService.getWarningDataOptions(this.$axios).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            switch (item.name) {
              case 'Driver':
              case 'Driver Group':
              case 'Driver Department':
              case 'Vehicle':
              case 'Vehicle Group':
              case 'Vehicle Department':
                this.optionsets.push({
                  text: item.name,
                  value: item.value,
                  type: item.type,
                  url: item.url,
                  active: true,
                  disabled: false,
                });
                break;
              default:
                break;
            }
          });

          if (window.opener === null) {
            this.optionType = this.optionsets[0];
            // this.submit();
          } else {
            this.queryFromParent();
          }
        }
      });
    },
    async queryFromParent() {
      console.log('queryFromParent');
      try {
        const clickedQuery = JSON.parse(localStorage.clickedQuery);
        const optionType = {
          text:
            clickedQuery.TargetType.charAt(0).toUpperCase() +
            clickedQuery.TargetType.slice(1),
          value: clickedQuery.TargetType,
          type: 'DropDown',
        };
        if (clickedQuery.TargetType === 'vehicle') {
          optionType.url = '/api/warningData/GetVehicleList';
          optionType.parameter = 'VehicleFilter';
        } else if (clickedQuery.TargetType === 'driver') {
          optionType.url = '/api/warningData/GetDriverList';
          optionType.parameter = 'DriverFilter';
        } else {
          console.error('only for vehicle or driver');
          return;
        }

        this.optionType = optionType;
        // this.optionsList = clickedQuery.TargetItems;
        const resp = await ApiService.getWarningDataOptionList(
          this.$axios,
          optionType.url
        );
        this.optionsList = resp.items;

        this.getOptions = clickedQuery.TargetItems[0];
        //*  above set the option like driver or vehicle

        this.waylistSortBy = clickedQuery.SortBy;
        this.warningCount = clickedQuery.WarningCount;
        this.warningPercentage = clickedQuery.WarningPctg;
        this.speedLimitQuery = clickedQuery.SpeedLimit;

        this.getWarning = clickedQuery.WarningTypes;
        this.warningComboBoxKey += 1; // re render

        this.dateRange = {
          type: clickedQuery.ProfileType,
          date: moment(clickedQuery.Date, 'YYYY-MM-DD').toDate(),
        };
        this.dateRangeKey += 1; // re render

        this.submit(clickedQuery);
      } catch (e) {
        console.error(e);
      }
    },
    async submit(_qry) {
      this.checkForm();
      if (this.valid) {
        let qry;
        console.log(_qry);
        if (_qry === undefined || _qry instanceof PointerEvent) {
          const query = {
            TargetType: this.optionType.value,
            Date: moment(this.dateRange.date).format('YYYY-MM-DD'),
            WarningTypes: this.getWarning,
          };

          query.TargetItems = [this.getOptions];
          query.SortBy = this.waylistSortBy;
          query.WarningCount = parseInt(this.warningCount);
          query.WarningPctg = parseFloat(this.warningPercentage);
          query.ProfileType = this.dateRange.type;
          query.SpeedLimit = parseInt(this.speedLimitQuery);
          qry = query;
        } else {
          qry = _qry;
        }

        //* save this query only for warninglist
        this.query = qry;

        this.drawerListLoading = true;
        this.selectAllWays = false;
        this.waySummaryTabs = [];

        const response = await ApiService.getWayList(this.$axios, qry);
        this.drawerListLoading = false;
        this.wayList = response;

        //! no need default select all
        // this.selectAllWays = true;

        // No record
        this.noRecord = true;
        if (this.wayList?.length > 0) {
          this.noRecord = false;
          this.panel = -1;
        } else {
          // this.getCluster(this.getheatList);
        }
      }
    },
    checkForm() {
      this.valid = true;
      if (!this.optionType.value) {
        this.valid = false;
      }

      if (!this.dateRange.date) {
        this.valid = false;
      }
      /*
      if (!this.dateRange.range[1]) {
        this.valid = false;
      }
      */
    },
  },
};
