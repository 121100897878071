
import Footer from '@/components/core/Footer';
export default {
  name: 'CoreView',
  components: {
    CoreFooter: Footer,
  },

  // metaInfo() {
  //   return {
  //     title: 'Vue Material Dashboard by CreativeTim',
  //   };
  // },
};
