
// import moment from 'moment';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      QueryString: '',
      km: 20,
      queryType: 'day',
      queryTypeGroup: [],
      mode: 'date',
      formatDate: 'YYYY-MM-DD',
      time: new Date(new Date().setDate(new Date().getDate() - 1)),
    };
  },
  watch: {
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setOptions();
      this.sendForm();
    },
    company(val) {
      if (val) {
        this.sendForm();
      }
    },
    queryType(val) {
      if (val === 'day') {
        this.mode = 'date';
        this.formatDate = 'YYYY-MM-DD';
      } else if (val === 'week') {
        this.mode = 'week';
        this.formatDate = 'YYYY ww';
      } else if (val === 'month') {
        this.mode = 'month';
        this.formatDate = 'YYYY-MM';
      } else if (val === 'year') {
        this.mode = 'year';
        this.formatDate = 'YYYY';
      }
      this.sendForm();
    },
    time(val) {
      if (val) {
        this.sendForm();
      }
    },
    km(val) {
      if (val) {
        this.sendForm();
      }
    },
  },
  mounted() {
    this.setOptions();

    this.sendForm();
  },
  methods: {
    ...mapMutations('app', ['get_Lang']),
    getLang() {
      this.get_Lang();
    },
    setOptions() {
      this.queryTypeGroup = [
        { text: this.$t('day'), value: 'day' },
        { text: this.$t('week'), value: 'week' },
        { text: this.$t('month'), value: 'month' },
        { text: this.$t('year'), value: 'year' },
      ];
    },
    disableDate(date) {
      return date > new Date();
    },
    sendForm() {
      const form = {
        queryType: this.queryType,
        km: parseInt(this.km),
        format: this.formatDate,
        time: this.time,
      };
      this.$emit('qryform', form);
    },
  },
};
