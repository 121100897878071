
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { mapMutations } from 'vuex';
import ApiService from '@/services/api-service.js';
import moment from 'moment';
import GlobalDataTable from '~/components/global/DataTable.vue';

export default {
  name: 'TripMaintenance',
  components: {
    GlobalDataTable,
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
    DriverModifyDialog: () => {
      if (process.client) {
        return import('@/components/dialog/DriverModifyDialog');
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  data() {
    return {
      search: '',
      tripList: [],
      downloadList: [],
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate())),
      ],
      vehicleDepartmentList: [],
      licencePlateList: [],
      driverList: [],
      vehdept: '',
      licences: '',
      drivers: '',
      vehdeptKey: '',
      licencesKey: '',
      driversKey: '',
      form: {},
      record: {},
      editDialog: false,
      // updateTripList: false,
      // fileName: null,
      msg: '',
      showMsg: false,
      resColor: 'green',

      summaryDialog: false,
      
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t('tripId'), value: 'tripId' },
        { text: this.$t('startTime'), value: 'startTime', width: '120px' },
        { text: this.$t('endTime'), value: 'endTime', width:'120px'},
        { text: this.$t('vehicle'), value: 'vehiclePlate' },
        { text: this.$t('vehicleDept'), value: 'vehicleDepartment' },
        { text: this.$t('driver'), value: 'driver', width: 180 },
        { text: this.$t('driverDept'), value: 'driverDepartment' },
        { text: this.$t('distance'), value: 'distance' },
        { text: this.$t('avgSpeed'), value: 'avgSpeed' },
        { text: this.$t('updateTime'), value: 'updateTime' },
      ];
    },
    summaryData() {
      const driverDistanceMap = this.tripList.reduce((prev,cur) => {
        if(cur.driver === '') {
          return prev
        }
        if (cur.driver in prev) {
          prev[cur.driver] = prev[cur.driver] + parseFloat(cur.distance)
        } else {
          prev[cur.driver] = parseFloat(cur.distance)
        }
        return prev
      }, {})
      
      return Object.entries(driverDistanceMap).map(([k,v]) => {
        const o = { 'driver': k, 'distance': parseFloat(v.toFixed(2) ) }
        
        return o;
      })
    },
    summaryHeaders() {
      return [
        { text: this.$t('driver'), value: 'driver'},
        { text: this.$t('distance'), value: 'distance'},
      ]
    }
  },
  watch: {
    dates(val) {
      if (val[0] && val[1]) {
        this.queryForm();
      }
    },
    vehdept(val) {
      this.queryForm();
    },
    licences(val) {
      this.queryForm();
    },
    drivers(val) {
      this.queryForm();
    },
    // updateTripList() {
    //   // update when it true
    //   if (this.updateTripList) {
    //     this.queryForm();
    //     this.updateTripList = false;
    //   }
    // },
    // Change company
    '$store.state.app.company'(val) {
      // this.queryForm();
      this.vehicleList().then();
    },
  },
  async created() {
    await this.vehicleList();
  },
  methods: {
    ...mapMutations('app', ['get_Lang']),
    getLang() {
      this.get_Lang();
    },
    disabledBefore(date) {
      return this.warningDatePickerDisableDate(date);
    },
    disabledAfter() {
      return new Date();
    },
    // disabledAfter(date) {
    //   return date > new Date();
    // },
    async vehicleList() {
      const response = await ApiService.getTripOptions(this.$axios)
        if (response.items) {
          response.items.forEach((item, i) => {
            if (item.name === 'VehicleDepartment') {
              this.vehicleDepartmentList = item.value;
              if (this.vehicleDepartmentList) {
                this.vehicleDepartmentList.unshift('');
              }
              this.vehdeptKey = item.parameter;
            }
            if (item.name === 'LicencePlae') {
              this.licencePlateList = item.value;
              if (this.licencePlateList) {
                this.licencePlateList.unshift('');
              }
              this.licencesKey = item.parameter;
            }
            if (item.name === 'DriverName') {
              this.driverList = item.value;
              if (this.driverList) {
                this.driverList.unshift('');
              }
              this.driversKey = item.parameter;
            }
          });
          this.queryForm();
        }
      
    },
    queryForm() {
      const form = {};
      form[this.vehdeptKey] = this.vehdept;
      form[this.licencesKey] = this.licences;
      form[this.driversKey] = this.drivers;
      form.startDate = moment(this.dates[0]).format('YYYY-MM-DD');
      form.endDate = moment(this.dates[1]).format('YYYY-MM-DD');
      this.wrapShowloading(
      ApiService.getTripList(this.$axios, form).then((response) => {
        if (response.items) {
          this.downloadList = [];
          response.items.forEach((item, i) => {
            this.downloadList.push({});
            this.downloadList[i][this.$t('tripId')] = item.tripId;
            this.downloadList[i][this.$t('startTime')] = moment(
              item.startTime
            ).format('YYYY-MM-DD HH:mm:ss');
            this.downloadList[i][this.$t('endTime')] = moment(
              item.endTime
            ).format('YYYY-MM-DD HH:mm:ss');
            this.downloadList[i][this.$t('vehicle')] = item.vehiclePlate;
            this.downloadList[i][this.$t('vehicleDept')] =
              item.vehicleDepartment;
            this.downloadList[i][this.$t('driver')] = item.driver;
            this.downloadList[i][this.$t('driverDept')] = item.driverDepartment;
            this.downloadList[i][this.$t('distance')] = item.distance;
            this.downloadList[i][this.$t('driver')] = item.driver;
            this.downloadList[i][this.$t('avgSpeed')] = item.avgSpeed;

            let updateTime = `${moment(
              item.updateTime
            ).format('YYYY-MM-DD HH:mm:ss')}`
            if (item.updatedBy) {
              updateTime = updateTime + ` (${item.updatedBy})`
            }
            this.downloadList[i][this.$t('updateTime')] = updateTime
            // this.downloadList[i][this.$t('updatedBy')] = item.updatedBy;
          });
          this.tripList = response.items;
        }
      })
      );
    },
    editItem(item) {
      this.record = item;
      this.editDialog = true;
    },
    openFileExplorer() {
      this.$refs.fileInputImport.click();
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      ApiService.postUploadFile(this.$axios, formData).then((response) => {
        if (!response.success) {
          this.msg = response.message;
          this.resColor = 'red';
        } else {
          this.msg = this.$t('UploadSuccess');
          this.resColor = 'green';
        }
        this.showMsg = true;
      });
      this.$refs.fileInputImport.value = '';
    },
    updateTripList(val) {
      this.queryForm();
      this.showMsg = true;
      this.msg = val.message;
    },
    showSummary() {
      this.summaryDialog = true;
    }
  },
};
