
import { vehicleSVG } from '@/plugins/vehiclesvg';
import moment from 'moment';
import mapMixin from '@/plugins/mapMixin';
export default {
  mixins: [mapMixin],
  props: {
    center: {
      type: Object,
      default: () => ({ lat: 22.3186, lng: 114.1796 }),
    },
    itemsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
      items: [],
      itemMouseOverHandlers: [],
    };
  },
  watch: {
    itemsData(val) {
      this.redrawItems(val);
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    cleanUpItems() {
      for (const c of this.items) {
        c.setMap(null);
      }
      /*
            for (const h of this.circleClickHandlers) {
                window.google.maps.event.removeListener(h);
            }
            */
      for (const { over, out } of this.itemMouseOverHandlers) {
        window.google.maps.event.removeListener(over);
        window.google.maps.event.removeListener(out);
      }
      this.items = [];
    },
    redrawItems(val) {
      this.cleanUpItems();
      for (const data of val) {
        const infoWindow = new window.google.maps.InfoWindow({});
        const markerColor =
          data.liveInfoStatus.status === 'online'
            ? 'indigo'
            : data.liveInfoStatus.status === 'stopped'
            ? 'red'
            : '';
        const marker = new window.google.maps.Marker({
          map: this.map,
          position: data.center,
          icon: {
            // path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            // anchor: new window.google.maps.Point(0, 2.5),
            // scale: 4,
            // strokeWeight: 3,
            // rotation: mobileLatLngValue.bearing,
            ...vehicleSVG.UNKNOWN(data.bearing),
            fixedRotation: true,
            offset: '0%',
            strokeColor: markerColor,
            fillColor: markerColor,
          },
        });
        this.itemMouseOverHandlers.push({
          over: marker.addListener('mouseover', () => {
            infoWindow.setContent(`
                            <b>${data.plate} [${data.vdept}]</b>
                            <br> ${data.driver ?? '-'} [${data.ddept ?? '-'}]
                            <br> ${moment(data.t.slice(0, -1)).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )}
                            <br> ${data.speed}kph
                            <br> ${data.bearing}
                            <br> ${data.satellites}
                            <br> ${data.fence?.en ?? ''}
                            `); // set content
            infoWindow.open(this.map, marker); // open at marker's location
          }),
          out: marker.addListener('mouseout', () => {
            infoWindow.close();
          }),
        });
        this.items.push(marker);
      }
    },
    initMap() {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: this.center,
        zoom: 12,
        maxZoom: 35,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
      });

      this.map.addListener('zoom_changed', () => {
        const zoomLevel = this.map.getZoom();
        console.log(zoomLevel);
        if (zoomLevel >= 16) {
          for (const c of this.items) {
            // c.setRadius(20);
            c.setOptions({ fillOpacity: 1 });
          }
        } else {
          for (const c of this.items) {
            // c.setRadius(c.computedRadius);
            c.setOptions({ fillOpacity: 0.55 });
          }
        }
      });

      this.map.addListener('tilesloaded', () => {
        this.$emit('mapReady');
      });

      this.customControls(this.map);
    },
  },
};
