
export default {
  name: 'ChartsPanel',
  props: {
    safetyScoreDatasets: Array,
    safetyScoreLabels: Array,
    collisionDatasets: Array,
    collisionLabels: Array,
    badDatasets: Array,
    badLabels: Array,
    comfortDatasets: Array,
    comfortLabels: Array,
  },
  data() {
    return {
      // safety score
      safetyScoreType: 'line',
      safetyScoreOptions: {
        matainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            // suggestedMin: 0,
            // suggestedMax: 100,
          },
          // yAxes: [
          //   {
          //     ticks: { min: 0, max: 100 },
          //   },
          // ],
        },
      },
      // collision
      collisionType: 'line',
      collisionOptions: {
        matainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
      // bad
      badType: 'line',
      badOptions: {
        matainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
      // comfort
      comfortType: 'line',
      comfortOptions: {
        matainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  methods: {
    // Download Image
    downloadImg(event) {
      const link = document.createElement('a');
      link.href = this.$refs[event].$el.toDataURL('image/jpg');
      link.download = this.$t(event) + '.png';
      link.click();
    },
  },
};
