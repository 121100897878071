
/* eslint-disable prettier/prettier */
import ApiService from '@/services/api-service.js';

export default {
  props: {
    value: Boolean,
    driverList: {
      type: Array,
      default: () => [],
    },
    record: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    driver: '',
    error: false,
    confirmDialog: false,
    UpdateErrorMsg: '',
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    record(val) {
      this.initial(val.driver);
    },
  },
  methods: {
    initial(val) {
      setTimeout(() => {
        this.driver = this.driverList.filter((e) => {
          return (e || '').includes(val || '');
        })[0];
        console.log(this.driver);
      }, 300);
    },
    confirm() {
      this.error = false;
      const data = {
        tripId: this.record.tripId,
        driverName: this.driver,
        version: this.record.version,
      };
      ApiService.postDriverUpdate(this.$axios, data).then((response) => {
        if (response) {
          if (response.success) {
            this.$emit('updateTripList', response);
            this.show = false;
          } else {
            this.error = true;
          }
        }
      });
    },
  },
};
