
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import ApiService from '@/services/api-service.js';

export default {
  name: 'TripWarningList',
  components: {
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
    NoVideoDialog: () => {
      if (process.client) {
        return import('@/components/dialog/NoVideoDialog');
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  props: {
    color: {
      type: String,
      default: '',
    },
    warningItemsIndex: {
      type: Number,
      default: 0,
    },
    warningDataTable: {
      type: Array,
      default: () => [],
    },
    warningTypeTotal: {
      type: Object,
      default: () => [],
    },
  },
  data: () => ({
    noVideoDialog: false,
    defaultSortColumn: 'startTime',
    defaultSortDesc: false,
    reason: null,
    other: '',
    page: 1,
    itemsPerPage: 5,
    slideGroup: [],
    // storageSlideGroup: [],
    dialog: false,
    currentItem: null,
    snackbar: false,
    reMsg: '',
    submitAppeal: false,
    // downloadList: [],
  }),
  computed: {
    locale: {
      get() {
        return this.$store.state.app.locale;
      },
    },
    downloadList() {
      return this.warningDataTable.map((item, i) => {
        const obj = {};
        obj[this.$t('Video')] = item.mediaFullId ? item.mediaFullId : '';
        obj[this.$t('warningType')] = this.$t(item.warningType) ?? '';

        obj[this.$t('startTime')] = item.startTime ? item.startTime : '';
        obj[this.$t('duration')] = item.duration ? item.duration : '';
        obj[this.$t('startSpeed')] = item.startSpeed ? item.startSpeed : '';
        obj[this.$t('endSpeed')] = item.endSpeed ? item.endSpeed : '';
        obj[this.$t('topSpeed')] = item.topSpeed ? item.topSpeed : '';
        obj[this.$t('speedLimit')] = this.getSpeedLimit(item);
        obj[this.$t('hw')] = item.hw ? item.hw : '';
        obj[this.$t('minHW')] = item.minHW ? item.minHW : '';
        let tempAction = '';
        if (item.brakeOn) {
          tempAction = tempAction + this.$t('driverActionB');
        }
        if (item.leftSignalOn) {
          if (tempAction !== '') {
            tempAction = tempAction + ',' + this.$t('driverActionL');
          } else {
            tempAction = tempAction + this.$t('driverActionL');
          }
        }
        if (item.rightSignalOn) {
          if (tempAction !== '') {
            tempAction = tempAction + ',' + this.$t('driverActionR');
          } else {
            tempAction = tempAction + this.$t('driverActionR');
          }
        }
        obj[this.$t('driverAction')] = tempAction || '';

        obj[this.$t('address')] =
          (this.locale === 'en' ? item.addressEn : item.addressCh) ?? '';
        return obj;
      });
    },
    warningListHead() {
      return [
        { text: this.$t('Video'), value: 'video' },
        { text: this.$t('warningType'), value: 'warningType' },

        { text: this.$t('startTime'), value: 'startTime' },
        { text: this.$t('duration'), value: 'duration' },
        { text: this.$t('startSpeed'), value: 'startSpeed' },
        { text: this.$t('endSpeed'), value: 'endSpeed' },
        { text: this.$t('topSpeed'), value: 'topSpeed' },
        { text: this.$t('speedLimit'), value: 'speedLimit' },
        { text: this.$t('hw'), value: 'hw' },
        { text: this.$t('minHW'), value: 'minHW' },
        { text: this.$t('driverAction'), value: 'driverType' },
        { text: this.$t('address'), value: 'address', width: '300px' },
        { text: this.$t('Appeal'), value: 'appeal' },
      ];
    },
    Reason() {
      return [
        { text: this.$t('AppealReason-1'), value: this.$t('AppealReason-1') },
        { text: this.$t('AppealReason-2'), value: this.$t('AppealReason-2') },
        { text: this.$t('AppealReason-3'), value: this.$t('AppealReason-3') },
        { text: this.$t('AppealReason-4'), value: this.$t('AppealReason-4') },
        { text: this.$t('AppealReason-5'), value: 5 },
      ];
    },
    filteredItems() {
      const filter = this.warningDataTable.filter((post) => {
        return post.display === true;
      });
      return filter;
    },
  },
  watch: {
    '$store.state.app.submitAppeal'(val) {
      this.submitAppeal = val;
    },
  },
  created() {
    this.submitAppeal = this.$store.state.app.submitAppeal;
    this.setSlideGroup();
    this.changeWarningType();
  },
  methods: {
    mobileLatLng(item) {
      this.$emit('detailLatLng', item);
    },
    setSlideGroup() {
      for (let i = 0; i < Object.keys(this.warningTypeTotal).length; i++) {
        this.slideGroup.push(i);
      }
    },
    changeWarningType() {
      const changeWarningType = [];
      this.slideGroup.forEach((i) => {
        changeWarningType.push({
          index: this.warningItemsIndex,
          warningType: Object.keys(this.warningTypeTotal)[i],
        });
      });
      this.$emit('changeWarningType', changeWarningType);
    },
    setAppeal() {
      let setReason = '';
      if (this.reason === 5) {
        setReason = this.other;
      } else {
        setReason = this.reason;
      }
      if (setReason === '') {
        return;
      }
      const rawData = {
        AppealedBy: this.$store.state.app.userName,
        AppealReason: setReason,
      };
      ApiService.applyAppeal(
        this.$axios,
        this.currentItem.id.split('-')[0],
        rawData
      ).then((response) => {
        if (response) {
          if (response.success) {
            this.snackbar = true;
            this.reMsg = this.$t('CreateAppealSuccess');
            setTimeout(() => {
              this.$emit('reload', '');
            }, 3000);
          } else {
            this.reMsg = this.$t('CreateAppealError');
          }
          console.log(response);
        }
      });
      this.dialog = false;
    },
    emitPlayVideo(startTime, value, isBSD) {
      if (this.olderThanNDays(startTime, 60)) {
        console.log('disable video');
        this.noVideoDialog = true;
      } else {
        this.$emit('playVideo', value, isBSD);
      }
    },
  },
};
