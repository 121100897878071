
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { mapMutations } from 'vuex';
import ApiService from '@/services/api-service.js';
import moment from 'moment';

export default {
  name: 'WarningData',
  components: {
    WarningOption: () => {
      if (process.client) {
        return import('@/components/global/WarningOption');
      }
    },
  },
  data() {
    return {
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 2)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
      downloadType: 'data',
      downloadTypeList: [
        { text: this.$t('Data'), value: 'data' },
        { text: this.$t('Video'), value: 'video' },
      ],
      optionText: 'warningDataOption',
      valid: true,
      datasets: [],
      optionsets: [],
      dynamic: null,
      optionPanel: [],
      optionIndex: 1,
      getFile: '',
      panel: [0],
      errorMsg: this.$t('RequiredMsg'),
      dialog: false,
      showMsg: false,
      msg: '',
      msgColor: 'primary',
    };
  },
  watch: {
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
    },
  },
  created() {
    this.getOptions();
    this.optionPanel.push({ id: this.optionIndex });
    this.optionIndex++;
  },
  methods: {
    ...mapMutations('app', ['get_Lang']),
    getLang() {
      this.get_Lang();
    },
    disabledBefore(date) {
      return this.warningDatePickerDisableDate(date);
    },
    disabledAfter(date) {
      return new Date();
    },
    addOption() {
      this.checkForm();
      if (this.valid) {
        this.optionPanel.push({
          id: this.optionIndex,
        });
        this.optionIndex++;
      }
    },
    getOptions() {
      ApiService.getWarningDataOptions(this.$axios).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            this.datasets.push({
              text: item.name,
              value: item.value,
              type: item.type,
              url: item.url,
              active: true,
              disabled: false,
            });
          });
          this.optionsets = this.datasets;
        }
      });
    },
    closeOption(item, i) {
      this.optionPanel.splice(i, 1);
      const val =
        this.$refs.getOption[i].optionType.value ||
        this.$refs.getOption[i - 1].optionType.value;
      this.optionsets.forEach((item, i) => {
        if (item.value === val) {
          item.disabled = false;
        }
      });
      // reset disabled
      setTimeout(() => {
        this.$refs.getOption[this.optionPanel.length - 1].$el
          .querySelector('.v-input')
          .classList.remove('v-input--is-disabled');
      }, 1000);

      if(this.optionPanel.length <= 1) {
        // when left with only 1 option, all should be enabled
        this.optionsets.forEach((item, i) => {
            item.disabled = false;
        });
      }
    },
    // check valid
    checkForm() {
      this.valid = true;
      this.optionsets = this.datasets;
      if (!this.downloadType) {
        this.valid = false;
      }
      this.$refs.getOption.forEach((item, i) => {
        if (this.$refs.getOption.length >= 1) {
          // reset disabled
          item.$el
            .querySelector('.v-input')
            .classList.remove('v-input--is-disabled');
          if (!item.optionType.text) {
            this.valid = false;
          }
          if (!item.getOptions) {
            this.valid = false;
          } else if (item.getOptions.length === 0) {
            this.valid = false;
          }
          if (this.valid) {
            this.filterData(item.optionType.value);
            // set disabled
            item.$el
              .querySelector('.v-input')
              .classList.add('v-input--is-disabled');
          }

          // form validate
          item.formValidate()
        }
      });
      if (!this.dates[0] || !this.dates[1]) {
        this.valid = false;
      }
    },
    // disabled item
    filterData(val) {
      this.optionsets.forEach((item, i) => {
        if (item.value === val) {
          item.disabled = true;
        }
      });
    },
    sendForm() {
      this.checkForm();
      if (this.valid) {
        this.msgColor = 'primary';
        this.dialog = true;
        const form = {};
        form.downloadType = this.downloadType;
        form.dataFilename = this.getFile;
        form.lang = this.$i18n.locale;
        form.startDate = moment(this.dates[0]).format('YYYY-MM-DD');
        form.endDate = moment(this.dates[1]).format('YYYY-MM-DD');
        // if (this.$refs.getOption.length > 1) {
        this.$refs.getOption.forEach((item, i) => {
          if (item.optionType.value) {
            if (item.getOptions.length > 0) {
              if(typeof item.getOptions[0] === 'object') {
                form[item.optionType.value] = item.getOptions.map((o) => o.id);
              } else {
                form[item.optionType.value] = item.getOptions;
              }
            }
          }
        });
        // }

        ApiService.getWarningData(this.$axios, form)
          .then((response) => {
            if (response) {
              if (response.type.includes('application/json')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                  const content = reader.result;
                  const result = JSON.parse(content);

                  this.msgColor = 'error';
                  this.showMsg = true;
                  this.msg = result.message;
                };
                reader.readAsText(response);
              } else {
                const fileName = this.getFile
                  ? this.getFile
                  : 'WarningData_' + moment(new Date()).format('YYYY-MM-DD');
                const blob = null;
                const link = document.createElement('a');
                link.style.display = 'none';
                if (this.downloadType === 'video') {
                  this.blob = new Blob([response], {
                    type: 'application/zip',
                  });
                  link.download = fileName + '.zip';
                } else {
                  this.blob = new Blob([response]);
                  link.download = fileName + '.csv';
                }
                link.href = window.URL.createObjectURL(this.blob);
                document.body.appendChild(link);
                link.click();
              }
            }
            this.dialog = false;
          })
          .catch((error) => {
            console.log(error);
            this.dialog = false;
          })
          .finally(() => {
            // re enable the last select box and selected item
            
              this.$refs.getOption.slice(-1)[0].$el
                  .querySelector('.v-input')
                  .classList.remove('v-input--is-disabled');
              
              this.optionsets.forEach((item, i) => {
                if (item.value === this.$refs.getOption.slice(-1)[0].optionType.value) {
                  item.disabled = false;
                }
              });
          });
      }
    },
  },
};
