
// import ApiService from '@/services/api-service.js';
export default {
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    filter: {
      type: Function,
      default() {
        return () => true;
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return () => true;
      },
    },
  },
  data() {
    return {
      warningList: [],
      getWarning: this.multiple ? this.value : this.value[0],
    };
  },
  computed: {
    allWarningsSelected: {
      get() {
        if (this.multiple)
          return this.getWarning.length === this.warningList.length;
        else return 1;
      },
    },
    icon() {
      if (this.multiple) {
        if (this.allWarningsSelected) return 'mdi-close-box';

        if (this.getWarning.length > 0 && !this.allWarningsSelected)
          return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      } else {
        return 'mdi-checkbox-blank-outline';
      }
    },
  },
  watch: {
    //! if watch for value, and set getWarning inside this watch,
    //! watch for getWatching i.e. below will be called, and so that emit input will trigger watch for value again , resulting infinite loop
    // TODO need to research how to solve
    getWarning(val) {
      if (this.multiple) this.$emit('input', val);
      else this.$emit('input', [val]);
    },
  },

  async mounted() {
    console.log('warningComboBox mounted');
    /*
    const response = await ApiService.getWarningDataOptionList(
      this.$axios,
      // '/api/warningData/GetWarningTypeList'
      '/api/heatmap/GetSpwWarningTypeList'
    );
    */
    // hardcode
    const response = await Promise.resolve({
      items: ['SPW', 'SPW_H', 'SPW_L', 'SPW_M'],
    });

    if (response.items) {
      this.warningList = response.items.filter(this.filter).map((item) => ({
        text: this.$t(item),
        value: item,
      }));

      if (
        this.getWarning === null ||
        this.getWarning === undefined ||
        this.getWarning.length === 0
      ) {
        if (this.warningList.length === 0) {
          this.getWarning = this.multiple ? [] : null;
          return;
        }
        if (this.multiple) this.getWarning = [this.warningList[0].value];
        else this.getWarning = this.warningList[0].value;
      }
    }
  },
  methods: {
    selectAllWarning() {
      if (this.allWarningsSelected) this.getWarning = [];
      else this.getWarning = [...this.warningList];
    },
  },
};
