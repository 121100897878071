
import ApiService from '@/services/api-service.js';
import VideoDialog from '@/components/dialog/VideoDialog';
// import AppealConfirmDialog from '@/components/dialog/AppealConfirmDialog';
import moment from 'moment';

export default {
  components: {
    VideoDialog,
    // AppealConfirmDialog,
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
    },
  },
  props: {
    value: Boolean,
    record: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    videoDialog: false,
    confirmDialog: false,
    confirmType: '',
    media: '',

    // Google maps
    imgDisplay: false,
    lat: null,
    lng: null,
    location: '',
    src: 'https://maps.googleapis.com/maps/api/staticmap?center=',
    marker: '&markers=',
    params: '&zoom=16&size=660x460&key=AIzaSyDnCqlHgVv_C23CFiIYhS20l3qfP4BIxUk',
    getresult: null,
    tempAction: '',
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    record(val) {
      if (val) {
        this.lat = this.record.lat;
        this.lng = this.record.lng;

        this.tempAction = '';
        if (val.brakeOn) {
          this.tempAction = this.tempAction + this.$t('driverActionB');
        }
        if (val.leftSignalOn) {
          if (this.tempAction !== '') {
            this.tempAction = this.tempAction + ',' + this.$t('driverActionL');
          } else {
            this.tempAction = this.tempAction + this.$t('driverActionL');
          }
        }
        if (val.rightSignalOn) {
          if (this.tempAction !== '') {
            this.tempAction = this.tempAction + ',' + this.$t('driverActionR');
          } else {
            this.tempAction = this.tempAction + this.$t('driverActionR');
          }
        }
      }
    },
    result(val) {
      console.log('result ' + val);
    },
    appealmsg() {
      console.log('appealmsg ' + this.appealmsg);
    },
  },
  methods: {
    getMap() {
      // Reset src path and center string
      this.src = process.env.GOOGLE_MAPS_STATIC;
      this.location = '';
      this.marker = '&markers=';

      // Pop Up Window
      this.imgDisplay = true;

      // Locate Map (Static Google Map API)
      if (this.lat && this.lng !== null) {
        this.location = this.location.concat(this.lat, ',', this.lng);
        this.marker = this.marker.concat(this.location);
        this.src = this.src.concat(this.location, this.marker, this.params);
      }
    },
    playVideo() {
      this.videoDialog = true;
    },
    // updateWarningList() {
    //   this.dialog2 = false;
    //   this.$emit('updateWarningList', true);
    // },
    // updateDialog() {
    //   const flag = false;
    //   this.$emit('updateDialog', flag);
    // },
    confirm(val) {
      this.confirmType = val;
      this.confirmDialog = true;
    },
    acquireAppeal(data) {
      // console.log('acquireAppeal ' + data);
      const rawData = {
        isAppealApproved: data.type === 'approve',
        appealApprovedBy: this.$store.state.app.userName,
        appealApprovalReason: data.reason,
      };
      ApiService.postAppealApproval(this.$axios, this.record.id, rawData).then(
        (response) => {
          // console.log(response);
          if (response) {
            if (response.success) {
              this.$emit('updateWarningList', true);
              this.show = false;
            }
          }
        }
      );
    },
  },
};
