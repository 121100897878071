
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';
import moment from 'moment';

export default {
  components: {
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
    HQUserDetailDialog: () => {
      if (process.client) {
        return import('@/components/dialog/HQUserDetailDialog');
      }
    },
    WarningDialog: () => {
      if (process.client) {
        return import('@/components/dialog/WarningDialog');
      }
    },
    // DistributorDialog: () => {
    //   if (process.client) {
    //     return import('@/components/dialog/DistributorDetailDialog');
    //   }
    // },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  data: () => ({
    tabList: null,
    tabs: ['User', 'User.AlarmList'],
    Search: '',
    calculateWidths: true,
    downloadList: [],
    ItemsList: [],
    warningList: [],
    distributorList: [],
    addDialog: false,
    updateValue: {},
    warningDialog: false,
    warningValue: {},
    distributorDialog: false,
    distributorValue: {},
    componentKey: 0,
    // displayDelete: false,
    beforeDelete: false,
    itemsToBeDeleted: {},
    UpdateAuth: false,
    showMsg: false,
    msg: '',
  }),
  computed: {
    Headers() {
      return [
        { text: this.$t('User.username'), value: 'username' },
        { text: this.$t('User.displayName'), value: 'displayName' },
        { text: this.$t('User.email'), value: 'email' },
        // { text: this.$t('User.companyRole'), value: 'companyRole' },
        // { text: this.$t('User.department'), value: 'department' },
        { text: this.$t('User.lang'), value: 'lang' },
        // { text: this.$t('User.state'), value: 'isSuspended' },
        { text: this.$t('User.isAdmin'), value: 'isAdmin' },
        { text: this.$t('Setting.lastUpdated'), value: 'updatedAt' },
        {
          text: this.$t('Setting.crudActions'),
          value: 'crudActions',
          sortable: false,
        },
      ];
    },
    warningHeaders() {
      return [
        { text: this.$t('WarningType'), value: 'warningType' },
        { text: this.$t('Warning.isActive'), value: 'isActive' },
        {
          text: this.$t('Warning.recommendationScore'),
          value: 'recommendationScore',
        },
        { text: this.$t('Warning.maxScore'), value: 'maxScore' },
        { text: this.$t('Warning.ScoreWeighting'), value: 'scoreWeighting' },
        {
          text: this.$t('Setting.crudActions'),
          value: 'crudActions',
          sortable: false,
        },
      ];
    },
    distributorHeaders() {
      return [
        { text: this.$t('Distributor'), value: 'name' },
        { text: this.$t('Distributor.ContactName'), value: 'contactName' },
        { text: this.$t('Distributor.ContactPhone'), value: 'contactPhone' },
        { text: this.$t('Distributor.ContactEmail'), value: 'contactEmail' },
        { text: this.$t('Distributor.TimeZone'), value: 'timeZone' },
        {
          text: this.$t('Setting.crudActions'),
          value: 'crudActions',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    displayDelete() {
      setTimeout(() => (this.displayDelete = false), 3000);
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setDownloadList();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.getUsers();
    },
    '$store.state.app.userRole'(val) {
      this.checkAuth();
    },
  },
  mounted() {
    this.getUsers();
    this.checkAuth();

    this.getWarningList();
    // this.getDistributorList();
  },
  methods: {
    getUsers() {
      this.ItemsList = [];
      this.wrapShowloading(
        ApiService.getHQUsers(this.$axios)
          .then((response) => {
            if (response) {
              this.ItemsList = response.items;
              this.setDownloadList();
            }
          })
          .catch({})
      );
    },
    checkAuth() {
      this.UpdateAuth = false;
      const val = this.$store.state.app.userRole;
      if (val === 'HQ') {
        this.UpdateAuth = true;
      }
    },
    setDownloadList() {
      if (this.ItemsList.length > 0) {
        this.downloadList = [];
        this.ItemsList.forEach((item, i) => {
          this.downloadList.push({});
          this.downloadList[i][this.$t('User.username')] = item.username;
          this.downloadList[i][this.$t('User.displayName')] = item.displayName;
          this.downloadList[i][this.$t('User.email')] = item.email;
          this.downloadList[i][this.$t('User.isAdmin')] = item.isAdmin;
          this.downloadList[i][this.$t('User.lang')] = item.lang
            ? item.lang
            : '';
          // this.downloadList[i][this.$t('User.state')] = !item.isSuspended;
          this.downloadList[i][this.$t('isViewSelfOnly')] = item.isViewSelfOnly;
          this.downloadList[i][this.$t('Setting.lastUpdated')] =
            moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
            ' (' +
            item.updatedBy +
            ')';
        });
      }
    },
    downloadCSV() {
      // eslint-disable-next-line
      let csvcontent = JSON.parse(JSON.stringify(this.ItemsList));

      // Acquire headers
      const csvheaders = this.Headers.map((obj) => obj.value);

      // Special add
      csvheaders.push('updatedBy', 'updatedAt');

      // Format companyList as same as display
      csvcontent.forEach((item) => {
        Object.entries(item).forEach(([key]) => {
          // If none of the key in csvcontent satisfies the headers, remove them.
          if (!csvheaders.includes(key)) delete item[key];
        });
      });
      return csvcontent;
    },
    addData() {
      this.addDialog = true;
    },
    // addDistributor() {
    //   this.distributorDialog = true;
    // },
    updateData(val) {
      this.updateValue = val;
      this.addDialog = true;
    },
    updateWarning(val) {
      this.warningValue = val;
      this.warningDialog = true;
    },

    beforeDeleteData(val) {
      console.log(val);
      this.beforeDelete = true;
      this.itemsToBeDeleted = val;
    },
    deleteData(val) {
      this.beforeDelete = false;

      // Delete the records in Vuetify datatable and refresh.
      this.ItemsList = this.ItemsList.filter((item) => item.id !== val.id);
      this.forceRerender();
      this.displayDelete = true;

      ApiService.deleteUser(this.$axios, val.id);
    },
    // updateDistributor(val) {
    //   this.distributorValue = val;
    //   this.distributorDialog = true;
    // },
    clearUpdateValue() {
      this.updateValue = {};
    },
    clearWarningValue() {
      this.warningValue = {};
    },
    // clearDistributorValue() {
    //   this.distributorValue = {};
    // },
    // forceRerender() {
    //   this.componentKey += 1;
    // },
    forceRerenderOnSubmit(val) {
      this.componentKey += 1;
      this.getUsers();
      this.showMsg = true;
      this.msg = val.message;
    },
    WarningOnSubmit(val) {
      // this.componentKey += 1;
      this.getWarningList();
      this.showMsg = true;
      this.msg = val.message;
    },
    // distributorSubmit(val) {
    //   // this.componentKey += 1;
    //   this.getDistributorList();
    //   this.showMsg = true;
    //   this.msg = val.message;
    // },
    // DescArray(array) {
    //   // eslint-disable-next-line
    //   let newarray = [];
    //   array.forEach((item) => {
    //     newarray.unshift(item);
    //   });
    //   return newarray;
    // },

    getWarningList() {
      this.warningList = [];
      this.wrapShowloading2(
        ApiService.getWarningList(this.$axios)
          .then((response) => {
            if (response) {
              this.warningList = response.items;
            }
          })
          .catch({})
      );
    },
    // getDistributorList() {
    //   this.distributorList = [];
    //   ApiService.getDistributorList(this.$axios)
    //     .then((response) => {
    //       if (response) {
    //         this.distributorList = response.items;
    //       }
    //     })
    //     .catch({});
    // },
  },
};
