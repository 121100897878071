
import geofenceMixin from '@/plugins/geofenceMixin';
const debounce = (fn, ms = 300) => {
  let timeoutId;
  return function ($this, ...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply($this, args), ms);
  };
};
export default {
  name: 'GeofencingSettings',
  components: {
    Gmap: () => {
      if (process.client) {
        return import('@/components/GeofenceGoogleMap');
      }
    },
  },
  mixins: [geofenceMixin],
  data() {
    return {
      // ==============================
      banner: false,
      leftDrawer: true,
      // ==============================
      activeTab: 'add',
      editMode: false,
      // ==============================
      listItemsPending: false,
      listItems: [],
      // ==============================
      // edit form
      fenceInEdit: null,
      fenceId: null,
      fenceActive: true,
      fenceName: '',
      fenceColor: '#B5B6B6',
      fenceCoordinates: '',
      fenceType: '',

      selectedGroups: [],

      drawingManager: null,
      currentShape: null,
      // for focus shape
      focusSahpe: null,
      actionForShapedChanged: debounce(this.geom2VariousInfo, 300),

      deleteConfirmDialog: false,
      itemsToBeDeleted: null,
    };
  },
  watch: {
    '$store.state.app.company'(_val) {
      this.updateListItems(true).then();
      this.retrieveGeofenceGroups().then();
    },
    async activeTab(tab) {
      if (tab === 'list') {
        this.listItemsPending = true;
        await this.updateListItems();
        this.listItemsPending = false;
        // reset add tab
        this.editMode = false;
        // clear the fenceType
        this.fenceType = null;
      } else {
        this.focusShape?.setMap(null);
        await this.retrieveGeofenceGroups();
        if (!this.editMode) {
          // add mode
          this.fenceColor = '#B5B6B6';
          this.fenceActive = true;
          this.fenceCoordinates = '';
          this.fenceId = null;
        }
      }
    },
    fenceInEdit(fence) {
      this.fenceActive = fence.active;
      this.fenceName = fence.meta.en;
      this.fenceColor = fence.color || '#B5B6B6';
      this.fenceType = fence.fenceType;
      this.fenceId = fence.name;

      this.selectedGroups = fence.groups.map((g) => ({ text: g, value: g }));
    },
    currentShape(currentShapeVal) {
      if (!currentShapeVal) return;
      const { overlay, type } = currentShapeVal;
      this.setupListenerForShape(type, overlay);
    },
    fenceColor(colorVal) {
      if (this.currentShape) {
        this.currentShape.overlay.setOptions(this.toGoogleShapeColor(colorVal));
      } else {
        const opts = {};
        if (this.fenceType === 'polygon') {
          opts.polygonOptions = this.makeGoogleShapeOptions(colorVal);
        } else if (this.fenceType === 'circle') {
        }
        this.drawingManager.setOptions(opts);
      }
    },
    fenceType(fenceTypeVal) {
      // clear shape first
      this.clearMapAndInfo();
      if (!fenceTypeVal) return;

      // turn on drawing mode
      this.drawingManager.setDrawingMode(fenceTypeVal);

      if (this.editMode && 'fenceType' in this.fenceInEdit) {
        /*
        let ShapeClazz;
        let geom;
        if (fenceTypeVal === 'circle') {
          ShapeClazz = window.google.maps.Circle;
        } else {
          ShapeClazz = window.google.maps.Polygon;
          const { coordinates } = this.fenceInEdit.geometry;
          const coords = {
            paths: coordinates[0].map(([lng, lat]) => ({ lat, lng })),
          };
          geom = { ...coords };
        }

        const shape = new ShapeClazz({
          ...geom,
          map: this.$refs.map.map,
          ...this.makeGoogleShapeOptions(this.fenceColor),
        });
*/
        const shape = this.drawShapeOnGMap(
          this.$refs.map.map,
          this.fenceInEdit.geometry,
          this.fenceColor,
          fenceTypeVal
        );
        this.currentShape = { overlay: shape, type: fenceTypeVal };
      } else if (fenceTypeVal === 'circle') {
      } else {
        this.drawingManager.setOptions({
          polygonOptions: this.makeGoogleShapeOptions(this.fenceColor),
        });
      }
    },
  },
  async mounted() {
    await this.retrieveGeofenceGroups();
  },
  methods: {
    async deleteFence(fence) {
      console.log('delete', fence);
      await this.deleteGeofence(fence.name);
      this.deleteConfirmDialog = false;
      await this.updateListItems(true);
      await this.retrieveGeofenceGroups();
    },
    openConfirmDialog(fence) {
      this.itemsToBeDeleted = fence;
      this.deleteConfirmDialog = true;
    },
    resetShape() {
      delete this.fenceInEdit?.fenceType;
      this.fenceType = null;
      this.drawingManager.setDrawingMode(null);
    },
    clearMapAndInfo() {
      if (!this.currentShape) return;
      const overlay = this.currentShape.overlay;
      if (this.currentShape.type === 'circle') {
      } else {
        window.google.maps.event.clearListeners(overlay.getPath(), 'set_at');
        window.google.maps.event.clearListeners(overlay.getPath(), 'insert_at');
      }
      window.google.maps.event.clearListeners(overlay, 'dragend');
      overlay.setMap(null);
      this.currentShape = null;
    },
    geom2VariousInfo() {
      console.log('shape done');
      const { overlay: drawnShape, type } = this.currentShape;
      let coordLines = '';
      if (type === 'circle') {
      } else {
        const paths = drawnShape.getPath();
        for (let i = 0; i < paths.length; i++) {
          const p = paths.getAt(i);
          coordLines += `${coordLines !== '' ? '\n' : ''}${p.lat()},${p.lng()}`;
        }
        coordLines += `\n${paths.getAt(0).lat()},${paths.getAt(0).lng()}`;
      }
      this.fenceCoordinates = coordLines;
    },
    setupListenerForShape(type, shape) {
      if (type === 'circle') {
      } else {
        window.google.maps.event.addListener(
          shape.getPath(),
          'set_at',
          this.actionForShapedChanged
        );
        window.google.maps.event.addListener(
          shape.getPath(),
          'insert_at',
          this.actionForShapedChanged
        );
      }
      window.google.maps.event.addListener(
        shape,
        'dragend',
        this.actionForShapedChanged
      );
      this.geom2VariousInfo();
      // reset to hand mode
      this.drawingManager.setDrawingMode(null);
    },
    onMapReady() {
      console.log('onMapReady');
      const mapRef = this.$refs.map;
      this.drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingControl: false,
        polygonOptions: this.makeGoogleShapeOptions(this.fenceColor),
        circleOptions: this.makeGoogleShapeOptions(this.fenceColor),
      });
      this.drawingManager.setMap(mapRef.map);
      // circle doesnt trigger this
      const $this = this;
      window.google.maps.event.addListener(
        this.drawingManager,
        'overlaycomplete',
        (e) => {
          $this.currentShape = e;
        }
      );
    },
    // =================================================================
    pageActionAllow() {
      return true;
    },

    async updateListItems(showProgressBar) {
      if (showProgressBar) this.listItemsPending = true;
      this.listItems = await this.adminGetFences();
      if (showProgressBar) this.listItemsPending = false;
    },
    // =================================================================
    focusFence(fence) {
      this.focusShape && this.focusShape.setMap(null);
      const map = this.$refs.map.map;
      const shape = this.drawShapeOnGMap(
        map,
        fence.geometry,
        fence.color,
        fence.fenceType
      );
      this.focusShape = shape;
      let bounds;
      if (fence.fenceType === 'circle') {
      } else {
        bounds = new window.google.maps.LatLngBounds();
        const paths = shape.getPath();
        for (let i = 0; i < paths.length; i++) {
          const p = paths.getAt(i);
          const coord = { lat: p.lat(), lng: p.lng() };
          bounds.extend(coord);
        }
      }
      map.fitBounds(bounds, 0);
    },
    editFence(fence) {
      this.fenceInEdit = fence;
      this.editMode = true;
      this.activeTab = 'add';
    },
    async createFence(_evt) {
      const {
        fenceActive,
        fenceType,
        fenceColor,
        fenceName,
        fenceCoordinates,
        selectedGroups,
      } = this;

      if (
        fenceType &&
        fenceType !== '' &&
        fenceCoordinates &&
        fenceCoordinates !== ''
      ) {
        const newFence = {
          fenceActive,
          fenceType,
          fenceColor,
          fenceName,
          fenceCoordinates,
          username: this.$store.state.app.userName,
          selectedGroups,
        };
        if (this.editMode) {
          newFence.id = this.fenceId;
        }
        console.log('createFence', newFence);
        await this.adminCreateOrEditFence(newFence);
        await this.updateListItems(true);
        await this.retrieveGeofenceGroups();
      }
    },
    resetEditForm() {
      this.resetShape();
      this.fenceColor = '#B5B6B6';
      this.fenceName = '';
      this.selectedGroups = [];
    },
    // =================================================================
    // convert to gooogle geom
    drawShapeOnGMap(map, geometry, color, fenceType) {
      let ShapeClazz;
      let geom;
      if (fenceType === 'circle') {
        ShapeClazz = window.google.maps.Circle;
      } else {
        ShapeClazz = window.google.maps.Polygon;
        const { coordinates } = geometry;
        const coords = {
          paths: coordinates[0].map(([lng, lat]) => ({ lat, lng })),
        };
        geom = { ...coords };
      }

      const shape = new ShapeClazz({
        ...geom,
        map,
        ...this.makeGoogleShapeOptions(color),
      });
      return shape;
    },
    // make options for drawing
    makeGoogleShapeOptions(color) {
      return {
        clickable: true,
        draggable: true,
        editable: true,
        ...this.toGoogleShapeColor(color),
      };
    },
    // shape color
    toGoogleShapeColor(color) {
      return {
        strokeColor: color || '#FF000066',
        strokeOpacity: 0.8,
        strokeWeight: 5,
        fillColor: color || '#FF0000',
        fillOpacity: 0.35,
      };
    },
  },
};
