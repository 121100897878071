
const livehost = 'https://video.gstsafety.com';
export default {
  name: 'Jessibuca',
  props: {
    plateNo: {
      type: String,
      default: () => '',
    },
    channel: {
      type: String,
      default: () => 0,
    },
    rotate: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    jessibucaContainerStyle: {
      get() {
        return this.rotate % 180 === 0
          ? { width: '370px', height: '240px' }
          : { width: '370px', height: '696px' };
      },
    },
  },
  watch: {
    plateNo(val, oldVal) {
      if (val !== oldVal) {
        this.log('watch', `plateNo changed to ${val}`);

        if (this.jessibuca === undefined || this.jessibuca === null) {
          this.create();
        }
        (async () => {
          await this.play();
        })();
      }
    },
  },
  created() {
    this.$root.$on('jessibucaLoaded', () => {
      this.create();

      if (this.startedPlay === undefined || !this.startedPlay) {
        this.startedPlay = true;
        (async () => {
          await this.play();
        })();
      }
    });
  },
  mounted() {
    this.log('mounted', `plateNo: ${this.plateNo}`, 'Jessibuca vue mounted');
    this.log(
      'mounted',
      `plateNo: ${this.plateNo}`,
      'isJessibucaEventSent',
      this.$root.__isJessibucaEventSent__
    );
    //* if didnt leave the page, script is still loaded
    //* so event will never be emitted
    //* so when changing vehicle on the list instead of clicking the map
    //* need to use a isJessibucaLoaded in mixin plugins/globalutil.js
    //* clear this flag

    const j = document.querySelector('script[src="/jessibuca.js"]');
    if (j === null) {
      this.log('mounted', 'no script, load jessibuca.js');
      this.$root.__isJessibucaEventSent__ = true;
      const plugin = document.createElement('script');
      plugin.setAttribute('src', '/jessibuca.js');
      plugin.async = true;
      const _this = this;

      document.head.addEventListener(
        'load',
        function (event) {
          if (event.target.nodeName === 'SCRIPT') {
            const name = event.target.getAttribute('src');
            if (name === '/jessibuca.js') {
              console.log('Script loaded: ' + name);
              _this.$root.$emit('jessibucaLoaded');
            }
          }
        },
        true
      );
      document.head.appendChild(plugin);
    } else if (
      this.$root.__isJessibucaEventSent__ !== undefined &&
      !this.$root.__isJessibucaEventSent__
    ) {
      this.log(
        'mounted',
        'already loaded, emit jessibucaLoaded event directly'
      );
      this.$root.$emit('jessibucaLoaded');
      this.$root.__isJessibucaEventSent__ = true;
    } else {
      this.log(
        'mounted',
        'already loaded, jessibucaLoaded event emitted, do nothing'
      );
    }
  },
  unmounted() {
    console.log('jessibuca unmounted');
    this.destroy();
  },
  beforeDestroy() {
    this.log('beforeDestroy');
    this.$root.__isJessibucaEventSent__ = false;
    this.$root.$off('jessibucaLoaded');
    this.destroy();
  },
  methods: {
    destroy() {
      clearInterval(this.keepAliveTimer);
      this.jessibuca && this.jessibuca.destroy();
      this.jessibuca = null;
    },
    create(options) {
      this.log('create', `channel:${this.channel}`);
      options = options || {};
      if (this.jessibuca === null || this.jessibuca === undefined) {
        this.jessibuca = new window.Jessibuca(
          Object.assign(
            {
              rotate: this.rotate,
              container: this.$refs.container,
              debug: false,
              videoBuffer: 0.2,
              hasAudio: false,
              isNotMute: false,
              forceNoOffscreen: true,
              supportDblclickFullscreen: true,
              isResize: false,
              operateBtns: {
                fullscreen: true,
              },
              decoder: '/decoder.js',
            },
            options
          )
        );
      }
      if (this.jessibuca !== null && this.jessibuca !== undefined) {
        /*
        this.jessibuca.on('play', function () {
          console.log('on play');
        });
        */
      }
    },
    async play() {
      const videoUrl = await this.getvideostream();
      if (videoUrl !== undefined && videoUrl !== null) {
        this.jessibuca.play(videoUrl);
        clearInterval(this.keepAliveTimer);
        this.keepAliveTimer = setInterval(this.keepAlive, 3000);
      }
    },
    async videocmd(cmd, params) {
      let resp;
      if (cmd === 'login') {
        const data = JSON.stringify({ user_name: 'sa', password: '123456' });
        resp = await fetch(
          `${livehost}/api/sys_mng/login?params=${data}&_=123`,
          {
            method: 'GET',
          }
        );
      } else if (cmd === 'subscribe') {
        const subscribedata = JSON.stringify({
          auth_code: params.authCode,
          client_id: params.clientId,
          plate_no: params.plateNo,
          dev_id: 0,
          mch: params.channel,
          mtype: 3,
          st: 0,
          bs_type: 2,
        });
        resp = await fetch(
          `${livehost}/api/media/subscribe?params=${subscribedata}&_=123`,
          {
            method: 'GET',
          }
        );
      } else if (cmd === 'keepalive') {
        const data = JSON.stringify({
          auth_code: params.authCode,
          client_id: params.clientId,
          sub_id: params.subId,
        });
        resp = await fetch(
          `${livehost}/api/media/keep_stream_alive?params=${data}&_=123`,
          {
            method: 'GET',
          }
        );
      } else {
        console.error('invalid command');
        return;
      }
      let text = await resp.text();
      text = text.substring(14, text.length - 1);
      const respdata = JSON.parse(text);
      this.log(`videocmd`, cmd, `channel: ${this.channel}`, respdata);
      return respdata;
    },
    async getvideostream() {
      let respdata = await this.videocmd('login');

      const authCode = respdata.auth_code;
      const clientId = respdata.client_id;

      const channel = this.channel;

      const plateNo = this.plateNo;
      respdata = await this.videocmd('subscribe', {
        authCode,
        clientId,
        plateNo,
        channel,
      });

      const subId = respdata.sub_id;
      // const termId = respdata.term_id;
      const intranet = 1;
      const token = 'xxxx';
      const params = {
        auth_code: authCode,
        token,
        intranet,
        subid: subId,
        client_id: clientId,
      };
      const videoUrl = `${livehost}/live/${plateNo}_${channel}.flv?params=${JSON.stringify(
        params
      )}&subid=${subId}`;
      this.log('getvideostream', videoUrl);

      this.keepAlive = async () => {
        const keepaliverespjson = await this.videocmd('keepalive', {
          authCode,
          clientId,
          subId,
        });

        if (keepaliverespjson.result !== 0) {
          console.log('error: result=' + keepaliverespjson.result);
          clearInterval(this.keepAliveTimer);
        }
      };

      return videoUrl;
    },
  },
};
