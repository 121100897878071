
export default {
  name: 'DateRange',
  props: {
    label: {
      type: String,
      default() {
        return this.$t('Date');
      },
    },
    nonRange: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    console.log('daterange', this.value);
    return {
      queryType: this.value === null ? 'month' : this.value.type,
      range: !this.nonRange,
      datePicked:
        this.value === null
          ? !this.range
            ? new Date(new Date().setDate(new Date().getDate() - 1))
            : [
                new Date(new Date().setDate(new Date().getDate() - 7)),
                new Date(new Date().setDate(new Date().getDate())),
              ]
          : !this.range
          ? this.value.date
          : this.value.range,
    };
  },
  computed: {
    langForDatepicker: {
      get() {
        return this.$i18n.locale === 'zh-Hant' ? 'zh-cn' : this.$i18n.locale;
      },
    },
    queryTypeGroup: {
      get() {
        return ['day', 'week', 'month', 'year'].map((r) => ({
          text: this.$t(r),
          value: r,
        }));
      },
    },
    datePickerMode: {
      get() {
        return this.queryType === 'day' ? 'date' : this.queryType;
      },
    },
    dateFormat: {
      get() {
        let _formatDate;
        switch (this.queryType) {
          case 'day':
            _formatDate = 'YYYY-MM-DD';
            break;
          case 'week':
            _formatDate = 'YYYY ww';
            break;
          case 'month':
            _formatDate = 'YYYY-MM';
            break;
          case 'year':
            _formatDate = 'YYYY';
            break;
        }
        return _formatDate;
      },
    },
    val: {
      get() {
        const obj = {
          type: this.queryType,
          format: this.dateFormat,
        };
        if (this.range) obj.range = this.datePicked;
        else obj.date = this.datePicked;
        return obj;
      },
    },
  },

  watch: {
    datePicked(_val) {
      this.$emit('input', this.val);
    },
    queryType(_val) {
      this.$emit('input', this.val);
    },
  },
  created() {
    this.$emit('input', this.val);
  },
  methods: {
    disableDate(date) {
      return this.warningDatePickerDisableDate(date);
    },
  },
};
