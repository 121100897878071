
import { mapMutations } from 'vuex';
import Footer from '@/components/core/Footer';
// import ApiService from '@/services/api-service.js';

export default {
  layout: 'login',
  components: {
    CoreFooter: Footer,
  },

  async mounted() {
    /*
    const resp = await ApiService.getUserRights(this.$axios);
    if (!resp.isSuspended) {
      this.$router.push('/');
    }
    */
  },
  methods: {
    ...mapMutations('app', ['clear_Token']),
    Signout() {
      this.clear_Token();
      this.$signout(this.$msal);
    },
  },
};
