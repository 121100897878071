
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/api-service.js';

export default {
  name: 'Compare',
  props: {
    query: {
      type: Object,
      default: () => {},
    },
    compareDialog: Boolean,
  },
  data() {
    return {
      profileType: [],
      options: [],
      currentOption: '',
      compareOption: '',
      optionsList: [],
      currentQuery: {},
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 440,
          stacked: true,
        },
        colors: ['#008FFB', '#FF4560'],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },

        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          min: -100,
          max: 100,
          // title: {
          //   text: 'Age',
          // },
        },
        tooltip: {
          shared: false,
          x: {
            formatter(val) {
              return val;
            },
          },
          y: {
            formatter(val) {
              return Math.abs(Math.round(val));
            },
          },
        },
        title: {
          text: this.$t('OverallPerformance'),
        },
        xaxis: {
          categories: [],
          labels: {
            formatter(val) {
              return Math.abs(Math.round(val));
            },
          },
        },
        noData: {
          text: [this.$t('NoData')],
        },
      },
      negativeList: [],
      positiveList: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.compareDialog;
      },
      set(value) {
        this.$emit('close', value);
      },
    },
  },
  watch: {
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
    },
    compareDialog(val) {
      this.dialog = JSON.parse(val);
      if (val) {
        this.setOptions();
        setTimeout(() => {
          this.compareOption = [];
          this.positiveList = [];
          this.getScore(this.query);
        }, 500);
      }
    },
  },
  methods: {
    getLang(item) {
      return this.$t(item.text);
    },
    setOptions() {
      this.currentOption = this.query.TargetId;
      this.options = [];
      ApiService.getWarningDataOptions(this.$axios).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            switch (item.name) {
              case 'Driver':
              case 'Vehicle':
                this.options.push({
                  text: item.name,
                  value: item.value,
                  url: item.url,
                });
                break;
              default:
                break;
            }
          });

          this.options.forEach((item) => {
            if (item.value === this.query.TargetType) {
              this.profileType = item;
            }
          });

          setTimeout(() => {
            this.getOptionList();
          }, 500);
        }
      });
    },
    onChange() {
      this.getOptionList();
    },
    getOptionList() {
      this.optionsList = [];
      ApiService.getWarningDataOptionList(
        this.$axios,
        this.profileType.url
      ).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            this.optionsList.push({
              id: item,
              name: item,
            });
          });
        }
      });
    },
    currentChange() {
      const newquery = {
        TargetType: this.query.TargetType,
        TargetId: this.currentOption,
        Date: this.query.Date,
        ProfileType: this.query.ProfileType,
        OutputType: this.query.OutputType,
        Distance: this.query.Distance,
      };
      this.getScore(newquery);
    },
    compareChange() {
      const newquery = {
        TargetType: this.query.TargetType,
        TargetId: this.compareOption,
        Date: this.query.Date,
        ProfileType: this.query.ProfileType,
        OutputType: this.query.OutputType,
        Distance: this.query.Distance,
      };
      this.getCompareScore(newquery);
    },
    getScore(query) {
      this.series = [];
      this.negativeList = [];
      ApiService.getProfileOverview(this.$axios, query).then((response) => {
        if (response && response.scores) {
          const filteredkeys = Object.keys(response.scores).filter((w) =>
            this.aggregateWarningFilterFunc(w, this.$store.state.app.profileViewMode)
          );
          if (this.positiveList.length === 0) {
            
            this.chartOptions.xaxis.categories = filteredkeys.map(w => this.$t(w.toLowerCase()) )
            this.$refs.compareChart.updateOptions(this.chartOptions);
          }
          
           this.negativeList = filteredkeys.map((m) => {
            const item = response.scores[m];
            return item ===0?0:-item;
           })
          
          this.updateChart();
        }
      });
    },
    getCompareScore(query) {
      this.series = [];
      this.positiveList = [];
      ApiService.getProfileOverview(this.$axios, query).then((response) => {
        if (response && response.scores) {
          const filteredkeys = Object.keys(response.scores).filter((w) =>
            this.aggregateWarningFilterFunc(w, this.$store.state.app.profileViewMode)
          );
          if (this.negativeList.length === 0) {
            this.chartOptions.xaxis.categories = filteredkeys;
            this.$refs.compareChart.updateOptions(this.chartOptions);
          }

          this.positiveList = filteredkeys.map((m) => {
            const item = response.scores[m];
            return item === 0 ? 0 : item;
           })
          this.updateChart();
        }
      });
    },
    updateChart() {
      this.$refs.compareChart.updateSeries([
        {
          name: this.currentOption,
          data: this.negativeList.length !== 0 ? this.negativeList : [],
        },
        {
          name: this.compareOption.length !== 0 ? this.compareOption : '',
          data: this.positiveList.length !== 0 ? this.positiveList : [],
        },
      ]);
    },
  },
};
