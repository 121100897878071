import { render, staticRenderFns } from "./QueryBox.vue?vue&type=template&id=61ed180e&scoped=true"
import script from "./QueryBox.vue?vue&type=script&lang=js"
export * from "./QueryBox.vue?vue&type=script&lang=js"
import style0 from "./QueryBox.vue?vue&type=style&index=0&id=61ed180e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ed180e",
  null
  
)

export default component.exports