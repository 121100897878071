
/* eslint-disable vue/no-template-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { backgroundColor } from '@/plugins/config';
// import ApiService from '@/services/api-service.js';
import VideoDialog from '@/components/dialog/VideoDialog';

export default {
  name: 'TablePanel',
  components: {
    VideoDialog,
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    tabName: {
      type: String,
      default: '',
    },
    csvTitle: {
      type: String,
      default: '',
    },
    downloadList: {
      type: Array,
      default: () => [],
    },
    groupheaders: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    benchmarkItem: {
      type: Object,
      default: () => {},
    },
    query: {
      type: Object,
      default: () => {},
    },

    showLoading: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      //* cannot use mixin because need to defaul true
      //* here take precendence
      dataTableShowLoading: this.csvTitle !== 'RiskyWarningHighlight',
      dangerousVideoItem: [],
      recommendflag: false,
      recommendmsg: {},
      backgroundColor,
      search: '',

      pagination: {
        sortBy: null,
        descending: false,
      },
      sortBy: '',
      sortDesc: false,
      sortTag: 0,

      // VIDEO
      videoDialog: false,
      media: '',

      // IMAGE (Google maps)
      imgDisplay: false,
      lat: null,
      lng: null,
      location: '',
      src: 'https://maps.googleapis.com/maps/api/staticmap?center=',
      marker: '&markers=',
      params:
        '&zoom=16&size=660x460&key=AIzaSyDnCqlHgVv_C23CFiIYhS20l3qfP4BIxUk',
    };
  },
  computed: {
    showExpand() {
      // return !this.isMyMobile;
      // TODO comment out to first deploay agg view
      return false;
    },
    messages() {
      const tempmessages = [];
      if (this.recommendmsg !== null) {
        for (const key of Object.keys(this.recommendmsg)) {
          tempmessages.push({ name: key, value: this.recommendmsg[key] });
        }
      }
      return tempmessages;
    },
    // dangerousVideoheaders() {
    //   return [
    //     { text: this.$t('video'), value: 'video' },
    //     { text: this.$t('Location'), value: 'location' },
    //     { text: this.$t('Latitude'), value: 'lat' },
    //     { text: this.$t('Longitude'), value: 'lng' },
    //     { text: this.$t('warningType'), value: 'warningType' },
    //     { text: this.$t('startTime'), value: 'startTime' },
    //     { text: this.$t('vehicle'), value: 'vehicle' },
    //     { text: this.$t('vehicleDept'), value: 'vehicleDept' },
    //     { text: this.$t('driver'), value: 'driver' },
    //     { text: this.$t('driverDept'), value: 'driverDept' },
    //     { text: this.$t('RateOfChange'), value: 'rateOfChange' },
    //     { text: this.$t('duration'), value: 'duration' },
    //     { text: this.$t('startSpeed'), value: 'startSpeed' },
    //     { text: this.$t('endSpeed'), value: 'endSpeed' },
    //     { text: this.$t('topSpeed'), value: 'topSpeed' },
    //     { text: this.$t('hw'), value: 'hw' },
    //     { text: this.$t('minHW'), value: 'nearestHw' },
    //     { text: this.$t('driverAction'), value: 'driverAction' },
    //   ];
    // },
  },
  watch: {
    query(val) {
      // OnChangeQuery
      this.dangerousVideoItem = [];
      this.recommendmsg = {};
      this.recommendflag = false;
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
    },
    showLoading(val) {
      this.setDataTableShowLoading(val);
    },
  },
  mounted() {
    this.onResize();
  },
  methods: {
    refresh() {
      this.$emit('reload', this.csvTitle);
    },
    changeSort(column) {
      if (this.sortBy === column) {
        if (this.sortTag === 0) {
          this.sortDesc = !this.sortDesc;
          this.sortTag = 1;
        } else {
          this.sortBy = '';
          this.sortDesc = false;
          this.sortTag = 0;
        }
      } else {
        this.sortBy = column;
        this.sortDesc = false;
        this.sortTag = 0;
        // this.pagination.sortBy = column;
        // this.pagination.descending = false;
      }
    },
    playVideo(value) {
      this.media = value;
      this.videoDialog = true;
    },
    source(item) {
      // Reset src path and center string
      this.src = 'https://maps.googleapis.com/maps/api/staticmap?center=';
      this.location = '';
      this.marker = '&markers=';

      // Pop Up Window
      this.imgDisplay = true;

      // Locate Map (Static Google Map API)
      if (item.lat && item.lng !== null) {
        this.lat = item.lat;
        this.lng = item.lng;
        this.location = this.location.concat(this.lat, ',', this.lng);
        this.marker = this.marker.concat(this.location);
        this.src = this.src.concat(this.location, this.marker, this.params);
      }
    },
    getImage(itemVal) {
      if (itemVal === false) return false;
      else return true;
    },

    detail(item) {
      this.$emit('handleClick', item);
    },
    // createObj(Obj) {
    //   return {
    //     video: Obj.mediaFullId,
    //     lat: Obj.lat,
    //     lng: Obj.lng,
    //     warningType: Obj.warningTypeText,
    //     startTime: Obj.startTime,
    //     vehicle: Obj.vehiclePlate,
    //     vehicleDept: Obj.vehicleDepartment,
    //     driver: Obj.driverName,
    //     driverDept: Obj.driverDepartment,
    //     rateOfChange: this.getRateOfChange(
    //       Obj.startSpeed,
    //       Obj.endSpeed,
    //       Obj.duration
    //     ).toFixed(3),
    //     duration: Obj.duration,
    //     startSpeed: Obj.startSpeed,
    //     endSpeed: Obj.endSpeed,
    //     topSpeed: Obj.topSpeed,
    //     hw: Obj.hw,
    //     nearestHw: Obj.minHW,
    //     driverActionl: Obj.leftSignalOn,
    //     driverActionr: Obj.rightSignalOn,
    //     driverActionb: Obj.brakeOn,
    //   };
    // },
    getRateOfChange(startSpeed, endSpeed, duration) {
      return (startSpeed - endSpeed) / duration;
    },
    checkMsgValue(obj) {
      return obj != null;
    },
    setDriverAction(item) {
      let driverAction = '';
      // if (item.brakeOn) driverAction += 'B';
      if (item.driverActionb) driverAction += 'B';
      // if (item.leftSignalOn) driverAction += 'L';
      if (item.driverActionl) driverAction += 'L';
      // if (item.rightSignalOn) driverAction += 'R';
      if (item.driverActionr) driverAction += 'R';
      return driverAction;
    },
  },
};
