
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/api-service.js';
import moment from 'moment';
import { mapMutations } from 'vuex';

import {
  Background,
  // guageColor,
  // gradeColor,
  doughnutColor,
  // safetyScoreColor,
  // backgroundColor,
  profileLineChartColor,
} from '@/plugins/config';

export default {
  name: 'ProfileTable',
  // components: {
  //   // ExpansionPanelService,
  // },
  data: () => ({
    warningDef: [],
    warningDefDialog: false,
    // stat card below rank
    drivingStat: {},
    origRecommendations: {},
    warningCountPer100Items: [],
    warningScoreMap: {},
    gradeChartType: 'doughnut',
    gradeLabels: [],
    gradeDatasets: [
      {
        label: 'GradeDatasets',
        backgroundColor: doughnutColor,
        data: null,
      },
    ],
    gradeOptions: {
      maintainAspectRatio: false,
      responsive: true,
      cutout: '75%',
      // cutoutPercentage: 70,
      // legend: { display: false },
      // tooltips: { enabled: false },
      plugins: {
        legend: { display: false },
        tooltips: { enabled: false },
        datalabels: {
          display: false,
        },
      },
    },
    companyAvg: false,
    grade: '',
    companyGrade: '',
    gradeDialog: false,
    compareDialog: false,
    currentPoint: {},
    totalScore: '',
    companyTotalScore: '',
    overviewChartType: 'polarArea',
    rawOverviewLabels: [],
    overviewLabels: [],
    zeroScoreLabels: [],
    emptyWarnFlag: false,
    overviewDatasets: [
      {
        label: 'OverviewDatasets',
        backgroundColor: Background,
        data: null,
      },
    ],
    overviewOptions: {
      responsive: true,
      maintainAspectRatio: false,
      // legend: {
      //   display: false,
      // },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'start',
          align: 'end',
          offset: 120,
          formatter(value, context) {
            return context.chart.data.labels[context.dataIndex];
          },
        },
      },
      scale: {
        angleLines: {
          display: true,
        },
        pointLabels: {
          display: false,
        },
      },
    },
    // safety score
    safetyScoreType: 'line',
    safetyScoreLabels: [],
    safetyScoreDatasets: [
      {
        label: 'Safety Score',
        // backgroundColor: safetyScoreColor,
        borderColor: profileLineChartColor,
        data: null,
        fill: false,
      },
    ],
    safetyScoreOptions: {
      // maintainAspectRatio: false,
      responsive: true,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        // yAxes: [
        //   {
        //     ticks: { min: 0, max: 100 },
        //   },
        // ],
      },
    },
    query: {
      TargetType: 'company',
      Date: new Date().toISOString().substr(0, 10),
      ProfileType: 'month',
    },
    collisionLabels: [],
    collisionDatasets: [],
    badDatasets: [],
    badLabels: [],
    comfortDatasets: [],
    comfortLabels: [],

    safetyQuery: {},
    componentKey: 0,
    name: '',
    score: 0,
    remainingScore: 100,
    scoreLabels: [],
    scoreData: [],

    collisionDataset: [],

    badDrivingBehaviourDataset: [],
    comfortabilityDataset: [],
    lazy: [],

    compareData: [],
    queryTag: true,
    recommendations: {},
    showMsg: false,
    msg: '',
    compareScore: false,

    warningFilter: [],
  }),
  computed: {
    warningFilterOptions: {
      get() {
        const warnings = Object.keys(this.warningScoreMap).filter((w) =>
          this.aggregateWarningFilterFunc(
            w,
            this.$store.state.app.profileViewMode
          )
        );

        return warnings.map((k) => ({
          label: this.$t(k.toUpperCase()),
          value: k,
        }));
      },
    },
    showIdling: {
      get() {
        console.log('show idling', this.$store.state.app.showIdling);
        if (this.$store.state.app.company) {
          return this.$store.state.app.showIdling;
        }
        return false;
      },
    },
  },

  watch: {
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      //! useless again, coz not depending on locale, same result
      // this.getWarningGroup(this.query);
    },
    '$store.state.app.company'(val) {
      //! this is useless because if no param defined, return immediately
      // this.getProfileOverview();
    },
    '$store.state.app.compareScore'(val) {
      this.compareScore = val;
    },
    '$store.state.app.profileViewMode'(val) {
      console.log(val);
      this.warningFilter = this.warningFilterOptions.map((w) => w.value);
      // this.adjustDataOnProfileViewMode(val);
      // ! here reset warningFilter and watch warningFilter below will update view
      // ! so here no need call update view
    },
    '$store.state.app.warningFilter'(_val) {
      this.adjustDataOnProfileViewMode(this.$store.state.app.profileViewMode);
    },
    warningFilter(val) {
      this.set_WarningFilter(val);
    },
    // * watch warningdef dialog
    async warningDefDialog(val) {
      if (val) {
        await this.openWarningInfo();
      }
    },
  },
  // The states for app company are now placed at mounted()
  // As in created, the state for app company is not yet defined yet.
  // Perferrably put in mounted() for now
  mounted() {
    this.get_ProfileViewMode();
    if (window.opener != null) {
      try {
        const clickedQuery = JSON.parse(localStorage.clickedQuery);
        this.query = clickedQuery;
      } catch (e) {
        console.log(e);
      }
    }
    this.compareScore = this.$store.state.app.compareScore;
  },
  methods: {
    // * show warning def
    async openWarningInfo() {
      const availableWarningsValue = this.warningFilterOptions.map(
        (w) => w.value
      );
      const respJson = await this.$axios.$get('/api/CompanyWarningTypes/info');
      this.warningDef = respJson?.items
        ?.filter(({ warningType }) =>
          availableWarningsValue.includes(warningType)
        )
        .map((w) => {
          const { warningTypeText, fullname, description } = w;
          return { warningTypeText, fullname, description };
        });
    },
    warningFilterRemove(w) {
      const index = this.$store.state.app.warningFilter.indexOf(w.value);
      if (index >= 0) {
        const clone = [...this.warningFilter];
        clone.splice(index, 1);
        this.warningFilter = clone;
      }
    },
    ...mapMutations('app', ['get_ProfileViewMode', 'set_WarningFilter']),
    prepareRecommendations(detailMode) {
      if (!this.origRecommendations) return;

      this.recommendations = Object.keys(this.origRecommendations)
        .filter(
          (w) =>
            this.aggregateWarningFilterFunc(w, detailMode) &&
            this.$store.state.app.warningFilter.includes(w.toUpperCase())
        )
        .reduce((prev, key) => {
          prev[key] = this.origRecommendations[key];
          return prev;
        }, {});
    },
    prepareOverviewData(detailMode) {
      if (!this.warningScoreMap) return;
      //* overview chart
      const label = Object.keys(this.warningScoreMap).filter(
        (w) =>
          this.aggregateWarningFilterFunc(w, detailMode) &&
          this.$store.state.app.warningFilter.includes(w.toUpperCase())
      );

      this.overviewDatasets[0].data = label.map(
        (_label) => this.warningScoreMap[_label]
      );
      this.overviewLabels = label.map((k) => this.$t(k.toUpperCase()));
      this.rawOverviewLabels = label;

      this.zeroScoreLabels = label
        .filter((_label) => this.warningScoreMap[_label] <= 20)
        .map((_label, ind) => {
          const localeLabel = this.$t(_label.toUpperCase());
          return {
            name: localeLabel,
            btnLabel: `${localeLabel}: ${this.warningScoreMap[_label]}`,
            btnBgColor: this.overviewDatasets[0].backgroundColor[ind],
            currentPoint: {
              score: 0,
              label: localeLabel,
              rawLabel: _label,
              index: ind,
            },
          };
        });
      this.emptyWarnFlag = label.length === 0;
    },
    prepareWarningGroupTimelineChartData(detailMode) {
      //* warningCountPer100 chart
      if (!this.warningGroup || this.warningGroup.length === 0) return;
      let collisionLabel = this.warningGroup[0] ?? [];
      let badLabel = this.warningGroup[1] ?? [];
      let comfortLabel = this.warningGroup[2] ?? [];

      collisionLabel = collisionLabel.filter(
        (w) =>
          this.aggregateWarningFilterFunc(w, detailMode) &&
          this.$store.state.app.warningFilter.includes(w.toUpperCase())
      );
      badLabel = badLabel.filter(
        (w) =>
          this.aggregateWarningFilterFunc(w, detailMode) &&
          this.$store.state.app.warningFilter.includes(w.toUpperCase())
      );
      comfortLabel = comfortLabel.filter(
        (w) =>
          this.aggregateWarningFilterFunc(w, detailMode) &&
          this.$store.state.app.warningFilter.includes(w.toUpperCase())
      );

      const DateObj = [];

      let i = 0;
      let j = 0;
      let k = 0;

      const tempObj = this.warningCountPer100Items.reduce(
        (prev, cur, index) => {
          DateObj.push(this.formatDate(cur.accDate));
          const results = cur.results;

          Object.keys(results).forEach((key) => {
            let tlist, kolor;
            if (collisionLabel.includes(key)) {
              tlist = prev.collision;
              kolor = profileLineChartColor[i]; // Background[i];
              i++;
            }
            if (badLabel.includes(key)) {
              tlist = prev.bad;
              kolor = profileLineChartColor[j]; // Background[j + 5];
              j++;
            }
            if (comfortLabel.includes(key)) {
              tlist = prev.comfort;
              kolor = profileLineChartColor[k]; // backgroundColor[k];
              k++;
            }
            if (tlist !== undefined) {
              if (tlist[key] === undefined) tlist[key] = { c: kolor, d: [] };
              tlist[key].d.push(results[key]);
            }
          });

          if (index >= this.warningCountPer100Items.length - 1) {
            // last item do reverse
            return Object.keys(prev).reduce((p, l) => {
              const warninggroup = prev[l];

              p[l] = Object.keys(warninggroup).map((w) => {
                return {
                  // backgroundColor: warninggroup[w].c,
                  borderColor: warninggroup[w].c,
                  fill: false,
                  label: this.$t(w),
                  data: warninggroup[w].d.reverse(),
                };
              });
              return p;
            }, {});
          }
          return prev;
        },
        { collision: {}, bad: {}, comfort: {} }
      );

      this.collisionDatasets = tempObj.collision;
      this.badDatasets = tempObj.bad;
      this.comfortDatasets = tempObj.comfort;

      // label
      DateObj.reverse();
      this.collisionLabels = DateObj;
      this.badLabels = DateObj;
      this.comfortLabels = DateObj;
    },
    adjustDataOnProfileViewMode(detail) {
      this.prepareOverviewData(detail);
      this.prepareWarningGroupTimelineChartData(detail);
      this.prepareRecommendations(detail);
    },
    downloadImg(event) {
      const link = document.createElement('a');
      link.href = this.$refs[event].$el.toDataURL('image/jpg');
      link.download = this.$t(event) + '.png';
      link.click();
    },
    // sync GlobalQueryBox
    getQuery(form) {
      this.lazy = [];
      this.qryData = form;
      this.time = form.time;
      const newQuery = {
        TargetType: form.targetType ? form.targetType : 'company',
        TargetId: form.targetId,
        Date: moment(this.time).format('YYYY-MM-DD'),
        ProfileType: form.queryType,
        OutputType: form.targetType ? form.targetType : '',
        Distance: form.km,
      };

      if (this.query !== newQuery) {
        this.query = newQuery;
        this.companyAvg = false;
        this.getProfileOverview(this.query);
      }
    },
    // Only for company grade
    getCompanyGrade(query) {
      ApiService.getProfileOverview(this.$axios, query).then((response) => {
        if (response) {
          this.compareData = response;
          this.companyGrade = response.grade;
          this.companyTotalScore = response.totalScore;
          let color = '';
          if (response.totalScore > 60) {
            color = 'green';
          } else if (response.totalScore > 20) {
            color = 'yellow';
          } else {
            color = 'red';
          }

          if (this.gradeDatasets.length < 2) {
            this.gradeDatasets.push({
              data: [response.totalScore, 100 - response.totalScore],
              backgroundColor: [color, '#bfbfbf'],
              weight: 0.5,
            });
          }
        }
      });
    },
    // Overview
    getProfileOverview(query) {
      if (!query) {
        return;
      }
      if (this.queryTag) {
        this.queryTag = false;

        this.showMsg = false;
        ApiService.getProfileOverview(this.$axios, query)
          .then((response) => {
            if (response) {
              if (response.message !== '') {
                this.showMsg = true;
                this.msg = response.message;
              }
              // initial dataset
              this.gradeDatasets = [
                {
                  label: '',
                  backgroundColor: doughnutColor,
                  data: null,
                },
              ];
              this.grade = response.grade;
              this.totalScore = response.totalScore;

              this.gradeLabels = ['', ''];

              if (response.totalScore) {
                let color = '';
                if (response.totalScore > 60) {
                  color = 'green';
                } else if (response.totalScore > 20) {
                  color = 'yellow';
                } else {
                  color = 'red';
                }
                this.gradeDatasets[0].backgroundColor = [color, '#bfbfbf'];
                this.gradeDatasets[0].weight = 1;
                this.gradeDatasets[0].data = [
                  response.totalScore,
                  100 - response.totalScore,
                ];
              }

              if (this.query.TargetType !== 'company') {
                const companyQuery = {
                  TargetType: 'company',
                  TargetId: this.query.TargetId,
                  Date: this.query.Date,
                  ProfileType: this.query.ProfileType,
                  OutputType: 'company',
                  Distance: this.query.Distance,
                };
                this.getCompanyGrade(companyQuery);
                this.companyAvg = true;
              }

              this.getSafetyScore();

              this.getWarningGroup();

              this.warningScoreMap = response.scores ?? {};

              //! set warningfilter options after get data from API
              this.warningFilter = this.warningFilterOptions.map(
                (w) => w.value
              );

              this.prepareOverviewData(this.$store.state.app.profileViewMode);

              if (response.recommendations) {
                this.origRecommendations = Object.entries(
                  response.recommendations
                ).reduce((prev, cur) => {
                  prev[cur[0]] = {
                    label: this.$t(cur[0]),
                    description: cur[1],
                  };
                  return prev;
                }, {});
                this.prepareRecommendations(
                  this.$store.state.app.profileViewMode
                );
              }

              this.drivingStat.distance = response.distance;
              this.drivingStat.idl = response.idl;
              this.drivingStat.idL_Duration = response.idL_Duration;
              this.drivingStat.idL_Rate = response.idL_Rate;
              this.drivingStat.idL_Longest = response.idL_Longest;
              this.drivingStat.duration = response.duration;
            }
            this.queryTag = true;
          })
          .catch((error) => {
            console.log(
              'ERROR: Profile Warning Group ' + JSON.stringify(error.response)
            );
            console.trace();
            this.queryTag = true;
          });
      }
    },
    // SafetyScore
    getSafetyScore() {
      this.safetyQuery = this.query;
      // this.safetyQuery.PageSize = 12;
      // this.safetyQuery.Page = 1;
      ApiService.getSafetyScore(this.$axios, this.safetyQuery).then(
        (response) => {
          if (response) {
            const ChartObj = [];
            const DateObj = [];
            let word = '';
            if (response.items) {
              response.items.forEach((p) => {
                ChartObj.push(p.totalScore);
                word = this.formatDate(p.accDate);
                DateObj.push(word);
              });
            }
            // Reverse array so that it starts from the oldest time
            ChartObj.reverse();
            DateObj.reverse();

            this.safetyScoreLabels = DateObj;
            this.safetyScoreDatasets[0].data = ChartObj;
            this.safetyScoreDatasets[0].label = this.$t('SafetyScore');
          }
        }
      );
    },
    // Warning Group
    getWarningGroup() {
      const promises = Promise.all([
        ApiService.getWarningGroup(this.$axios, this.query),
        ApiService.getWarningCountper100(this.$axios, this.safetyQuery),
      ]);

      promises
        .then((responses) => {
          let response = responses[0];
          this.warningGroup = [
            response.dangerous,
            response.bad,
            response.comfort,
          ];

          // WarningCountper100
          response = responses[1];
          if (response) {
            this.collisionDatasets = [];
            this.badDatasets = [];
            this.comfortDatasets = [];
            if (response.items) {
              this.warningCountPer100Items = response.items;
              this.prepareWarningGroupTimelineChartData(
                this.$store.state.app.profileViewMode
              );

              //! below is on9 logic, redundant loop,
              /*
              // Set Dataset
              collisionLabel.forEach((k, i) => {
                collisionList.push({
                  label: k,
                  backgroundColor: Background[i],
                  fill: true,
                  data: [],
                });
              });
              badLabel.forEach((k, i) => {
                badList.push({
                  label: k,
                  backgroundColor: Background[i + 5],
                  fill: true,
                  data: [],
                });
              });
              comfortLabel.forEach((k, i) => {
                comfortList.push({
                  label: k,
                  backgroundColor: backgroundColor[i],
                  fill: true,
                  data: [],
                });
              });

              response.items.forEach((p) => {
                Object.keys(p.results).forEach(function (k, v) {
                  collisionList.forEach((item) => {
                    if (item.label === k) {
                      item.data.push(p.results[k]);
                    }
                  });
                  badList.forEach((item) => {
                    if (item.label === k) {
                      item.data.push(p.results[k]);
                    }
                  });
                  comfortList.forEach((item) => {
                    if (item.label === k) {
                      item.data.push(p.results[k]);
                    }
                  });
                });
                // label
                word = this.formatDate(p.accDate);
                DateObj.push(word);
              });
              
              // Reverse array so that it starts from the oldest time
              collisionList.forEach((item) => {
                item.label = this.$t(item.label);
                item.data.reverse();
              });
              this.collisionDatasets = collisionList;
              badList.forEach((item) => {
                item.label = this.$t(item.label);
                item.data.reverse();
              });
              this.badDatasets = badList;
              comfortList.forEach((item) => {
                item.label = this.$t(item.label);
                item.data.reverse();
              });
              this.comfortDatasets = comfortList;
              */

              // console.log('Chart ok');
            }
          }
        })
        .catch((error) => {
          console.log(
            'ERROR: Profile Warning Group ' + JSON.stringify(error.response)
          );
        });
    },
    getProfilePoint(currentPoint) {
      this.gradeDialog = true;
      this.currentPoint = currentPoint;
    },
    formatDate(date) {
      switch (this.query.ProfileType) {
        case 'day':
          return moment(date).format('YYYY/MM/DD');
        // break;
        case 'week':
          return 'W' + moment(date).format('ww');
        // break;
        case 'month':
          return moment(date).format('YYYY/MM');
        // break;
        case 'year':
          return moment(date).format('YYYY');
        // break;
        default:
          return moment(date).format('YYYY/MM/DD');
      }
    },
    compare() {
      this.compareDialog = true;
    },
  },
};
