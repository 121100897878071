
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';
import moment from 'moment';
import { roleMap } from '@/plugins/config';

export default {
  name: 'UserRole',
  components: {
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
    UserRoleDetailDialog: () => {
      if (process.client) {
        return import('@/components/dialog/UserRoleDetailDialog');
      }
    },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  data: () => ({
    roleMap,
    UpdateAuth: false,
    // // 搜尋框輸入值
    Search: '',
    // // datatable 的欄寬平均
    calculateWidths: true,
    downloadList: [],
    ItemsList: [],
    // 新增功能Dialog
    addDialog: false,
    updateValue: {},
    componentKey: 0,
    displayDelete: false,
    // Before delete items
    beforeDelete: false,
    itemsToBeDeleted: {},
    showMsg: false,
    msg: '',
  }),
  computed: {
    Headers() {
      return [
        { text: this.$t('User.roleId'), value: 'id', width: 100 },
        { text: this.$t('User.roleName'), value: 'roleName' },
        { text: this.$t('User.description'), value: 'description' },
        // { text: this.$t('User.isAdmin'), value: 'isAdmin', width: 100 },

        { text: this.$t('roleType'), value: 'role', width: 120 },
        {
          text: this.$t('User.functions'),
          value: 'roleFunctions',
          width: 400,
        },
        { text: this.$t('Setting.lastUpdated'), value: 'updatedAt' },
        {
          text: this.$t('Setting.crudActions'),
          value: 'crudActions',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    displayDelete() {
      setTimeout(() => (this.displayDelete = false), 3000);
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setDownloadList();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.getRoles();
    },
    '$store.state.app.userRole'(val) {
      this.checkAuth();
    },
  },
  mounted() {
    this.getRoles();
    this.checkAuth();
  },
  methods: {
    getRoles() {
      this.ItemsLis = [];
      this.wrapShowloading(
        ApiService.getRoles(this.$axios)
          .then((response) => {
            if (response) {
              this.ItemsList = response;
              this.setDownloadList();
            }
          })
          .catch({})
      );
    },
    checkAuth() {
      this.UpdateAuth = false;
      const val = this.$store.state.app.userRole;
      if (
        val === 'HQ' ||
        val === 'Administrator' ||
        this.$store.state.app.isAdmin
      ) {
        this.UpdateAuth = true;
      }
    },
    setDownloadList() {
      if (this.ItemsList.length > 0) {
        this.downloadList = [];
        this.ItemsList.forEach((item, i) => {
          this.downloadList.push({});
          this.downloadList[i][this.$t('User.roleId')] = item.id;
          this.downloadList[i][this.$t('User.roleName')] = item.roleName;
          this.downloadList[i][this.$t('User.description')] = item.description;
          this.downloadList[i][this.$t('User.isAdmin')] = item.isAdmin;
          this.downloadList[i][this.$t('Setting.lastUpdated')] =
            moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
            ' (' +
            item.updatedBy +
            ')';
        });
      }
    },
    updateData(val) {
      this.updateValue = val;
      this.addDialog = true;
    },
    beforeDeleteData(val) {
      console.log(val);
      this.beforeDelete = true;
      this.itemsToBeDeleted = val;
    },
    deleteData(val) {
      this.beforeDelete = false;

      // Delete the records in Vuetify datatable and refresh.
      this.ItemsList = this.ItemsList.filter((item) => item.id !== val.id);
      this.forceRerender();
      this.displayDelete = true;

      ApiService.deleteDrivers(this.$axios, val.id);
    },
    downloadCSV() {
      // eslint-disable-next-line
      let csvcontent = JSON.parse(JSON.stringify(this.ItemsList));

      // Acquire headers
      const csvheaders = this.Headers.map((obj) => obj.value);

      // Special add
      csvheaders.push('updatedBy', 'updatedAt');

      // Format companyList as same as display
      csvcontent.forEach((item) => {
        Object.entries(item).forEach(([key]) => {
          // If none of the key in csvcontent satisfies the headers, remove them.
          if (!csvheaders.includes(key)) delete item[key];
        });
      });
      return csvcontent;
    },
    addData() {
      this.addDialog = true;
    },
    clearUpdateValue() {
      this.updateValue = {};
    },
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerenderOnSubmit(val) {
      this.componentKey += 1;
      this.getRoles();
      this.showMsg = true;
      this.msg = val.message;
    },
    DescArray(array) {
      // eslint-disable-next-line
      let newarray = [];
      array.forEach((item) => {
        newarray.unshift(item);
      });
      return newarray;
    },
  },
};
