
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import ApiService from '@/services/api-service.js';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import TDRoutesOnMap from '@/components/TDRoutesOnMap';
import AutocompleteWithSelectAll from '@/components/global/AutocompleteWithSelectAll';
import geofenceMixin from '@/plugins/geofenceMixin';
export default {
  name: 'HistoricalLocation',
  components: {
    TDRoutesOnMap,
    AutocompleteWithSelectAll,
    Gmap: () => {
      if (process.client) {
        return import('@/components/GeofenceGoogleMap');
      }
    },
    Treeselect: () => {
      if (process.client) {
        return import('@riophae/vue-treeselect');
      }
    },
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  filters: {
    formatDateTime(date) {
      return moment(date.slice(0, -1)).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  mixins: [geofenceMixin],
  data() {
    return {
      queryDisabled: false,
      useGeofence: true,
      selectedGeofences: [],
      geofences: [],
      LiveCheckboxType: ['online', 'offline', 'stopped'],
      panel: 0,
      wayListCheckAllWarningDialog: false,

      selectAllWays: false,

      query: {},
      selectedWayColor: [],

      circlesData: [],
      tabs: null,

      checkedWays: {},
      wayList: [],

      dateRange: [
        moment(new Date(new Date().getTime() - 1000 * 60 * 10)).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        moment(new Date(new Date().getTime() - 1000 * 60 * 9)).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
      ],

      dateRangeKey: 0,
      drawerListLoading: false,
      warningComboBoxKey: 0,
      setOptionsCalled: false, // hack to prevent called twice

      // ==============================
      banner: false,
      leftDrawer: true,
      getWarning: [],

      optionType: [],
      optionsets: [],
      getOptions: [],
      optionsList: [],

      normalizer(node) {
        if (node.children == null || node.children.length === 0) {
          delete node.children;
        }
        return {
          id: node.name,
          label: node.name,
          children: node.children,
        };
      },
      noRecord: false,

      requiredRules: [
        (v) => {
          if (Array.isArray(v)) {
            return !!(v && v.length) || this.$t('Required');
          }
          return !!v || this.$t('Required');
        },
      ],
    };
  },
  computed: {
    // ===== geofences =====
    cGeofencesList() {
      return this.geofences; /* .map((g) => {
        return {
          text: g.meta[this.$store.state.app.locale] || g.name,
          value: g.name,
        };
      }); */
    },
    // =================================================================
    langForDatepicker: {
      get() {
        return this.$i18n.locale === 'zh-Hant' ? 'zh-cn' : this.$i18n.locale;
      },
    },
    wayListDownloadCsv: {
      get() {
        return this.convertToCsv(this.wayList);
      },
    },

    // ===================================
    likesAllItems() {
      return this.getOptions.length === this.optionsList.length;
    },
    likesSomeItems() {
      return this.getOptions.length > 0 && !this.likesAllItems;
    },

    icon() {
      if (this.likesAllItems) return 'mdi-close-box';

      if (this.likesSomeItems) return 'mdi-minus-box';

      return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    '$store.state.app.locale'(val) {
      // to trigger re render to call api to get list
      this.warningComboBoxKey += 1;
    },
    selectAllWays(checked) {
      if (checked) {
        this.wayListCheckAllWarningDialog = true;
      } else {
        for (const key of Object.keys(this.checkedWays)) {
          this.checkedWays[key] = false;
        }
        this.wayItemChecked();
      }
    },
    // ===================================
    optionType(val) {
      //! should be useless
      // this.optionsList = [];
      // ! because single selection
      // this.getOptions = [];
      // this.getOptions = null;

      //* when multiple selections
      if (
        val.value === 'driverdepartment' ||
        val.value === 'vehicledepartment'
      ) {
        this.getOptions = null;
      } else {
        this.getOptions = [];
      }
    },
    '$store.state.app.company'(val) {
      this.optionType = [];
      this.setOptions('company');
      // to trigger re render to call api to get list
      this.warningComboBoxKey += 1;
      this.getGeofences().then();
    },
  },
  async mounted() {
    this.setOptions('mounted');
    await this.getGeofences();
  },
  methods: {
    disabledBefore(date) {
      return this.warningDatePickerDisableDate(date);
    },
    async getGeofences() {
      /*
      this.geofences = await this.fetchGeofences();
      this.selectedGeofences = this.geofences.map((g) => g.name);
      */
      this.geofences = await this.getFencesForTreeSelect();
      this.selectedGeofences = this.geofences.map((g) => g.id);
    },
    dummy() {
      console.log(this.getWarning);
      this.getWarning = ['SPW_L'];
      this.dateRange = {
        type: 'day',
        date: moment('2022-09-13', 'YYYY-MM-DD').toDate(),
      };
      this.dateRangeKey += 1;
    },

    closeDialog(yes) {
      this.wayListCheckAllWarningDialog = false;
      if (yes) {
        const checked = this.selectAllWays;
        if (checked) {
          for (const way of this.wayList) {
            this.checkedWays[way.wayId] = true;
          }
        } else {
          for (const key of Object.keys(this.checkedWays)) {
            this.checkedWays[key] = false;
          }
        }
        this.wayItemChecked();
      } else {
        this.selectAllWays = false;
      }
    },
    openTrip(item) {
      console.log(item);
      const { TripId, t, utcts, vehicle, plate, vdept } = item;
      const opener = 'gfhisloc';
      localStorage.setItem(
        'openTrip',
        JSON.stringify({
          TripId,
          t,
          utcts,
          vehicle,
          plate,
          vdept,
        })
      );
      const href = window.location.origin + '/Trip';
      window.open(href, opener);
    },
    openNewTab(data) {
      console.log(data);
      let href = window.location.href;
      let newQuery = {
        TargetType: data.type,
        Date: moment(this.dateRange.date).format('YYYY-MM-DD'),
        ProfileType: this.dateRange.type,
      };

      if (data.tab === 'spwheatmap') {
        newQuery = {
          ...newQuery,
          ...{
            WarningTypes: this.getWarning,
            TargetItems: [data.id],
          },
        };
      } else {
        //* profile query
        // console.log(window.location);
        href = window.location.origin + '/Profile';
        newQuery = {
          ...newQuery,
          ...{
            TargetId: data.id,
            OutputType: data.type,
            Distance: 20,
          },
        };
      }
      const opener = 'spwheatmap';
      localStorage.setItem('clickedQuery', JSON.stringify(newQuery));
      window.open(href, opener);
      console.log(href, newQuery);
    },

    convertToCsv(items) {
      const _this = this;
      return items.map((i) => {
        // const addr = _this.$store.state.app.locale === 'en' ? i.address_EN : i.address_HK;
        const retObj = {};

        retObj[_this.$t('Live.Running_Status') + ' (GPS)'] = _this.$t(
          'Live.' +
            i.liveInfoStatus.status.charAt(0).toUpperCase() +
            i.liveInfoStatus.status.slice(1) +
            'Tip'
        );
        retObj[_this.$t('Trip.vehicle')] = i.plate;
        retObj[_this.$t('Trip.department')] = i.vdept;
        retObj[_this.$t('Trip.driver')] = i.driver ?? '';
        retObj[_this.$t('Trip.driverGroup')] = i.ddept ?? '';
        retObj[_this.$t('Trip.time')] = _this.$options.filters.formatDateTime(
          i.t
        );
        retObj[_this.$t('Trip.speed')] = i.speed;
        retObj[_this.$t('Trip.bearing')] = i.bearing;
        retObj[_this.$t('Trip.satellites')] = i.satellites;
        retObj[_this.$t('fence')] = i.fence ? i.fence.en : 'n/a';

        return retObj;
      });
    },

    wayItemChecked(item) {
      // console.log(this.checkedWays);
      // console.log(item);
      const selectedIds = Object.keys(this.checkedWays).filter(
        (id) => this.checkedWays[id]
      );
      // console.log(selectedIds);
      const selectedWays = this.wayList.filter((w) =>
        selectedIds.includes(w.wayId)
      );

      // const maxWarningCount = Math.max(
      //  ...selectedWays.map((w) => w.warningCount)
      // );

      // console.log(selectedWays);

      this.circlesData = selectedWays.map((w) => {
        //* 2km is 100%
        const fullRadius = 500;
        const min = 100;
        // TODO if want to compare only selected, should use selected max
        // TODO otherwise use all way,
        // TODO use limited max , or proportional max ?
        w.info = `${w.warningPctg}%`;
        w.radius = fullRadius - min;
        // min + ((fullRadius - min) * w.warningCount) / maxWarningCount;
        w.center = { lat: w.lat, lng: w.lng };
        w.fillColor = '#B300F5';
        /*
          w.warningPctg <= 50
            ? //* 255 change color
              '#B300F5' // '#00FFFF' // '#FFFF00'
            : w.warningPctg <= 90
            ? '#F50000' // '#FF8C00' // darkorange
            : '#000000'; // '#FF0000';
            */
        return w;
      });
      // console.log(this.circlesData);
    },
    // ===================================

    getLang(item) {
      return this.$t(item.text);
    },
    onChange() {
      if (this.optionType.value !== 'company') {
        this.getOptionList(this.optionType.url);
      }
    },

    getOptionList(url) {
      ApiService.getWarningDataOptionList(this.$axios, url).then((response) => {
        this.optionsList = response.items ?? [];
      });
    },

    selectAll() {
      this.$nextTick(() => {
        if (this.likesAllItems) {
          this.getOptions = [];
        } else {
          this.getOptions = this.optionsList.slice();
        }
      });
    },

    setOptions(from) {
      //! set company also call, so quick hack to prevent
      if (this.setOptionsCalled) return;
      this.setOptionsCalled = true;
      console.log('setOptions from %s', from);
      this.optionsets = [];

      /*
      this.optionsets.push({
        text: 'Company',
        value: 'company',
        type: 'company',
        url: '',
        active: true,
        disabled: false,
      });
      */

      ApiService.getWarningDataOptions(this.$axios).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            switch (item.name) {
              case 'Driver':
              case 'Driver Group':
              case 'Driver Department':
              case 'Vehicle':
              case 'Vehicle Group':
              case 'Vehicle Department':
                this.optionsets.push({
                  text: item.name,
                  value: item.value,
                  type: item.type,
                  url: item.url,
                  active: true,
                  disabled: false,
                });
                break;
              default:
                break;
            }
          });

          if (window.opener === null) {
            this.optionType = this.optionsets[0];
            this.onChange();
            // this.submit();
          } else {
            this.queryFromParent();
          }
        }
      });
    },
    async queryFromParent() {
      console.log('queryFromParent');
      try {
        const clickedQuery = JSON.parse(localStorage.clickedQuery);
        const optionType = {
          text:
            clickedQuery.TargetType.charAt(0).toUpperCase() +
            clickedQuery.TargetType.slice(1),
          value: clickedQuery.TargetType,
          type: 'DropDown',
        };
        if (clickedQuery.TargetType === 'vehicle') {
          optionType.url = '/api/warningData/GetVehicleList';
          optionType.parameter = 'VehicleFilter';
        } else if (clickedQuery.TargetType === 'driver') {
          optionType.url = '/api/warningData/GetDriverList';
          optionType.parameter = 'DriverFilter';
        } else {
          console.error('only for vehicle or driver');
          return;
        }

        this.optionType = optionType;
        // this.optionsList = clickedQuery.TargetItems;
        const resp = await ApiService.getWarningDataOptionList(
          this.$axios,
          optionType.url
        );
        this.optionsList = resp.items;

        this.getOptions = clickedQuery.TargetItems[0];
        //*  above set the option like driver or vehicle

        this.getWarning = clickedQuery.WarningTypes;
        this.warningComboBoxKey += 1; // re render

        this.dateRange = {
          type: clickedQuery.ProfileType,
          date: moment(clickedQuery.Date, 'YYYY-MM-DD').toDate(),
        };
        this.dateRangeKey += 1; // re render

        this.submit(clickedQuery);
      } catch (e) {
        console.error(e);
      }
    },
    submit(_qry) {
      this.checkForm();
      if (this.valid) {
        let qry;
        console.log(_qry);
        if (_qry === undefined || _qry instanceof PointerEvent) {
          const query = {
            target: 'gfhisloc',
            optionType: this.optionType.value,
            targetTime: this.dateRange[0],
            timeRange: { start: this.dateRange[0], end: this.dateRange[1] },
            liveStatus: this.LiveCheckboxType, // ['online', 'offline', 'stopped'],
          };

          // if (this.cGeofencesList.length > 0)
          if (this.useGeofence) {
            query.fences = this.checkAndPickFencesFromGroup(
              this.selectedGeofences,
              this.geofences
            ).map((f) => f.id);
          }

          if (this.optionType.value !== 'company') {
            query.targetId = this.getOptions;
          }

          qry = query;
        } else {
          qry = _qry;
        }

        //* save this query only for warninglist
        this.query = qry;

        this.drawerListLoading = true;
        this.selectAllWays = false;
        this.wayList = [];
        this.noRecord = false;
        this.queryDisabled = true;

        const myNewInstance = this.$axios;
        const worker = new Worker('/gfhisloc.worker.js');
        worker.postMessage({
          baseURL: myNewInstance.defaults.baseURL,
          headers: myNewInstance.defaults.headers,
          qry,
        });
        const outsideGeofence = this.useGeofence;

        worker.onmessage = (e) => {
          const { payload, isLast } = e.data;

          const resultpoints = outsideGeofence
            ? payload.detectRecords
            : payload.outsideAllFences;

          if (resultpoints) {
            this.wayList.push(
              ...resultpoints.map((dr) => ({
                ...dr,
                wayId: `${dr.TripId}-${dr.t}`,
              }))
            );
          }

          if (isLast) {
            this.drawerListLoading = false;
            if (this.wayList?.length > 0) {
              this.noRecord = false;
              this.panel = -1;
            } else {
              this.noRecord = true;
            }
            this.queryDisabled = false;
          }
        };
        /*
        const response = await myNewInstance.$post(`/api/geo`, qry);

        this.drawerListLoading = false;
        const resultpoints = this.useGeofence
          ? response.detectRecords
          : response.outsideAllFences;
        this.wayList = resultpoints
          ? resultpoints.map((dr) => ({
              ...dr,
              wayId: `${dr.TripId}-${dr.t}`,
            }))
          : [];

        //! no need default select all
        // this.selectAllWays = true;

        // No record
        this.noRecord = true;
        if (this.wayList?.length > 0) {
          this.noRecord = false;
          this.panel = -1;
        }
        */
      }
    },
    checkForm() {
      this.valid = true;
      if (!this.optionType.value) {
        this.valid = false;
      }

      if (!this.dateRange || this.dateRange === '') {
        this.valid = false;
      }

      if (
        this.optionType.value !== 'company' &&
        (this.getOptions === null || this.getOptions.length === 0)
      ) {
        this.valid = false;
      }
      /*
      if (!this.dateRange.range[1]) {
        this.valid = false;
      }
      */
    },
  },
};
