
import moment from 'moment';
import ApiService from '@/services/settings-service.js';

export default {
  filters: {
    formatYear(date) {
      return moment(date).format('YYYY');
    },
  },
  props: {
    value: Boolean,
    updateValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 4),
      menu: false,
      isAdd: false,
      // 3333
      device: '',
      licensePlate: '',
      licensePlateItems: [],
      status: true,
      tboxProviderId: 2,
      hasBSD: false,
      hasDMS: false,
      hasADAS: false,
      hasSpeedSensor: false,
      hasIndoorBeacon: false,
      hasObdBox: false,
      hasRotationSensor: false,
      videoChannels: {},
      companyId: null,
      rules: {
        required: (value) => !!value || this.$t('Required'),
      },
      tBoxValues: [],
    };
  },
  computed: {
    Active() {
      return [
        { text: this.$t('Setting.Active'), value: true },
        { text: this.$t('Setting.Inactive'), value: false },
      ];
    },

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    async show(val) {
      this.$refs.form.resetValidation();
      this.device = '';
      this.licensePlate = '';
      this.status = true;
      this.tboxProviderId = 2;
      this.id = '';
      if (val) {
        // Fetch vehicleId
        ApiService.getVehicles(this.$axios).then((response) => {
          if (response) {
            this.licensePlateItems = response.items.map((a) => {
              return { text: a.plate, value: a.id };
            });
          }
        });
        if (Object.keys(this.updateValue).length === 0) {
          this.isAdd = true;
          this.hasBSD = false;
          this.hasDMS = false;
          this.hasADAS = false;
          this.hasSpeedSensor = false;
          this.hasIndoorBeacon = false;
          this.hasObdBox = false;
          this.hasRotationSensor = false;
          this.videoChannels = {
            'CH-1': false,
            'CH-2': false,
            'CH-3': false,
            'CH-4': false,
            'CH-5': false,
            'CH-6': false,
          };
        } else {
          this.companyId = this.updateValue.companyId;
          this.device = this.updateValue.serialNumber;
          this.licensePlate = this.updateValue.vehicleId;
          this.status = this.updateValue.isActive;
          this.tboxProviderId = this.updateValue.tboxProviderId;
          this.id = this.updateValue.id;
          this.hasBSD = this.updateValue.options.HasBSD;
          this.hasDMS = this.updateValue.options.HasDMS;
          this.hasADAS = this.updateValue.options.HasADAS;
          this.hasSpeedSensor = this.updateValue.options.HasSpeedSensor;
          this.hasIndoorBeacon = this.updateValue.options.HasIndoorBeacon;
          this.hasObdBox = this.updateValue.options.HasObdBox;
          this.hasRotationSensor = this.updateValue.options.HasRotationSensor;
          this.videoChannels = this.updateValue.videoChannels;
        }
        await this.getTBoxProvider();
      } else {
        this.isAdd = false;
        this.$emit('clearUpdateValue');
      }
    },
  },
  methods: {
    async getTBoxProvider() {
      const response = await ApiService.getTBoxProvider(this.$axios);
      this.tBoxValues = response.map((t) => ({ text: t.value, value: t.key }));
    },
    submit() {
      if (this.$refs.form.validate()) {
        const query = {
          serialNumber: this.device,
          vehicleId: this.licensePlate,
          tboxProviderId: this.tboxProviderId,
          isActive: this.status,
          options: {
            HasBSD: this.hasBSD,
            HasDMS: this.hasDMS,
            HasADAS: this.hasADAS,
            HasSpeedSensor: this.hasSpeedSensor,
            HasIndoorBeacon: this.hasIndoorBeacon,
            HasObdBox: this.hasObdBox,
            HasRotationSensor: this.hasRotationSensor,
          },
          videoChannels: this.videoChannels,
        };
        if (this.isAdd) {
          console.log('add');
          // console.log(query);
          ApiService.addDevice(this.$axios, query)
            .then((response) => {
              if (response.succeeded) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
          /*
          // Need to do a emit, reload and clear cache to make sure
          // the next axios call is updated. Only device behaves like this.
          // Otherwise, it will still fetch the old items in axios.
          this.$emit('newrecord', query);
          // eslint-disable-next-line
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));
          // eslint-disable-next-line
        const refreshPage = async () => {
            await delay(2000);
            // console.log('Waited 2 seconds');
            window.location = window.location.href + '?eraseCache=true';
          };
          refreshPage();
          */
        } else {
          console.log('update');
          // console.log(query);
          query.id = this.id;
          query.companyId = this.companyId;
          ApiService.updateDevice(this.$axios, query)
            .then((response) => {
              if (response.succeeded) {
                this.show = false;
              }
              // window.location = window.location.href + '?eraseCache=true';
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        // this.$emit('forceRerenderOnSubmit');
      }
    },
  },
};
