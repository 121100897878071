
// import { PublicClientApplication } from '@azure/msal-browser';

export default {
  name: 'Login',
  // data() {
  //   return {
  //     account: undefined,
  //   };
  // },
  // mounted() {
  //   if (!this.$CheckLogin()) {
  //     this.login();
  //   }
  //   //   this.$router.push('/Callback');
  //   // } else {
  //   //
  //   // }
  // },
  // method: {
  //   login() {
  //     this.$msal.signIn();
  //   },
  // },
};
