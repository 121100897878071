
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { backgroundColor } from '@/plugins/config';
import ApiService from '@/services/api-service.js';

export default {
  name: 'ExpansionPanel',
  props: {
    query: {
      type: Object,
      default: () => {},
    },
    recommendations: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dangerousVideoItem: [],
      recommendflag: false,
      recommendmsg: {},
      backgroundColor,
      search: '',
      downloadList: [],

      videoDialog: false,
      media: '',
      showBehavior: false,

      tablePanelShowLoading: false,
    };
  },
  computed: {
    // * showIdling
    showIdling: {
      get() {
        console.log('show idling', this.$store.state.app.showIdling);
        if (this.$store.state.app.company) {
          return this.$store.state.app.showIdling;
        }
        return false;
      },
    },
    messages() {
      const tempmessages = [];
      if (this.recommendmsg !== null) {
        for (const key of Object.keys(this.recommendmsg)) {
          tempmessages.push({ name: key, value: this.recommendmsg[key] });
        }
      }
      return tempmessages;
    },
    dangerousVideoheaders() {
      return [
        { text: this.$t('Video'), value: 'video' },
        { text: this.$t('Location'), value: 'location' },
        // { text: this.$t('Latitude'), value: 'lat' },
        // { text: this.$t('Longitude'), value: 'lng' },
        { text: this.$t('warningType'), value: 'warningType' },
        { text: this.$t('startTime'), value: 'startTime' },
        { text: this.$t('vehicle'), value: 'vehicle' },
        { text: this.$t('vehicleDept'), value: 'vehicleDept' },
        { text: this.$t('driver'), value: 'driver' },
        { text: this.$t('driverDept'), value: 'driverDept' },
        { text: this.$t('RateOfChange'), value: 'rateOfChange' },
        { text: this.$t('duration'), value: 'duration' },
        { text: this.$t('startSpeed'), value: 'startSpeed' },
        { text: this.$t('endSpeed'), value: 'endSpeed' },
        { text: this.$t('topSpeed'), value: 'topSpeed' },
        { text: this.$t('hw'), value: 'hw' },
        { text: this.$t('minHW'), value: 'nearestHw' },
        { text: this.$t('driverAction'), value: 'driverAction' },
      ];
    },
  },
  watch: {
    query(val) {
      //   // OnChangeQuery
      this.getProfileWarningList(val);
    },
    // recommendations(val) {
    //   console.log(val);
    // },
    '$store.state.app.company'(val) {
      this.checkShowBehavior();
    },
  },
  mounted() {
    this.checkShowBehavior();
    if (this.showBehavior) {
      this.getProfileWarningList(this.query);
    }
  },
  methods: {
    checkShowBehavior() {
      this.showBehavior = false;
      if (this.$store.state.app.collision) {
        this.showBehavior = true;
      }
    },
    reload(val) {
      this.getProfileWarningList(this.query);
    },
    playVideo(value) {
      this.media = value;
      this.videoDialog = true;
    },
    source(item) {
      // Reset src path and center string
      this.src = 'https://maps.googleapis.com/maps/api/staticmap?center=';
      this.location = '';
      this.marker = '&markers=';

      // Pop Up Window
      this.imgDisplay = true;

      // Locate Map (Static Google Map API)
      if (item.lat && item.lng !== null) {
        this.lat = item.lat;
        this.lng = item.lng;
        this.location = this.location.concat(this.lat, ',', this.lng);
        this.marker = this.marker.concat(this.location);
        this.src = this.src.concat(this.location, this.marker, this.params);
      }
    },
    getImage(itemVal) {
      if (itemVal === false) return false;
      else return true;
    },
    getProfileWarningList(query) {
      const newQuery = {
        TargetType: query.TargetType,
        TargetId: query.TargetId,
        Date: query.Date,
        ProfileType: query.ProfileType,
        OutputType: query.OutputType,
        Distance: query.Distance,
        IsDanger: true,
      };
      this.dangerousVideoItem = [];
      //* first time, refs is always empty, subsequent is ok
      //* first time, this is called before panel is expanded,
      //* so no need show loading, because can't see anyways
      //* and tablepanel showloading should be default to false
      /*
      const panel = this.$refs['profileTablePanel-dangerous'];
      if (panel !== null && panel !== undefined) {
        if (Array.isArray(panel)) {
          panel[0].setDataTableShowLoading(false);
        } else {
          panel.setDataTableShowLoading(true);
        }
      } else {
        console.log('ProfileTablePanel is undefined');
      }
      */
      this.tablePanelShowLoading = true;
      ApiService.getProfileWarningList(this.$axios, newQuery)
        .then((response) => {
          response.items.forEach((p) => {
            if (p.mediaFullId) {
              this.dangerousVideoItem.push(this.createObj(p));
            }
          });
          this.downloadList = [];
          this.dangerousVideoItem.forEach((item, i) => {
            this.downloadList.push({});
            this.downloadList[i][this.$t('Video')] = item.video
              ? item.video
              : '';
            this.downloadList[i][this.$t('Latitude')] = item.lat;
            this.downloadList[i][this.$t('Longitude')] = item.lng;
            this.downloadList[i][this.$t('warningType')] = this.$t(
              item.warningType
            );
            this.downloadList[i][this.$t('startTime')] = item.startTime
              ? moment(item.startTime).format('YYYY-MM-DD HH:mm:ss')
              : '';
            this.downloadList[i][this.$t('vehicle')] = item.vehicle;
            this.downloadList[i][this.$t('vehicleDept')] = item.vehicleDept;
            this.downloadList[i][this.$t('driver')] = item.driver
              ? item.driver
              : '';
            this.downloadList[i][this.$t('driverDept')] = item.driverDept
              ? item.driverDept
              : '';
            this.downloadList[i][this.$t('RateOfChange')] = item.rateOfChange
              ? item.rateOfChange
              : '';
            this.downloadList[i][this.$t('duration')] =
              item.duration != null ? item.duration : '';
            this.downloadList[i][this.$t('startSpeed')] =
              item.startSpeed != null ? item.startSpeed : '';
            this.downloadList[i][this.$t('endSpeed')] =
              item.endSpeed != null ? item.endSpeed : '';
            this.downloadList[i][this.$t('topSpeed')] =
              item.topSpeed != null ? item.topSpeed : '';
            this.downloadList[i][this.$t('hw')] =
              item.hw != null ? item.hw : '';
            this.downloadList[i][this.$t('minHW')] =
              item.nearestHw != null ? item.nearestHw : '';
            this.downloadList[i][this.$t('driverAction')] =
              this.setDriverAction(item);
          });
        })
        .catch((error) => {
          console.log(
            'ERROR: Profile Warning List ' + JSON.stringify(error.response)
          );
        })
        .finally(() => {
          this.tablePanelShowLoading = false;
        });
    },
    createObj(Obj) {
      return {
        video: Obj.mediaFullId,
        lat: Obj.lat,
        lng: Obj.lng,
        warningType: this.$t(Obj.warningTypeText),
        startTime: Obj.startTime
          ? moment(Obj.startTime).format('YYYY-MM-DD HH:mm:ss')
          : '',
        vehicle: Obj.vehiclePlate,
        vehicleDept: Obj.vehicleDepartment,
        driver: Obj.driverName,
        driverDept: Obj.driverDepartment,
        // rateOfChange: this.getRateOfChange(
        //   Obj.startSpeed,
        //   Obj.endSpeed,
        //   Obj.duration
        // ).toFixed(3),
        rateOfChange: Obj.rateOfChange,
        duration: Obj.duration,
        startSpeed: Obj.startSpeed,
        endSpeed: Obj.endSpeed,
        topSpeed: Obj.topSpeed,
        hw: Obj.hw,
        nearestHw: Obj.minHW,
        driverActionl: Obj.leftSignalOn,
        driverActionr: Obj.rightSignalOn,
        driverActionb: Obj.brakeOn,
      };
    },
    getRateOfChange(startSpeed, endSpeed, duration) {
      return (startSpeed - endSpeed) / duration;
    },
    checkMsgValue(obj) {
      return obj != null;
    },
    setDriverAction(item) {
      let driverAction = '';
      // if (item.brakeOn) driverAction += 'B';
      if (item.driverActionb) driverAction += 'B';
      // if (item.leftSignalOn) driverAction += 'L';
      if (item.driverActionl) driverAction += 'L';
      // if (item.rightSignalOn) driverAction += 'R';
      if (item.driverActionr) driverAction += 'B';
      return driverAction;
    },
  },
};
