
export default {
  name: 'DrawerList',
  props: {
    idForStyle: {
      type: String,
      default() {
        return '';
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedColor: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      perPage: 10,
      listItems: this.value,
      itemStyle: {},
    };
  },
  computed: {
    numbOfPage: {
      get() {
        return Math.ceil(this.listItems.length / this.perPage);
      },
    },
    listItemsPaged: {
      get() {
        return this.listItems.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
      },
    },
  },
  watch: {
    value(val) {
      this.listItems = val;
    },
    selectedColor(val) {
      this.itemStyle = {};
      for (const v of val) {
        this.itemStyle[v.itemId] = {
          color: 'white',
          backgroundColor: v.color,
        };
      }
      //! need this to re render with style if itemStyle is not set
      //! Object does not cause render if setting keys
      // this.listItems = [...this.listItems];
    },
  },

  methods: {
    onItemClick(item, index) {
      const indexOfTotal = (this.currentPage - 1) * this.perPage + index;
      this.$emit('itemClick', { item, index, indexOfTotal });
    },
  },
};
