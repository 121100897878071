
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';
import moment from 'moment';

export default {
  name: 'Vehicle',
  components: {
    // downloadExcel: () => {
    //   if (process.client) {
    //     return import('vue-json-excel');
    //   }
    // },
    DriverGroupDetailDialog: () => {
      if (process.client) {
        return import('@/components/dialog/DriverGroupDetailDialog');
      }
    },
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  data: () => ({
    // 搜尋框輸入值
    Search: '',
    // datatable 的欄寬平均
    calculateWidths: true,
    downloadList: [],
    ItemsList: [],
    // 新增功能Dialog
    addDialog: false,
    updateValue: {},
    displayPrompt: false,
    displayDelete: false,
    // Component key
    componentKey: 0,
    // Before delete items
    beforeDelete: false,
    itemsToBeDeleted: {},
    showMsg: false,
    msg: '',
  }),
  computed: {
    Headers() {
      return [
        // { text: this.$t('Setting.driverGroupID'), value: 'id' },
        { text: this.$t('Setting.groupName'), value: 'name' },
        { text: this.$t('Setting.groupDescription'), value: 'description' },
        { text: this.$t('Setting.department'), value: 'companyGroup' },
        {
          text: this.$t('Setting.driversBelongToGroup'),
          value: 'driverCount',
        },
        { text: this.$t('Setting.lastUpdated'), value: 'updatedAt' },
        {
          text: this.$t('Setting.crudActions'),
          value: 'crudActions',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    displayPrompt() {
      setTimeout(() => (this.displayPrompt = false), 3000);
    },
    displayDelete() {
      setTimeout(() => (this.displayDelete = false), 3000);
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setDownloadList();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.getDriverGroups();
      // this.setDownloadList();
    },
  },
  mounted() {
    this.getDriverGroups();
  },
  methods: {
    getDriverGroups() {
      this.wrapShowloading(
        ApiService.getDriverGroups(this.$axios)
          .then((response) => {
            // console.log(response.items);
            if (response) {
              this.ItemsList = response.items;
              this.setDownloadList();
            }
          })
          .catch({})
      );
    },
    setDownloadList() {
      if (this.ItemsList.length > 0) {
        this.downloadList = [];
        let rowIndex = 0;
        this.ItemsList.forEach((item) => {
          if (item.driverCodes.length > 0) {
            item.driverCodes.forEach((driver) => {
              this.downloadList.push({});
              this.downloadList[rowIndex][this.$t('Setting.groupName')] =
                item.name;
              this.downloadList[rowIndex][this.$t('Setting.groupDescription')] =
                item.description;
              this.downloadList[rowIndex][this.$t('Setting.department')] =
                item.companyGroup;
              this.downloadList[rowIndex][this.$t('Setting.driverCode')] =
                driver;
              this.downloadList[rowIndex][this.$t('Setting.lastUpdated')] =
                moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
                ' (' +
                item.updatedBy +
                ')';
              rowIndex = rowIndex + 1;
            });
          } else {
            this.downloadList.push({});
            this.downloadList[rowIndex][this.$t('Setting.groupName')] =
              item.name;
            this.downloadList[rowIndex][this.$t('Setting.groupDescription')] =
              item.description;
            this.downloadList[rowIndex][this.$t('Setting.department')] =
              item.companyGroup;
            this.downloadList[rowIndex][this.$t('Setting.driverCode')] = '';
            this.downloadList[rowIndex][this.$t('Setting.lastUpdated')] =
              moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
              ' (' +
              item.updatedBy +
              ')';
            rowIndex = rowIndex + 1;
          }
        });
      }
    },
    updateData(val) {
      this.updateValue = val;
      this.addDialog = true;
    },
    beforeDeleteData(val) {
      console.log(val);
      this.beforeDelete = true;
      this.itemsToBeDeleted = val;
    },
    deleteData(val) {
      this.beforeDelete = false;

      // If vehicleGroup have no vehicles, delete. Otherwise, pop a screen to
      // tell them to remove all vehicles first.
      if (val.driverCount !== 0) {
        // Display Dialog
        this.displayPrompt = true;
        return;
      }
      // Delete the records in Vuetify datatable and refresh.
      this.ItemsList = this.ItemsList.filter((item) => item.id !== val.id);
      this.forceRerender();
      this.displayDelete = true;

      ApiService.deleteDriverGroup(this.$axios, val.id);
    },
    openFileExplorer() {
      this.$refs.fileInputImport.click();
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      ApiService.postUploadDriverGroup(this.$axios, formData).then(
        (response) => {
          if (!response.success) {
            this.msg = response.message;
            this.resColor = 'red';
          } else {
            this.msg = this.$t('UploadSuccess');
            this.resColor = 'green';
          }
          this.showMsg = true;
        }
      );
      this.$refs.fileInputImport.value = '';
    },
    // downloadCSV() {
    //   // eslint-disable-next-line
    //   let csvcontent = JSON.parse(JSON.stringify(this.ItemsList));

    //   // Acquire headers
    //   const csvheaders = this.Headers.map((obj) => obj.value);

    //   // Special add
    //   csvheaders.push('updatedBy', 'updatedAt', 'driverCount');

    //   // Format companyList as same as display
    //   csvcontent.forEach((item) => {
    //     Object.entries(item).forEach(([key]) => {
    //       // If none of the key in csvcontent satisfies the headers, remove them.
    //       if (!csvheaders.includes(key)) delete item[key];
    //     });
    //   });
    //   return csvcontent;
    // },
    addData() {
      this.addDialog = true;
    },
    clearUpdateValue() {
      this.updateValue = {};
    },
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerenderOnSubmit(val) {
      this.componentKey += 1;
      this.ItemsList = [];
      this.getDriverGroups();
      this.showMsg = true;
      this.msg = val.message;
    },
  },
};
