
export default {
  name: 'TDRoutesOnMap',
  props: {
    // google map vue component
    mapVueComponent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tdRoutes: [],
      tdButtonLabel: 'TD',
      tdBtnDisable: false,
      // for map
      mouseOverTDHandler: null,
      tdInfowindows: [],
    };
  },
  watch: {
    tdRoutes(val) {
      const _map = this.mapVueComponent.map;
      if (val.length === 0) {
        _map.data.forEach((f) => {
          _map.data.remove(f);
        });
        this.mouseOverTDHandler.remove();
        for (const iw of this.tdInfowindows) {
          iw.close();
        }
      } else {
        console.log(val.length);
        console.log(val[0]);

        this.tdInfowindows = [];
        for (const r of val) {
          // .slice(0, 100)) {
          _map.data.addGeoJson({ type: 'Feature', ...r });
        }
        _map.data.setStyle({
          strokeColor: 'green',
        });
        this.mouseOverTDHandler = _map.data.addListener(
          'click',
          this.showInfoWindOnTD
        );
      }
    },
  },
  methods: {
    // for map
    showInfoWindOnTD(e) {
      const feat = e.feature;
      let html =
        '<b>' +
        feat.getProperty('name') +
        '</b><br>' +
        feat.getProperty('ROAD_ROUTE_ID');
      html += '-' + feat.getProperty('SPEED_LIMIT_ID');

      const infowindow = new window.google.maps.InfoWindow();
      infowindow.setContent(html);
      infowindow.setPosition(e.latLng);
      infowindow.setOptions({
        pixelOffset: new window.google.maps.Size(0, -4),
      });
      infowindow.open(this.mapVueComponent.map);
      this.tdInfowindows.push(infowindow);
    },
    async showTD() {
      if (this.tdRoutes.length === 0) {
        const myNewInstance = this.$axios.create({
          baseURL: 'https://spw-valhalla.azurewebsites.net',
          timeout: 30000,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json, */*',
          },
        });
        this.tdButtonLabel = this.$t('loadingPleaseWait');
        this.tdBtnDisable = true;
        const resp = await myNewInstance.$get('/api/td');
        this.tdRoutes = resp.data;
        this.tdButtonLabel = this.$t('clear_all') + ' TD';
        this.tdBtnDisable = false;
      } else {
        this.tdButtonLabel = 'TD';
        this.tdRoutes = [];
      }
    },
  },
};
