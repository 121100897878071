
/* eslint-disable @typescript-eslint/no-unused-vars */
// import map from '@/components/GoogleMap';
import { mapMutations } from 'vuex';
import moment from 'moment';
// import VideoDialog from '@/components/dialog/VideoDialog';
import ApiService from '@/services/api-service.js';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'WarningHeatMap',
  components: {
    // VideoDialog,
    Gmap: () => {
      if (process.client) {
        return import('@/components/HeatMap');
      }
    },
    Treeselect: () => {
      if (process.client) {
        return import('@riophae/vue-treeselect');
      }
    },
    VideoDialog: () => {
      if (process.client) {
        return import('@/components/dialog/VideoDialog');
      }
    },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    formatDateYYMM(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    formatTime(date) {
      return moment(date).format('HH:mm:ss');
    },
  },
  props: {
    // google map api 初始值
    center: {
      type: Object,
      default: () => ({ lat: 22.3186, lng: 114.1796 }),
    },
  },
  data: () => ({
    banner: false,
    tripDrawer: true,
    getWarning: ['FCW'],
    warningList: [],
    optionType: [],
    optionsets: [],
    getOptions: [],
    optionsList: [],
    dates: [
      new Date(new Date().setDate(new Date().getDate() - 31)),
      new Date(new Date().setDate(new Date().getDate() - 1)),
    ],
    normalizer(node) {
      if (node.children == null || node.children.length === 0) {
        delete node.children;
      }
      return {
        id: node.name,
        label: node.name,
        children: node.children,
      };
    },
    noRecord: false,
    getheatList: [],
    videoDialog: false,
    media: '',
  }),
  computed: {
    likesAllItems() {
      return this.getOptions.length === this.optionsList.length;
    },
    likesSomeItems() {
      return this.getOptions.length > 0 && !this.likesAllItems;
    },
    likesAllWarnings() {
      return this.getWarning.length === this.warningList.length;
    },
    likesSomeWarnings() {
      return this.getWarning.length > 0 && !this.likesAllWarnings;
    },
    icon() {
      if (this.likesAllItems) return 'mdi-close-box';
      if (this.likesAllWarnings) return 'mdi-close-box';
      if (this.likesSomeItems) return 'mdi-minus-box';
      if (this.likesSomeWarnings) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    optionType(val) {
      this.optionsList = [];
      this.getOptions = [];
      // if (
      //   val.value === 'driverdepartment' ||
      //   val.value === 'vehicledepartment'
      // ) {
      //   this.getOptions = null;
      // } else {
      //   this.getOptions = [];
      // }
    },
    '$store.state.app.company'(val) {
      this.optionType = [];
      this.setOptions();
    },
  },
  mounted() {
    this.setOptions();
  },
  methods: {
    ...mapMutations('app', ['getCluster']),
    getLang(item) {
      return this.$t(item.text);
    },
    onChange() {
      if (this.optionType.value !== 'company') {
        this.getOptionList(this.optionType.url);
      }
    },
    getWarningList() {
      ApiService.getWarningDataOptionList(
        this.$axios,
        '/api/warningData/GetWarningTypeList'
      ).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            this.warningList.push({
              text: this.$t(item),
              value: item,
            });
          });
        }
      });
    },
    getOptionList(url) {
      ApiService.getWarningDataOptionList(this.$axios, url).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            this.optionsList.push(item);
          });
        }
      });
    },
    disabledBefore(date) {
      return this.warningDatePickerDisableDate(date);
    },
    disabledAfter() {
      return new Date();
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.likesAllItems) {
          this.getOptions = [];
        } else {
          this.getOptions = this.optionsList.slice();
        }
      });
    },
    selectAllWarning() {
      this.$nextTick(() => {
        if (this.likesAllWarnings) {
          this.getWarning = [];
        } else {
          this.getWarning = this.warningList.slice();
        }
      });
    },

    setOptions() {
      // Get warning options
      this.getWarningList();

      this.optionsets = [];
      this.optionsets.push({
        text: 'Company',
        value: 'company',
        type: 'company',
        url: '',
        active: true,
        disabled: false,
      });
      ApiService.getWarningDataOptions(this.$axios).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            switch (item.name) {
              case 'Driver':
              case 'Driver Group':
              case 'Driver Department':
              case 'Vehicle':
              case 'Vehicle Group':
              case 'Vehicle Department':
                this.optionsets.push({
                  text: item.name,
                  value: item.value,
                  type: item.type,
                  url: item.url,
                  active: true,
                  disabled: false,
                });
                break;
              default:
                break;
            }
          });
          this.optionType = this.optionsets[0];
          // setTimeout(() => {
          this.submit();
          // }, 500);
        }
      });
    },
    submit() {
      this.checkForm();
      if (this.valid) {
        let warningTypes = this.getWarning;
        if (typeof warningTypes[0] === 'object') {
          warningTypes = warningTypes.map((o) => o.value);
        }
        const query = {
          TargetType: this.optionType.value,
          StartTime: moment(this.dates[0]).format('YYYY-MM-DD'),
          EndTime: moment(this.dates[1]).format('YYYY-MM-DD'),
          WarningType: warningTypes,
        };
        const list = [];
        this.getOptions.forEach((item) => {
          list.push(item);
        });
        query.TargetItem = list;
        this.getheatList = [];
        ApiService.getHeatMap(this.$axios, query).then((response) => {
          this.getheatList = response.items;
          // this.next();
          // this.clearAll();
          // No record
          this.noRecord = false;
          if (this.getheatList.length === 0) {
            this.noRecord = true;
          } else {
            this.getCluster(this.getheatList);
          }
        });
      }
    },
    checkForm() {
      this.valid = true;
      if (!this.optionType.value) {
        this.valid = false;
      }
      if (!this.dates[0]) {
        this.valid = false;
      }
      if (!this.dates[1]) {
        this.valid = false;
      }
    },
    playVideo(value) {
      this.media = value;
      this.videoDialog = true;
    },
  },
};
