
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';
import moment from 'moment';

export default {
  name: 'CompanyGroup',
  components: {
    TreeTable: () => {
      if (process.client) {
        return import('vue-table-with-tree-grid');
      }
    },
    CompanyGroupDialog: () => {
      if (process.client) {
        return import('@/components/dialog/CompanyGroupDialog.vue');
      }
    },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
    },
  },
  data: () => ({
    fakeLoading: '',
    UpdateAuth: false,
    Search: '',
    companyGroupList: [],
    editDialog: false,
    record: {},
    beforeDelete: false,
    itemsToBeDeleted: {},
    showMsg: false,
    msg: '',
  }),
  computed: {
    Headers() {
      return [
        { label: this.$t('Setting.orgName'), prop: 'name' },
        { label: this.$t('Setting.description'), prop: 'description' },
        {
          label: this.$t('Setting.createAt'),
          type: 'template',
          prop: 'createAt',
          template: 'createAt',
        },
        {
          label: this.$t('Setting.crudActions'),
          type: 'template',
          template: 'crudActions',
        },
      ];
    },
  },
  watch: {
    '$store.state.app.userRole'(val) {
      this.checkAuth();
    },
    '$store.state.app.company'(val) {
      this.getCompanyGroup();
    },
  },
  mounted() {
    this.checkAuth();
    this.getCompanyGroup();
  },
  methods: {
    checkAuth() {
      this.UpdateAuth = false;
      const val = this.$store.state.app.userRole;
      if (
        val === 'HQ' ||
        val === 'Administrator' ||
        this.$store.state.app.isAdmin
      ) {
        this.UpdateAuth = true;
      }
    },
    getCompanyGroup() {
      this.companyGroupList = [];
      this.fakeLoading = this.$t('loadingPleaseWait');
      ApiService.getCompanyGroupsTree(this.$axios)
        .then((response) => {
          if (response.items) {
            response.items.forEach((item) => {
              this.companyGroupList.push({
                name: item.name,
                description: item.description,
                createAt: item.createAt,
                children: item.children ? item.children : null,
              });
            });
          }
        })
        .finally(() => {
          this.fakeLoading = this.$t('NoData');
        });
    },
    addData() {
      this.editDialog = true;
    },
    updateData(item) {
      // console.log(item);
      this.record = item;
      this.editDialog = true;
    },
    // initial value
    clearUpdateValue() {
      this.record = {};
    },
    beforeDeleteData(val) {
      this.beforeDelete = true;
      this.itemsToBeDeleted = val;
    },
    deleteData(val) {
      this.beforeDelete = false;
      ApiService.deleteCompanyGroup(this.$axios, val.id)
        .then((response) => {
          // if (response.success) {
          //   this.show = false;
          // }
          // this.$emit('forceRerenderOnSubmit', response);
          this.forceRerenderOnSubmit(response);
        })
        .catch((error) => {
          console.log(error);
        });
      // setTimeout(() => {
      //   this.getCompanyGroup();
      // }, 500);
    },
    forceRerenderOnSubmit(val) {
      this.msg = val.message;
      this.showMsg = true;
      this.getCompanyGroup();
    },
  },
};
