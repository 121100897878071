import { render, staticRenderFns } from "./LiveInformation.vue?vue&type=template&id=4c29dd54&scoped=true"
import script from "./LiveInformation.vue?vue&type=script&lang=js"
export * from "./LiveInformation.vue?vue&type=script&lang=js"
import style0 from "./LiveInformation.vue?vue&type=style&index=0&id=4c29dd54&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c29dd54",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Jessibuca: require('/home/vsts/work/1/s/components/Jessibuca.vue').default})
