import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=25a98ba6&scoped=true"
import script from "./Profile.vue?vue&type=script&lang=js"
export * from "./Profile.vue?vue&type=script&lang=js"
import style0 from "./Profile.vue?vue&type=style&index=0&id=25a98ba6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a98ba6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileQueryBox: require('/home/vsts/work/1/s/components/profile/QueryBox.vue').default,GlobalChart: require('/home/vsts/work/1/s/components/global/Chart.vue').default,ProfilePopup: require('/home/vsts/work/1/s/components/profile/Popup.vue').default,DialogCompareDialog: require('/home/vsts/work/1/s/components/dialog/CompareDialog.vue').default,ProfileChartsPanel: require('/home/vsts/work/1/s/components/profile/ChartsPanel.vue').default,ProfileExpansionPanel: require('/home/vsts/work/1/s/components/profile/ExpansionPanel.vue').default})
