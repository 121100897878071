
import moment from 'moment';
import ApiService from '@/services/settings-service.js';
import { roleMap } from '@/plugins/config';

export default {
  filters: {
    formatYear(date) {
      return moment(date).format('YYYY');
    },
  },
  props: {
    value: Boolean,
    updateValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      roleMap,
      date: null,
      menu: false,
      isAdd: false,
      description: '',
      roleName: '',
      // isAdmin: false,
      isAdmin: 0,
      functions: [],
      functionList: [],
      showMsg: false,
      msg: '',
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    show(val) {
      this.roleName = '';
      this.description = '';
      this.isAdmin = 0;
      this.functions = [];
      if (val) {
        if (Object.keys(this.updateValue).length === 0) {
          this.isAdd = true;
          // this.isAdmin = false;
          this.isAdmin = 0;
        } else {
          this.roleName = this.updateValue.roleName;
          this.description = this.updateValue.description;
          // this.isAdmin = this.updateValue.isAdmin;
          this.isAdmin = this.updateValue.role;
          this.functions = this.updateValue.roleFunctions;
        }
      } else {
        this.isAdd = false;
        this.$emit('clearUpdateValue');
      }
    },
    filterInput(value) {
      this.departmentOption = this.checkDepartmentOption;
      this.departmentOption = this.departmentOption.filter((post) => {
        return post.text.toLowerCase().trim().includes(value.toLowerCase());
      });
    },
  },
  mounted() {
    this.getRoleFunctionList();
    // this.getUserDropdownList();
  },
  methods: {
    // save(date) {
    //   this.$refs.menu.save(date);
    // },
    getRoleFunctionList() {
      ApiService.getRoleFunctions(this.$axios)
        .then((response) => {
          response.forEach((item) => {
            this.functionList.push(item.functionName);
          });
        })
        .catch({});
    },
    submit() {
      const query = {
        roleName: this.roleName,
        description: this.description,
        // isAdmin: this.isAdmin,
        role: this.isAdmin,
        rolefunctions: this.functions,
      };
      if (this.isAdd) {
        ApiService.addRole(this.$axios, query)
          .then((response) => {
            if (response.success) {
              this.show = false;
            }
            this.$emit('forceRerenderOnSubmit', response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        query.id = this.updateValue.id;
        ApiService.updateRole(this.$axios, query)
          .then((response) => {
            if (response.success) {
              this.show = false;
            }
            this.$emit('forceRerenderOnSubmit', response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
