
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/api-service.js';
import moment from 'moment';
import {
  Background,
  // guageColor,
  // gradeColor,
  doughnutColor,
  safetyScoreColor,
  backgroundColor,
} from '@/plugins/config';

export default {
  data() {
    return {
      parentValue: 0,
      query: {
        TargetType: 'company',
        Date: new Date().toISOString().substr(0, 10),
        ProfileType: 'day',
      },
      safetyQuery: {},
      plateItem: [],
      vehicleGrpItem: [],
      nameItem: [],
      driverGrpItem: [],
      qryData: {},
      warningGroup: [],
      overviewHeight: null,
      overviewChartType: 'polarArea',
      overviewLabels: [],
      overviewDatasets: [
        {
          label: 'OverviewDatasets',
          backgroundColor: Background,
          data: null,
        },
      ],
      overviewOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'start',
            align: 'end',
            offset: 120,
            formatter(value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
          },
        },
        // legend: {
        //   display: false,
        // },
        scale: {
          // yAxes: [
          //   {
          //     display: false,
          //   },
          // ],
          angleLines: {
            display: true,
          },
          pointLabels: {
            display: false,
          },
        },
      },
      // grade
      gradeChartType: 'doughnut',
      gradeLabels: [],
      gradeDatasets: [
        {
          label: 'GradeDatasets',
          backgroundColor: doughnutColor,
          data: null,
        },
      ],
      gradeOptions: {
        // rotation: 1 * Math.PI,
        // circumference: 1 * Math.PI,
        // cutoutPercentage: 67,
        maintainAspectRatio: false,
        responsive: true,
        // cutoutPercentage: 85,
        cutout: '85%',
        // legend: { display: false },
        // tooltips: { enabled: false },
        plugins: {
          legend: { display: false },
          tooltips: { enabled: false },
          datalabels: {
            display: false,
          },
        },
      },
      grade: '',
      totalScore: '',
      // safety score
      safetyScoreType: 'line',
      safetyScoreLabels: [],
      safetyScoreDatasets: [
        {
          label: 'Safety Score',
          backgroundColor: safetyScoreColor,
          data: null,
        },
      ],
      safetyScoreOptions: {
        // maintainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
          },
        },
      },
      // collision
      collisionType: 'line',
      collisionLabels: [],
      collisionDatasets: [],
      collisionOptions: {
        // maintainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
      // bad
      badType: 'line',
      badLabels: [],
      badDatasets: [],
      badOptions: {
        // maintainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
      // comfort
      comfortType: 'line',
      comfortLabels: [],
      comfortDatasets: [],
      comfortOptions: {
        // maintainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
      downloadPath: '',
    };
  },
  watch: {
    // '$vuetify.breakpoint.name'(val) {
    //   this.overviewHeight = this.getChartHeight(val);
    // },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.getOverview();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.getOverview();
    },
  },
  // mounted() {
  //   // warning list
  //   // this.overviewHeight = this.getChartHeight();
  //   // this.getWarningGroup(this.query);
  // },
  methods: {
    // sync GlobalQueryBox
    getQuery(form) {
      this.qryData = form;
      this.time = form.time;
      this.query = {
        TargetType: this.query.TargetType,
        TargetId: this.query.TargetId,
        Date: moment(this.time).format('YYYY-MM-DD'),
        ProfileType: form.queryType,
        OutputType: this.query.TargetType,
        Distance: form.km,
      };

      this.getOverview();
    },

    getOverview() {
      // Overview
      ApiService.getProfileOverview(this.$axios, this.query).then(
        (response) => {
          if (response) {
            this.grade = response.grade;
            this.totalScore = response.totalScore;
            // this.guageDatasets[0].data = [35, 15, 15, 15, 20];
            // this.guageLabels = ['0-35', '>35', '>50', '>65', '>80'];
            this.gradeLabels = ['', ''];
            let color = '';
            if (response.totalScore > 60) {
              color = 'green';
            } else if (response.totalScore > 20) {
              color = 'yellow';
            } else {
              color = 'red';
            }
            this.gradeDatasets[0].backgroundColor = [color, '#bfbfbf'];
            // this.gradeDatasets[0].data = [
            //   response.totalScore - 0.75,
            //   1.5,
            //   99.25 - response.totalScore,
            // ];
            this.gradeDatasets[0].data = [
              response.totalScore,
              100 - response.totalScore,
            ];

            const scoreLabels = [];
            if (response.scoreLabels) {
              response.scoreLabels.forEach((k) => {
                scoreLabels.push(this.$t(k));
              });
            }

            this.overviewDatasets[0].data = response.scoreData;
            this.overviewLabels = scoreLabels;
          }
        }
      );

      // SafetyScore
      this.safetyQuery = this.query;
      this.safetyQuery.PageSize = 12;
      this.safetyQuery.Page = 1;
      ApiService.getSafetyScore(this.$axios, this.safetyQuery).then(
        (response) => {
          if (response) {
            const ChartObj = [];
            const DateObj = [];
            let word = '';
            if (response.items) {
              response.items.forEach((p) => {
                ChartObj.push(p.totalScore);
                word = this.formatDate(p.accDate);
                DateObj.push(word);
              });
            }

            // Reverse array so that it starts from the oldest time
            ChartObj.reverse();
            DateObj.reverse();

            this.safetyScoreLabels = DateObj;
            this.safetyScoreDatasets[0].data = ChartObj;
            this.safetyScoreDatasets[0].label = this.$t('SafetyScore');
          }
        }
      );

      this.getWarningGroup(this.query);
    },
    // Warning Group
    getWarningGroup(query) {
      ApiService.getWarningGroup(this.$axios, query)
        .then((response) => {
          if (response) {
            this.warningGroup = [
              response.dangerous,
              response.bad,
              response.comfort,
            ];

            this.getWarningCountper100();
          }
        })
        .catch((error) => {
          console.log(
            'ERROR: Profile Warning Group ' + JSON.stringify(error.response)
          );
        });
    },

    // WarningCountper100
    getWarningCountper100() {
      this.collisionDatasets = [];
      this.badDatasets = [];
      this.comfortDatasets = [];
      ApiService.getWarningCountper100(this.$axios, this.safetyQuery).then(
        (response) => {
          if (response.items) {
            const collisionLabel = this.warningGroup[0];
            const badLabel = this.warningGroup[1];
            const comfortLabel = this.warningGroup[2];
            const collisionList = [];
            const badList = [];
            const comfortList = [];
            const DateObj = [];
            let word = '';

            if (collisionLabel) {
              collisionLabel.forEach((k, i) => {
                collisionList.push({
                  label: k,
                  backgroundColor: Background[i],
                  fill: true,
                  data: [],
                });
              });
            }
            // Set Dataset
            if (badLabel) {
              badLabel.forEach((k, i) => {
                badList.push({
                  label: k,
                  backgroundColor: Background[i + 5],
                  fill: true,
                  data: [],
                });
              });
            }
            if (comfortLabel) {
              comfortLabel.forEach((k, i) => {
                comfortList.push({
                  label: k,
                  backgroundColor: backgroundColor[i],
                  fill: true,
                  data: [],
                });
              });
            }

            response.items.forEach((p) => {
              Object.keys(p.results).forEach(function (k, v) {
                collisionList.forEach((item) => {
                  if (item.label === k) {
                    item.data.push(p.results[k]);
                  }
                });
                badList.forEach((item) => {
                  if (item.label === k) {
                    item.data.push(p.results[k]);
                  }
                });
                comfortList.forEach((item) => {
                  if (item.label === k) {
                    item.data.push(p.results[k]);
                  }
                });
              });
              // label
              word = this.formatDate(p.accDate);
              DateObj.push(word);
            });
            // Reverse array so that it starts from the oldest time
            collisionList.forEach((item, i) => {
              item.label = this.$t(item.label);
              item.data.reverse();
            });
            this.collisionDatasets = collisionList;
            badList.forEach((item) => {
              item.label = this.$t(item.label);
              item.data.reverse();
            });
            this.badDatasets = badList;
            comfortList.forEach((item) => {
              item.label = this.$t(item.label);
              item.data.reverse();
            });
            this.comfortDatasets = comfortList;
            // label
            DateObj.reverse();
            this.collisionLabels = DateObj;
            this.badLabels = DateObj;
            this.comfortLabels = DateObj;

            // console.log('Chart ok');
          }
        }
      );
    },

    getProfileOverview(currentPoint) {
      // this.currentQuery = {
      //   TargetType: this.query.TargetType,
      //   Date: moment(this.time).format('YYYY-MM-DD'),
      //   OutputType: 'company',
      //   Distance: this.query.Distance,
      //   ProfileType: this.query.ProfileType,
      // };
      console.log(currentPoint);
    },

    formatDate(date) {
      switch (this.query.ProfileType) {
        case 'day':
          return moment(date).format('YYYY/MM/DD');
        // break;
        case 'week':
          return 'W' + moment(date).format('ww');
        // break;
        case 'month':
          return moment(date).format('YYYY/MM');
        // break;
        case 'year':
          return moment(date).format('YYYY');
        // break;
        default:
          return moment(date).format('YYYY/MM/DD');
      }
    },
    // Download Image
    downloadImg(event) {
      const link = document.createElement('a');
      link.href = this.$refs[event].$el.toDataURL('image/jpg');
      link.download = this.$t(event) + '.png';
      link.click();
    },
  },
};
