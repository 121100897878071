
import { mapMutations, mapState } from 'vuex';

export default {
  data: () => ({
    showChangePassword: false,
    showContact: false,
  }),
  computed: {
    ...mapState('app', ['image', 'barColor']),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.set_Drawer(val);
      },
    },
    expandOnHover: {
      get() {
        return this.$store.state.app.toggleDrawer;
      },
    },
    links: {
      get() {
        return this.$store.state.app.menuLinks;
      },
    },
    userName: {
      get() {
        return this.$store.state.app.userName;
      },
    },
    locales: {
      get() {
        return this.$store.state.app.locales;
      },
    },
    locale: {
      get() {
        return this.$store.state.app.locale;
      },
    },
  },
  watch: {
    '$vuetify.breakpoint.smAndDown'(val) {
      this.$emit('update:expandOnHover', val);
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
    },
  },
  methods: {
    openExternal() {
      console.log('open external');
      window.open(
        process.env.FORKLIFTUTIL_URL ||
          'https://victorious-pebble-0573ccc00.5.azurestaticapps.net',
        'newWindowForkliftUtil'
      );
    },
    ...mapMutations('app', [
      'set_Drawer',
      'get_ToggleDrawer',
      'set_Lang',
      'clear_Token',
    ]),
    closeOther(val, items) {
      items.forEach((x, i) => {
        if (val !== i) x.active = false;
      });
      console.log(items);
    },
    setLang(lang) {
      this.set_Lang(lang);
    },
    Signout() {
      this.clear_Token();
      this.$signout(this.$msal);
    },
    changePassword() {
      this.showChangePassword = true;
    },
    changeResult(data) {
      this.snackbar = true;
      if (data) {
        this.result = this.$t('ChangePassowrdSuccess');
      } else {
        this.result = this.$t('ChangePassowrdfail');
      }
    },
    showContactInfo() {
      this.showContact = true;
    },
  },
};
