
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';
import moment from 'moment';

export default {
  name: 'Vehicle',
  components: {
    // downloadExcel: () => {
    //   if (process.client) {
    //     return import('vue-json-excel');
    //   }
    // },
    DeviceDetailDialog: () => {
      if (process.client) {
        return import('@/components/dialog/DeviceDetailDialog');
      }
    },
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  data: () => ({
    // 搜尋框輸入值
    Search: '',
    // datatable 的欄寬平均
    calculateWidths: true,
    downloadList: [],
    ItemsList: [],
    // 新增功能Dialog
    addDialog: false,
    updateValue: {},
    componentKey: 0,
    displayDelete: false,
    // New record
    newrecord: [],
    // Before delete items
    beforeDelete: false,
    itemsToBeDeleted: {},
    UpdateAuth: false,
    showMsg: false,
    msg: '',
    TBoxType: [],
    // * 6 channels
    videoChannels: Array.from({ length: 6 }, (_, i) => `CH-${i + 1}`),
    // * sensors
    sensors: [
      'HasSpeedSensor',
      'HasIndoorBeacon',
      'HasObdBox',
      'HasRotationSensor',
    ],
  }),
  computed: {
    Headers() {
      return [
        { text: this.$t('Setting.device'), value: 'serialNumber' },
        { text: this.$t('Setting.licensePlate'), value: 'plate' },
        { text: this.$t('Setting.status'), value: 'isActive' },
        {
          text: this.$t('Setting.lastLocationUpdateTime'),
          value: 'lastLocUpdate',
        },
        {
          text: this.$t('Setting.tbox'),
          value: 'tboxProviderId',
        },
        {
          text: 'ADAS',
          value: 'hasADAS',
        },
        {
          text: 'DMS',
          value: 'hasDMS',
        },
        {
          text: 'BSD',
          value: 'hasBSD',
        },
        ...this.sensors.map((n) => ({
          text: this.$t(`Setting.${n}`),
          value: n,
        })),
        ...this.videoChannels.map((n) => ({
          text: n,
          value: n,
        })),
        { text: this.$t('Setting.lastUpdated'), value: 'updatedAt' },
        {
          text: this.$t('Setting.crudActions'),
          value: 'crudActions',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    newrecord() {
      // Need to use this in order to get the most updated axios call
      // otherwise, the records will not update even axio call. See device
      // dialog for more details.
      // console.log('newrecord OnChange');
      // console.log(this.newrecord);
      this.getDevice();
    },
    displayDelete() {
      setTimeout(() => (this.displayDelete = false), 3000);
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setDownloadList();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.getDevice();
      // this.setDownloadList();
    },
    '$store.state.app.userRole'(val) {
      this.checkAuth();
    },
  },
  mounted() {
    this.getDevice();
    this.checkAuth();
    ApiService.getTBoxProvider(this.$axios).then((response) => {
      this.TBoxType = response.reduce(
        (p, t) => ({ ...p, [t.key]: t.value }),
        {}
      );
    });
  },
  methods: {
    // * for dynamic slot name
    dummyFnForDynamicNameVSlot(n) {
      const name = `item.${n}`;
      return name;
    },
    // auth
    checkAuth() {
      this.UpdateAuth = false;
      const val = this.$store.state.app.userRole;
      if (val === 'HQ') {
        this.UpdateAuth = true;
      }
    },
    getDevice() {
      this.wrapShowloading(
        ApiService.getDevice(this.$axios)
          .then((response) => {
            if (response) {
              this.ItemsList = response?.data;
              this.setDownloadList();
            }
          })
          .catch({})
      );
    },
    setDownloadList() {
      if (this.ItemsList.length > 0) {
        this.downloadList = [];
        this.ItemsList.forEach((item, i) => {
          this.downloadList.push({});
          this.downloadList[i][this.$t('Setting.device')] = item.serialNumber;
          this.downloadList[i][this.$t('Setting.licensePlate')] = item.plate;
          this.downloadList[i][this.$t('Setting.status')] = item.isActive
            ? // ? 'ACTIVE'
              // : 'INACTIVE';
              this.$t('Setting.Active')
            : this.$t('Setting.Inactive');
          this.downloadList[i][this.$t('Setting.lastLocationUpdateTime')] =
            moment(item.lastLocUpdate).format('YYYY-MM-DD HH:mm:ss');
          this.downloadList[i][this.$t('Setting.tbox')] =
            this.TBoxType[item.tboxProviderId];
          this.downloadList[i].ADAS = item.options.HasADAS;
          this.downloadList[i].DMS = item.options.HasDMS;
          this.downloadList[i].BSD = item.options.HasBSD;
          for (const chname of [...this.sensors, ...this.videoChannels]) {
            const isVideoCh = chname.includes('CH-');
            this.downloadList[i][
              isVideoCh ? chname : this.$t(`Setting.${chname}`)
            ] = item[isVideoCh ? 'videoChannels' : 'options'][chname]
              ? this.$t('User.false')
              : this.$t('User.true');
          }
          this.downloadList[i][this.$t('Setting.lastUpdated')] =
            moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
            ' (' +
            item.updatedBy +
            ')';
        });
      }
    },
    updateData(val) {
      this.updateValue = val;
      this.addDialog = true;
    },
    beforeDeleteData(val) {
      console.log(val);
      this.beforeDelete = true;
      this.itemsToBeDeleted = val;
    },
    deleteData(val) {
      this.beforeDelete = false;

      // Delete the records in Vuetify datatable and refresh.
      this.ItemsList = this.ItemsList.filter((item) => item.id !== val.id);
      this.forceRerender();
      this.displayDelete = true;

      ApiService.deleteDevice(this.$axios, val.id);
    },
    openFileExplorer() {
      this.$refs.fileInputImport.click();
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      ApiService.postUploadDevice(this.$axios, formData).then((response) => {
        this.resColor = response.succeeded ? 'green' : 'red';
        this.msg = response.messages;
        this.showMsg = true;
      });
      this.$refs.fileInputImport.value = '';
    },
    downloadCSV() {
      // eslint-disable-next-line
      let csvcontent = JSON.parse(JSON.stringify(this.ItemsList));

      // Acquire headers
      const csvheaders = this.Headers.map((obj) => obj.value);

      // Special add
      csvheaders.push('updatedBy', 'updatedAt', 'tboxProviderId');

      // Format companyList as same as display
      csvcontent.forEach((item) => {
        Object.entries(item).forEach(([key]) => {
          // If none of the key in csvcontent satisfies the headers, remove them.
          if (!csvheaders.includes(key)) delete item[key];
        });
      });

      // Pushing the values to a new and renamed object.
      // this.copyList.forEach((item) => {
      //   csvcontent.push({
      //     'Device S/N': item.serialNumber,
      //     'License Plate': item.plate,
      //     Status: item.isActive,
      //     'Last Location Update Time': moment(item.updatedAt).format(
      //       'YYYY-MM-DD HH:mm:ss'
      //     ),
      //     '4S/MSS2': this.tBoxText(item.tBoxProviderId),
      //     'Last Updated':
      //       moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
      //       ' (' +
      //       item.updatedBy +
      //       ')',
      //   });
      // });
      return csvcontent;
    },
    addData() {
      this.addDialog = true;
    },
    clearUpdateValue() {
      this.updateValue = {};
    },
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerenderOnSubmit(val) {
      console.log('rerender');
      this.componentKey += 1;
      this.ItemsList = [];
      this.getDevice();
      this.showMsg = true;
      this.msg = val.messages;
    },
  },
};
