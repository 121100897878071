
export default {
  props: {
    value: Boolean,
    confirmType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reason: null,
      title: '',
      Reason: [],
      acceptReason: [
        { text: this.$t('ApproveReason-1'), value: this.$t('ApproveReason-1') },
        { text: this.$t('ApproveReason-2'), value: this.$t('ApproveReason-2') },
        { text: this.$t('ApproveReason-3'), value: 3 },
      ],
      rejectReason: [
        { text: this.$t('RejectReason-1'), value: this.$t('RejectReason-1') },
        { text: this.$t('RejectReason-2'), value: this.$t('RejectReason-2') },
        { text: this.$t('RejectReason-3'), value: 3 },
      ],
      other: '',
    };
  },
  computed: {
    show: {
      get() {
        this.init();
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.title = this.$t(this.confirmType);
      this.reason = null;
      // Check approve/reject
      if (this.confirmType === 'approve') {
        this.Reason = this.acceptReason;
      } else {
        this.Reason = this.rejectReason;
      }
    },
    confirm() {
      const response = {
        index: true,
        type: this.confirmType,
        reason: this.reason === 3 ? this.other : this.reason,
      };
      this.$emit('result', response);
      this.show = false;
    },
  },
};
