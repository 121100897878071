
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';
import moment from 'moment';

export default {
  name: 'Vehicle',
  components: {
    // downloadExcel: () => {
    //   if (process.client) {
    //     return import('vue-json-excel');
    //   }
    // },
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
    DriverDetailDialog: () => {
      if (process.client) {
        return import('@/components/dialog/DriverDetailDialog');
      }
    },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    formatDoB(date) {
      return date ? moment(date).format('YYYY-MM-DD') : null;
    },
  },
  data: () => ({
    // 搜尋框輸入值
    Search: '',
    // datatable 的欄寬平均
    calculateWidths: true,
    downloadList: [],
    ItemsList: [],
    // 新增功能Dialog
    addDialog: false,
    updateValue: {},
    componentKey: 0,
    displayDelete: false,
    // Before delete items
    beforeDelete: false,
    itemsToBeDeleted: {},
    showMsg: false,
    msg: '',
  }),
  computed: {
    Headers() {
      return [
        { text: this.$t('Setting.name'), value: 'name' },
        { text: this.$t('Setting.driverCode'), value: 'code' },
        { text: this.$t('Setting.department'), value: 'companyGroup' },
        // { text: this.$t('Setting.phoneHome'), value: 'brand' },
        { text: this.$t('Setting.phoneMobile'), value: 'mobile' },
        // { text: this.$t('Setting.phoneOffice'), value: 'type' },
        { text: this.$t('Setting.dateOfBirth'), value: 'bod' },
        { text: this.$t('Setting.lastUpdated'), value: 'updatedAt' },
        {
          text: this.$t('Setting.crudActions'),
          value: 'crudActions',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    displayDelete() {
      setTimeout(() => (this.displayDelete = false), 3000);
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setDownloadList();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.getDrivers();
      // this.setDownloadList();
    },
  },
  mounted() {
    this.getDrivers();
  },
  methods: {
    getDrivers() {
      this.wrapShowloading(
        ApiService.getDrivers(this.$axios)
          .then((response) => {
            if (response) {
              this.ItemsList = this.DescArray(response.items);
              this.ItemsList.forEach((item, i) => {
                this.downloadList.push({});
                this.downloadList[i][this.$t('Setting.name')] = item.name;
                this.downloadList[i][this.$t('Setting.driverCode')] = item.code;
                this.downloadList[i][this.$t('Setting.department')] =
                  item.companyGroup;
                // this.downloadList[i][this.$t('Setting.phoneHome')] = item.brand
                //   ? item.brand
                //   : '';
                this.downloadList[i][
                  this.$t('Setting.phoneMobile')
                ] = item.mobile ? item.mobile : '';
                // this.downloadList[i][this.$t('Setting.phoneOffice')] = item.type
                //   ? item.type
                //   : '';
                this.downloadList[i][this.$t('Setting.dateOfBirth')] = item.bod
                  ? moment(item.bod).format('YYYY-MM-DD')
                  : '';
                this.downloadList[i][this.$t('Setting.lastUpdated')] =
                  moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
                  ' (' +
                  item.updatedBy +
                  ')';
              });
              this.setDownloadList();
            }
          })
          .catch({})
      );
    },
    setDownloadList() {
      if (this.ItemsList.length > 0) {
        this.downloadList = [];
        this.ItemsList.forEach((item, i) => {
          this.downloadList.push({});
          this.downloadList[i][this.$t('Setting.name')] = item.name;
          this.downloadList[i][this.$t('Setting.driverCode')] = item.code;
          this.downloadList[i][this.$t('Setting.department')] =
            item.companyGroup;
          // this.downloadList[i][this.$t('Setting.phoneHome')] = item.brand
          //   ? item.brand
          //   : '';
          this.downloadList[i][this.$t('Setting.phoneMobile')] = item.mobile
            ? item.mobile
            : '';
          // this.downloadList[i][this.$t('Setting.phoneOffice')] = item.type
          //   ? item.type
          //   : '';
          this.downloadList[i][this.$t('Setting.dateOfBirth')] = item.bod
            ? moment(item.bod).format('YYYY-MM-DD')
            : '';
          this.downloadList[i][this.$t('Setting.lastUpdated')] =
            moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
            ' (' +
            item.updatedBy +
            ')';
        });
      }
    },
    updateData(val) {
      this.updateValue = val;
      this.addDialog = true;
    },
    beforeDeleteData(val) {
      console.log(val);
      this.beforeDelete = true;
      this.itemsToBeDeleted = val;
    },
    deleteData(val) {
      this.beforeDelete = false;

      // Delete the records in Vuetify datatable and refresh.
      this.ItemsList = this.ItemsList.filter((item) => item.id !== val.id);
      // this.forceRerender(val);
      this.displayDelete = true;

      ApiService.deleteDrivers(this.$axios, val.id)
        .then((response) => {
          this.forceRerenderOnSubmit(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openFileExplorer() {
      this.$refs.fileInputImport.click();
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      ApiService.postUploadDriver(this.$axios, formData).then((response) => {
        if (!response.success) {
          this.msg = response.message;
          this.resColor = 'red';
        } else {
          this.msg = this.$t('UploadSuccess');
          this.resColor = 'green';
        }
        this.showMsg = true;
      });
      this.$refs.fileInputImport.value = '';
    },
    downloadCSV() {
      // eslint-disable-next-line
      let csvcontent = JSON.parse(JSON.stringify(this.ItemsList));

      // Acquire headers
      const csvheaders = this.Headers.map((obj) => obj.value);

      // Special add
      csvheaders.push('updatedBy', 'updatedAt');

      // Format companyList as same as display
      csvcontent.forEach((item) => {
        Object.entries(item).forEach(([key]) => {
          // If none of the key in csvcontent satisfies the headers, remove them.
          if (!csvheaders.includes(key)) delete item[key];
        });
      });

      // this.copyList.forEach((item) => {
      //   csvcontent.push({
      //     Name: item.name,
      //     'Driver Code': item.code,
      //     Department: item.companyGroup,
      //     'Phone Home': item.brand,
      //     'Phone Mobile': item.mobile,
      //     'Phone Office': item.type,
      //     'Date of Birth': item.bod,
      //     'Last Updated':
      //       moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
      //       ' (' +
      //       item.updatedBy +
      //       ')',
      //   });
      // });
      return csvcontent;
    },
    addData() {
      this.addDialog = true;
    },
    clearUpdateValue() {
      this.updateValue = {};
    },
    forceRerender(val) {
      this.componentKey += 1;
      this.msg = val.message;
      this.showMsg = true;
    },
    forceRerenderOnSubmit(val) {
      this.componentKey += 1;
      this.ItemsList = [];
      this.getDrivers();
      this.showMsg = true;
      this.msg = val.message;
    },
    DescArray(array) {
      // eslint-disable-next-line
      let newarray = [];
      array.forEach((item) => {
        newarray.unshift(item);
      });
      return newarray;
    },
  },
};
