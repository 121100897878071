
import moment from 'moment';
import ApiService from '@/services/settings-service.js';

export default {
  // filters: {
  //   formatYear(date) {
  //     return moment(date).format('YYYY');
  //   },
  // },
  props: {
    value: Boolean,
    updateValue: {
      type: Object,
      default: () => {},
    },
    plates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // date: new Date().toISOString().substr(0, 4),
      menu: false,
      isAdd: false,
      // 3333
      vehicleId: '',
      runDistance: '',
      licencePlate: '',
      vin: '',
      brand: '',
      model: '',
      type: '',
      department: '',
      tboxProviderId: 2,
      status: true,
      // test
      departmentOption: [],
      checkDepartmentOption: [],
      filterInput: '',
      filterTypeInput: '',
      // duplicate flag
      duplicateFlag: false,
      date: new Date(),
      rules: {
        required: (value) => !!value || this.$t('Required'),
      },
      TBoxType: [],
    };
  },
  computed: {
    Active() {
      return [
        { text: this.$t('Setting.Active'), value: true },
        { text: this.$t('Setting.Inactive'), value: false },
      ];
    },
    // According to retrieved Postman records, the id of TBoxType are
    /*
        ID: TYPE
        2: MSS2
        4: 4S
    */
    // moved to data

    // According to Database (DB), the corresponding Id and type are:
    /*
      ID: TYPE
        1: CAR
        2: VAN
        3: MINIBUS
        4: BUS
        5: TRUCK
        6: CONCRETE TRUCK
        7: OTHERS
    */
    VehicleType() {
      return [
        { text: this.$t('Setting.CAR'), value: 1, icon: 'mdi-car' },
        { text: this.$t('Setting.VAN'), value: 2, icon: 'mdi-van-utility' },
        {
          text: this.$t('Setting.MINI-BUS'),
          value: 3,
          icon: 'mdi-bus',
        },
        {
          text: this.$t('Setting.BUS'),
          value: 4,
          icon: 'mdi-bus-articulated-front',
        },
        { text: this.$t('Setting.TRUCK'), value: 5, icon: 'mdi-truck' },
        {
          text: this.$t('Setting.CONCRETE-TRUCK'),
          value: 6,
          icon: '',
        },
        {
          text: this.$t('Setting.TIPPER_TRUCK'),
          value: 8,
        },
        {
          text: this.$t('Setting.CEMENT_TANKER'),
          value: 9,
        },
        {
          text: this.$t('Setting.TAXI'),
          value: 10,
        },
        {
          text: this.$t('Setting.SCHOOL_BUS'),
          value: 11,
        },
        {
          text: this.$t('Setting.FORKLIFT'),
          value: 12,
        },
        {
          text: this.$t('Setting.OTHERS'),
          value: 7,
          icon: 'mdi-car-hatchback',
        },
      ];
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    duplicateFlag() {
      setTimeout(() => (this.duplicateFlag = false), 3000);
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    async show(val) {
      this.$refs.form.resetValidation();
      this.date = new Date();
      this.vehicleId = '';
      this.runDistance = '';
      this.licencePlate = '';
      this.vin = '';
      this.brand = '';
      this.model = '';
      this.type = '';
      this.department = '';
      this.status = true;
      if (val) {
        if (Object.keys(this.updateValue).length === 0) {
          this.isAdd = true;
        } else {
          this.vehicleId = this.updateValue.id;
          this.runDistance = this.updateValue.distance;
          this.licencePlate = this.updateValue.plate;
          this.vin = this.updateValue.vin;
          this.brand = this.updateValue.brand;
          this.model = this.updateValue.model;
          this.type = this.updateValue.vehicleTypeId;
          this.date = this.dateFromDay(this.updateValue.year, 1);
          this.department = this.updateValue.companyGroupId;
          this.tboxProviderId = this.updateValue.tBoxProviderId;
          this.status = this.updateValue.isActive;
        }
        this.getCompanyGroups();
        await this.getTBoxProvider();
      } else {
        this.isAdd = false;
        this.$emit('clearUpdateValue');
      }
    },
    filterInput(value) {
      this.departmentOption = this.checkDepartmentOption;
      this.departmentOption = this.departmentOption.filter((post) => {
        return post.text.toLowerCase().trim().includes(value.toLowerCase());
      });
    },
    // departmentOption(val) {
    //   console.log(val);
    // },
  },
  mounted() {
    // this.getCompanyGroups();
  },
  methods: {
    disableDate(date) {
      return date > new Date();
    },
    saveYear(year) {
      this.$refs.menu.save(year);
      this.$refs.picker.activePicker = 'YEAR';
      this.menu = false;
    },
    async getTBoxProvider() {
      const response = await ApiService.getTBoxProviderV1(this.$axios);
      this.TBoxType = response.map((t) => ({ text: t.value, value: t.key }));
    },
    getCompanyGroups() {
      ApiService.getCompanyGroups(this.$axios)
        .then((response) => {
          this.departmentOption = response.items.map((element) => ({
            text: element.name,
            value: element.id,
          }));
          /*
          response.items.forEach((element) => {
            this.departmentOption.push({
              text: element.name,
              value: element.id,
            });
          });
          */
        })
        .catch({});
      this.checkDepartmentOption = this.departmentOption;
    },
    submit() {
      if (this.$refs.form.validate()) {
        const query = {
          plate: this.licencePlate,
          vin: this.vin,
          brand: this.brand,
          model: this.model,
          year: parseInt(moment(this.date).format('YYYY')),
          vehicleTypeId: this.type,
          companyGroupId: this.department ? this.department : null,
          tBoxProviderId: this.tboxProviderId,
          isActive: this.status,
        };
        if (this.isAdd) {
          if (this.plates.includes(this.licencePlate)) {
            this.duplicateFlag = true;
            return;
          }
          console.log('add');
          console.log(query);
          ApiService.addVehicles(this.$axios, query)
            .then((response) => {
              if (response.success) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
          // window.location = window.location.href + '?eraseCache=true';
        } else {
          console.log('update');
          query.id = this.vehicleId;
          console.log(query);
          ApiService.updateVehicles(this.$axios, query)
            .then((response) => {
              if (response.success) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        // this.$emit('forceRerenderOnSubmit');
        this.show = false;
      }
    },
    dateFromDay(year, day) {
      const date = new Date(year, 0); // initialize a date in `year-01-01`
      return new Date(date.setDate(day)); // add the number of days
    },
  },
};
