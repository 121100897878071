
import { mapMutations, mapState } from 'vuex';
import ApiService from '@/services/api-service.js';

export default {
  inject: ['reload'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showChangePassword: false,
    showContact: false,
    title: '',
    company: '',
    snackbar: false,
    timeout: 3000,
    result: '',
    clpNote: false,
  }),
  computed: {
    ...mapState('app', ['locales', 'locale']),
    userName: {
      get() {
        return this.$store.state.app.userName;
      },
    },
    locales: {
      get() {
        return this.$store.state.app.locales;
      },
    },
    locale: {
      get() {
        return this.$store.state.app.locale;
      },
    },
    companies: {
      get() {
        return this.$store.state.app.companies;
      },
    },
  },
  watch: {
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
    },
    '$store.state.app.title'(val) {
      this.title = val;
    },
    // 'msal.isAuthenticated': {
    //   handler(value) {
    //     console.log('isAuthenticated is ', value);
    //     // Do something here
    //   },
    //   immediate: true,
    // },
    // 'msal.accessToken': {
    //   handler(value) {
    //     this.$store.dispatch('setToken', value);
    //     // console.log('accessToken is ', value)
    //     // Do something here
    //   },
    //   immediate: true,
    // },
    '$store.state.app.companies'(val) {
      if (val) {
        if (!this.company) {
          this.company = val[0];
          this.getAuth();
          // this.sendCompany();
        }
        this.clpNote = false;
        if (this.company === 'clp') {
          this.clpNote = true;
        }
      }
    },
  },

  mounted() {
    this.getCompany();
    this.pullTitle();
    this.get_ToggleDrawer();
  },
  methods: {
    ...mapMutations('app', [
      'set_Drawer',
      'get_Company',
      'set_Company',
      'set_CompanyRole',
      'set_Menu',
      'set_Function',
      'set_CamType',
      'get_Lang',
      'set_Lang',
      'set_Title',
      'get_Title',
      'set_ToggleDrawer',
      'get_ToggleDrawer',
      'clear_Token',
    ]),
    getAuth() {
      ApiService.getUserRights(this.$axios).then((response) => {
        if (response) {
          this.set_Menu(response.menuLinks);
          this.sendCompany();
        }
      });
    },
    getCompany() {
      this.get_Company();
      this.company = this.$store.state.app.company;
    },
    pullTitle() {
      this.get_Title();
      if (!this.$store.state.app.title) {
        this.$router.push('/');
      }
      this.title = this.$store.state.app.title;
    },
    sendCompany() {
      this.set_Company(this.company.code);
      this.set_CompanyRole(this.company.role);
      this.set_CamType(this.company.camTypeAllow);
      this.set_Function(this.company.pageFunctionAllow);
      this.reload();
      this.clpNote = false;
      if (this.company.code === 'clp') {
        this.clpNote = true;
      }
      // to fetch locales from remote, and merge with client
      // remoteLocales is injected in globalutil.js
      // done in middleware, but here is needed in case of change company
      this.$remoteLocales(this.$axios, this.company.code).then();
    },
    setLang(lang) {
      this.set_Lang(lang);
    },
    onClick() {
      // eslint-disable-next-line no-debugger
      this.set_ToggleDrawer();
      if (this.$vuetify.breakpoint.smAndDown) {
        this.set_Drawer(!this.drawer);
      } else {
        this.$emit('input', this.$store.state.app.toggleDrawer);
      }
    },
    signOut() {
      this.clear_Token();
      this.$signout(this.$msal);
    },
    changePassword() {
      this.showChangePassword = true;
    },
    changeResult(data) {
      this.snackbar = true;
      if (data) {
        this.result = this.$t('ChangePassowrdSuccess');
      } else {
        this.result = this.$t('ChangePassowrdfail');
      }
    },
    showContactInfo() {
      this.showContact = true;
    },
  },
  head() {
    return {
      title: this.$store.state.app.title,
    };
  },
};
