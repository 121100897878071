
import moment from 'moment';
import ApiService from '@/services/settings-service.js';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    Treeselect: () => {
      if (process.client) {
        return import('@riophae/vue-treeselect');
      }
    },
  },
  filters: {
    formatYear(date) {
      return moment(date).format('YYYY');
    },
  },
  props: {
    value: Boolean,
    updateValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      date: null,
      menu: false,
      isAdd: false,
      username: '',
      displayName: '',
      companyRole: '',
      email: '',
      department: '',
      lang: '',
      roleOption: [],
      departmentOption: [],
      langOption: [],
      rules: {
        required: (value) => !!value || this.$t('Required'),
        counter: (value) =>
          !value ||
          (value.length <= 8 && value.length >= 16) ||
          this.$t('PasswordHint'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('InvalidEmail');
        },
        password: (value) => {
          const pattern = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&amp;*\-_+=[\]{}|\\:',?/`~&quot;();!]|\.(?!@)){8,16}$/;
          return pattern.test(value) || this.$t('PasswordHint');
        },
      },
      normalizer(node) {
        return {
          id: node.value,
          label: node.value,
          children: node.children,
        };
      },
      state: true,
      selfOnly: false,
      // stateOption: [
      //   { text: this.$t('User.true'), value: true },
      //   { text: this.$t('User.false'), value: false },
      // ],
      password: '',
      // passwordRules: [
      //   (v) => !!v || this.$t('PasswordRequired'),
      //   (v) => (v || '').length >= 4 || this.$t('PasswordMin'),
      // ],
    };
  },
  computed: {
    Active() {
      return [
        { text: this.$t('Setting.Active'), value: true },
        { text: this.$t('Setting.Inactive'), value: false },
      ];
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    show(val) {
      this.$refs.form.resetValidation();
      this.getUserDropdownList();
      this.username = '';
      this.displayName = '';
      this.email = '';
      this.companyRole = '';
      this.department = null;
      this.lang = '';
      this.userId = '';
      this.state = true;
      this.selfOnly = false;
      this.password = '';
      if (val) {
        if (Object.keys(this.updateValue).length === 0) {
          this.isAdd = true;
        } else {
          // console.log(this.updateValue);
          this.username = this.updateValue.username;
          this.displayName = this.updateValue.displayName;
          this.email = this.updateValue.email;
          this.state = !this.updateValue.isSuspended;
          this.selfOnly = this.updateValue.isViewSelfOnly;
          this.department = this.updateValue.department;
          this.roleOption.forEach((item) => {
            if (item.text === this.updateValue.companyRole) {
              this.companyRole = item.value;
            }
          });
          // this.departmentOption.forEach((item) => {
          //   if (item.value === this.updateValue.department) {
          //     this.department = item.value;
          //   }
          // });
          this.langOption.forEach((item) => {
            if (item.value === this.updateValue.lang) {
              this.lang = item.value;
            }
          });
        }
      } else {
        this.isAdd = false;
        this.$emit('clearUpdateValue');
      }
    },
    filterInput(value) {
      this.departmentOption = this.checkDepartmentOption;
      this.departmentOption = this.departmentOption.filter((post) => {
        return post.text.toLowerCase().trim().includes(value.toLowerCase());
      });
    },
  },
  mounted() {
    this.getUserDropdownList();
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    getUserDropdownList() {
      ApiService.getUserDropdownList(this.$axios)
        .then((response) => {
          this.roleOption = response.companyRoles;
          this.departmentOption = response.departments;
          this.langOption = response.langs;
        })
        .catch({});
    },
    submit() {
      if (this.$refs.form.validate()) {
        const query = {
          username: this.username.trim(),
          displayName: this.displayName,
          email: this.email.trim(),
          CompanyRole: this.companyRole,
          Department: this.department,
          lang: this.lang,
          isViewSelfOnly: this.selfOnly,
          isSuspended: !this.state,
          password: this.password,
        };
        if (this.isAdd) {
          ApiService.addUser(this.$axios, query)
            .then((response) => {
              if (response.success) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          query.id = this.updateValue.userId;
          ApiService.updateUser(this.$axios, query)
            .then((response) => {
              if (response.success) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
