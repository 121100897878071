
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/api-service.js';
import moment from 'moment';

export default {
  data() {
    return {
      valid: true,
      newFileName: '',
      videoString: [],
      showMsg: false,
      msg: '',
      msgColor: 'primary',
    };
  },
  methods: {
    checkForm() {
      if (this.videoString === '') {
        this.valid = false;
      }
    },
    sendForm() {
      this.checkForm();
      this.msgColor = 'primary';
      const form = {};
      form.filename = this.newFileName;
      form.warningVideoIdString = this.videoString;
      if (this.valid) {
      }
      ApiService.getWarningVideo(this.$axios, form)
        .then((response) => {
          if (response.type.includes('application/json')) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const content = reader.result;
              const result = JSON.parse(content);

              this.msgColor = 'error';
              this.showMsg = true;
              this.msg = result.message;
            };
            reader.readAsText(response);
          } else {
            const fileName = this.newFileName
              ? this.newFileName
              : 'WarningData_' + moment(new Date()).format('YYYY-MM-DD');
            const blob = null;
            const link = document.createElement('a');
            link.style.display = 'none';
            this.blob = new Blob([response], {
              type: 'application/zip',
            });
            link.download = fileName + '.zip';
            link.href = window.URL.createObjectURL(this.blob);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          console.log(error);
          this.msgColor = 'error';
          this.dialog = false;
        });
    },
  },
};
