
/* eslint-disable no-new */
import { MarkerClusterer } from '@googlemaps/markerclusterer';
// import { MarkerWithLabel } from '@googlemaps/markerwithlabel';

export default {
  props: {
    center: {
      type: Object,
      default: () => ({ lat: 22.3186, lng: 114.1796 }),
    },
    circlesData: {
      type: Array,
      default: () => [],
    },
    wayWarningLocations: {
      type: Object,
      default: () => {},
    },
    wayGetFocus: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      map: null,
      circles: this.circlesData,
      markerClusterMap: null,
      circleClickHandlers: [],
      circleMouseOverHandlers: [],
    };
  },
  computed: {},
  watch: {
    circlesData(val) {
      this.redrawCircles(val);

      /*
        circle.MarkerWithLabel = new MarkerWithLabel({
          position: data.center,
          map: this.map,
          labelContent: data.info,
          labelAnchor: new window.google.maps.Point(-51, -10),
          labelClass: 'circle-labels',
          labelStyle: { opacity: 1.0, fontSize: '24px' },
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 0,
          },
        });
*/
    },
    wayGetFocus(val) {
      // when add new tab, cluster is not populated yet, but here gets called first, so should do nothing because focusWayOnMap check for wayId exist in cluster
      //
      // so here is when tabs already existed and for the time when selected
      this.focusWayOnMap(val);
    },
    wayWarningLocations: {
      handler(valobj, oldobj) {
        // remove existing markers
        this.removeAllWarningMarkers();

        const valarr = Object.values(valobj);

        console.log(valarr);
        valarr.forEach((val) => {
          const markers = val.warnings.map((warningDetail) => {
            const marker = new window.google.maps.Marker({
              mapTypeId: { id: warningDetail.id },
              position: { lat: warningDetail.lat, lng: warningDetail.lng },
            });
            marker.addListener('click', () => {
              this.showSpeedingInfoWindow(marker, warningDetail);
            });
            return marker;
          });

          const cluster = new MarkerClusterer({
            markers,
            map: this.map,
          });

          if (!this.markerClusterMap) this.markerClusterMap = {};
          this.markerClusterMap[val.wayId] = {
            markers,
            cluster,
          };
        });

        // here is for add or remove tab
        const oldarr = Object.values(oldobj).map((v) => v.wayId);
        console.log('old %s', oldarr);
        const newWayIdArr = valarr.map((v) => v.wayId);
        console.log('new %s', newWayIdArr);
        if (valarr.length > oldarr.length) {
          // add tab

          const [wayId] = valarr
            .map((v) => v.wayId)
            .filter((x) => !oldarr.includes(x));
          // this.focusWayOnMap(valarr.at(-1).wayId);

          this.focusWayOnMap(wayId);
        } else if (valarr.length > 0 && valarr.length < oldarr.length) {
          // remove tab
          const [wayId] = newWayIdArr;
          this.focusWayOnMap(wayId);
        } else if (valarr.length === oldarr.length) {
          // same len, trigger chip
          this.focusWayOnMap(this.wayGetFocus);
        } else {
          this.focusWayOnMap();
        }
      },
      deep: false,
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    redrawCircles(val) {
      this.cleanUpCircles();

      for (const data of val) {
        const circle = new window.google.maps.Circle({
          strokeColor: data.fillColor,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          // fillColor: '#FF0000',
          fillColor: data.fillColor,
          fillOpacity: 0.55,
          map: this.map,
          center: data.center,
          radius: data.radius,
        });
        circle.computedRadius = data.radius;

        this.circleClickHandlers.push(
          circle.addListener('click', () => {
            this.$emit('circleClick', { wayId: data.wayId });
          })
        );

        const infoWindow = new window.google.maps.InfoWindow({});
        const marker = new window.google.maps.Marker({
          map: this.map,
        });
        this.circleMouseOverHandlers.push({
          over: circle.addListener('mouseover', () => {
            marker.setPosition(circle.getCenter());
            infoWindow.setContent(`
            <b> ${
              (this.$store.state.app.locale === 'en'
                ? data.address_EN
                : data.address_HK) ?? ''
            }</b>
            <br> ${data.lat},${data.lon}
            <br> ${data.warningPctg}% (${data.warningCount} / ${
              data.passingCount
            })
            <br> ${data.speedLimit}kph

            `); // set content
            infoWindow.open(this.map, marker); // open at marker's location
            marker.setVisible(false);
          }),
          out: circle.addListener('mouseout', () => {
            infoWindow.close();
          }),
        });
        circle.marker = marker;
        this.circles.push(circle);
      }
    },
    cleanUpCircles() {
      for (const c of this.circles) {
        // c.MarkerWithLabel.setMap(null);
        c.setMap(null);
        c.marker.setMap(null);
      }
      for (const h of this.circleClickHandlers) {
        window.google.maps.event.removeListener(h);
      }
      for (const { over, out } of this.circleMouseOverHandlers) {
        window.google.maps.event.removeListener(over);
        window.google.maps.event.removeListener(out);
      }
    },
    focusWayOnMap(wayId) {
      console.log('focus way %s', wayId);
      if (
        wayId !== undefined &&
        wayId !== '' &&
        this.markerClusterMap !== null &&
        wayId in this.markerClusterMap
      ) {
        const bounds = new window.google.maps.LatLngBounds();
        this.markerClusterMap[wayId].markers.forEach((m) => {
          bounds.extend(m.position);
        });
        this.map.fitBounds(bounds);
      } else {
        this.map.panTo(this.center);
        this.map.setZoom(12);
      }
    },
    showSpeedingInfoWindow(marker, location) {
      const infowindow = new window.google.maps.InfoWindow({
        content: `
            <div id="content">
              <div style="text-align:center;">
                <h3>${this.$t(location.warningTypeText)}<h3>
              </div>
              <hr>
                <table style="width:100%">
                <tr>
                    <th align="left">${this.$t('vehicle')}:</th>
                    <td align="left">${location.vehiclePlate ?? ''}</td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('startTime')}:</th>
                    <td align="left">${
                      this.momentformatDate(location.startTime) ?? ''
                    }</td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('duration')}:</th>
                    <td align="left">${location.duration ?? ''}</td>
                  </tr>
                  <tr>
            <th align="left">${this.$t('speedLimit')}:</th>
            <td align="left">${location.speedLimit}</td>
          </tr>
                  <tr>
                    <th align="left">${this.$t('startSpeed')}:</th>
                    <td align="left" >${location.startSpeed ?? ''} </td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('endSpeed')}:</th>
                    <td align="left" >${location.endSpeed ?? ''} </td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('topSpeed')}:</th>
                    <td align="left">${location.topSpeed ?? ''} </td>
                  </tr>
                  
                </table>
            </div>
                `,
        maxWidth: 300,
      });

      infowindow.open(this.map, marker);
    },
    removeAllWarningMarkers() {
      if (this.markerClusterMap === null) return;

      Object.keys(this.markerClusterMap).forEach((wayId) => {
        const markers = this.markerClusterMap[wayId].markers;
        const markerCluster = this.markerClusterMap[wayId].cluster;
        markerCluster && markerCluster.clearMarkers();
        markers.forEach((m) => {
          m.setMap(null);
        });
      });
    },
    initMap() {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: this.center,
        zoom: 12,
        maxZoom: 35,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
      });

      this.map.addListener('zoom_changed', () => {
        const zoomLevel = this.map.getZoom();
        console.log(zoomLevel);
        if (zoomLevel >= 16) {
          for (const c of this.circles) {
            c.setRadius(20);
            c.setOptions({ fillOpacity: 1 });
          }
        } else {
          for (const c of this.circles) {
            c.setRadius(c.computedRadius);
            c.setOptions({ fillOpacity: 0.55 });
          }
        }
      });
    },
  },
};
