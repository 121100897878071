
import Jessibuca from '@/components/Jessibuca';
export default {
  layout: 'livestream',
  components: { Jessibuca },
  data() {
    return {
      currentPlate: '',
      hasBSD: false,
      show: false,
    };
  },
  mounted() {
    this.currentPlate = this.$route.query.plateNo;
    console.log('plateNo', this.currentPlate);
    /**
     * ! hack to delay , otherwise window.jessibuca is not available
     */
    this.show = this.currentPlate || false;
  },
};
