
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/api-service.js';
import VideoDialog from '@/components/dialog/VideoDialog';
import AppealDetailDialog from '@/components/dialog/AppealDetailDialog';
import moment from 'moment';

export default {
  components: {
    VideoDialog,
    AppealDetailDialog,
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  data: () => ({
    tabList: null,
    tabs: ['Pending', 'Approved', 'Rejected'],
    appealList: [],
    pendingItems: [],
    approvedItems: [],
    rejectedItems: [],
    downloadList: [],
    text: 'test',
    search: '',
    videoDialog: false,
    media: '',
    editDialog: false,
    record: {},
    updateWarningList: false,
    processAppeal: false,
    UpdateAuth: false,
  }),
  computed: {
    headers() {
      return [
        { text: this.$t('appealId'), value: 'id' },
        { text: this.$t('licensePlate'), value: 'vehiclePlate' },
        { text: this.$t('driver'), value: 'driverCode' },
        { text: this.$t('Video'), value: 'video' },
        { text: this.$t('warningType'), value: 'warningTypeText' },
        { text: this.$t('speedLimit'), value: 'speedLimit' },
        { text: this.$t('startTime'), value: 'startTime' },
        { text: this.$t('appealedBy'), value: 'appealedBy' },
        { text: this.$t('appealedAt'), value: 'appealedAt' },
        { text: this.$t('crudActions'), value: 'crudActions', sortable: false },
      ];
    },
    links: {
      get() {
        return this.$store.state.app.menuLinks;
      },
    },
  },
  watch: {
    updateWarningList() {
      // update when it true
      if (this.updateWarningList) {
        this.acquireWarningList();
        this.updateWarningList = false;
      }
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.acquireWarningList();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.acquireWarningList();
    },
    '$store.state.app.processAppeal'(val) {
      this.processAppeal = val;
    },
  },
  mounted() {
    if (this.$store.state.app.company) {
      this.processAppeal = this.$store.state.app.processAppeal;
      this.acquireWarningList();
    }
  },
  methods: {
    acquireWarningList() {
      this.pendingItems = [];
      this.approvedItems = [];
      this.rejectedItems = [];
      this.downloadList = [];
      this.wrapShowloading(
        ApiService.getAppealList(this.$axios).then((response) => {
          if (response.items) {
            response.items.forEach((item, i) => {
              this.setDownloadList(item, i);
              if (item.isAppealApproved === null && item.isAppealed === true) {
                this.pendingItems.push(item);
              } else if (item.isAppealApproved === true) {
                this.approvedItems.push(item);
              } else if (item.isAppealApproved === false) {
                this.rejectedItems.push(item);
              } else {
                console.warn('Some Items are unclassified.');
              }
            });
            this.onTabChange();
          }
        })
      );
    },
    setDownloadList(item, i) {
      this.downloadList.push({});
      this.downloadList[i][this.$t('appealId')] = item.id ? item.id : '';
      this.downloadList[i][this.$t('licensePlate')] = item.vehiclePlate
        ? item.vehiclePlate
        : '';
      this.downloadList[i][
        this.$t('VehicleDepartment')
      ] = item.vehicleDepartment ? item.vehicleDepartment : '';
      this.downloadList[i][this.$t('driver')] = item.driverCode
        ? item.driverCode
        : '';
      this.downloadList[i][this.$t('DriverDepartment')] = item.driverDepartment
        ? item.driverDepartment
        : '';
      this.downloadList[i][this.$t('warningType')] = item.warningTypeText
        ? this.$t(item.warningTypeText)
        : '';
      this.downloadList[i][this.$t('startTime')] = item.startTime
        ? moment(item.startTime).format('YYYY-MM-DD HH:mm:ss')
        : '';
      this.downloadList[i][this.$t('duration')] =
        item.duration != null ? item.duration : '';
      this.downloadList[i][this.$t('startSpeed')] =
        item.startSpeed != null ? item.startSpeed : '';
      this.downloadList[i][this.$t('endSpeed')] =
        item.endSpeed != null ? item.endSpeed : '';
      this.downloadList[i][this.$t('topSpeed')] =
        item.topSpeed != null ? item.topSpeed : '';
      this.downloadList[i][this.$t('hw')] = item.hw != null ? item.hw : '';
      this.downloadList[i][this.$t('minHW')] =
        item.minHW != null ? item.minHW : '';
      this.downloadList[i][this.$t('location')] = item.lat + ',' + item.lng;
      this.downloadList[i][this.$t('Video')] = item.mediaFullId
        ? item.mediaFullId
        : '';
      this.downloadList[i][this.$t('speedLimit')] = this.getSpeedLimit(item);
      let tempAction = '';
      if (item.brakeOn) {
        tempAction = tempAction + this.$t('driverActionB');
      }
      if (item.leftSignalOn) {
        if (tempAction !== '') {
          tempAction = tempAction + ',' + this.$t('driverActionL');
        } else {
          tempAction = tempAction + this.$t('driverActionL');
        }
      }
      if (item.rightSignalOn) {
        if (tempAction !== '') {
          tempAction = tempAction + ',' + this.$t('driverActionR');
        } else {
          tempAction = tempAction + this.$t('driverActionR');
        }
      }
      this.downloadList[i][this.$t('driverAction')] = tempAction || '';
      this.downloadList[i][this.$t('appealedAt')] = item.appealedAt
        ? moment(item.appealedAt).format('YYYY-MM-DD HH:mm:ss')
        : '';
      this.downloadList[i][this.$t('appealedBy')] = item.appealedBy
        ? item.appealedBy
        : '';
      this.downloadList[i][this.$t('appealReason')] = item.appealReason
        ? item.appealReason
        : '';
      this.downloadList[i][this.$t('appealApprovedAt')] = item.appealApprovedAt
        ? moment(item.appealApprovedAt).format('YYYY-MM-DD HH:mm:ss')
        : '';
      this.downloadList[i][this.$t('appealApprovedBy')] = item.appealApprovedBy
        ? item.appealApprovedBy
        : '';
      this.downloadList[i][
        this.$t('appealApprovalReason')
      ] = item.appealApprovalReason ? item.appealApprovalReason : '';
      if (item.isAppealApproved === null && item.isAppealed === true) {
        this.downloadList[i][this.$t('appealResult')] = this.$t('Pending');
      } else if (item.isAppealApproved === true) {
        this.downloadList[i][this.$t('appealResult')] = this.$t('Approved');
      } else if (item.isAppealApproved === false) {
        this.downloadList[i][this.$t('appealResult')] = this.$t('Rejected');
      } else {
        this.downloadList[i][this.$t('appealResult')] = '';
      }
    },
    onTabChange() {
      this.appealList = [];
      if (this.tabList === 'Pending') {
        this.appealList = this.pendingItems;
      } else if (this.tabList === 'Approved') {
        this.appealList = this.approvedItems;
      } else if (this.tabList === 'Rejected') {
        this.appealList = this.rejectedItems;
      }
    },
    getImage(itemVal) {
      if (itemVal === false) return false;
      else return true;
    },
    playVideo(value) {
      this.media = value;
      this.videoDialog = true;
    },
    editItem(item) {
      // Triggered per row call on the details button.
      // The record data varies according to the row data.
      this.record = item;
      this.editDialog = true;
    },
  },
};
