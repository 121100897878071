
/* eslint-disable no-new */
/* eslint-disable prettier/prettier */
import moment from 'moment';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
// import { HeatmapOverlay } from '~/node_modules/gmaps-heatmap';

let lastOpenedInfoWindow = '';

export default {
  props: {
    center: {
      type: Object,
      default: () => ({
        lat: Number,
        lng: Number,
      }),
    },
    zoom: {
      type: Number,
      default: 12,
    },
  },
  data: () => ({
    map: Object,
    mapID: '',
    markers: [],
    polylines: [],
    locations: [],
    reductionId: '',
    reductionStatus: '',
    currentVehicle: [],
    historyMarkers: [],
  }),
  computed: {
    getCluster: {
      get() {
        return this.$store.state.app.getCluster;
      },
    },
  },
  watch: {
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      if (this.currentVehicle.isActive) {
        this.mobileLatLng(this.currentVehicle, true);
      }
    },
    getCluster(val) {
      this.initMap();
      this.setMarker(val);
    },
  },
  mounted() {
    this.initMap();

    // recaptchaScript.setAttribute(
    //   'src',
    //   'https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=visualization&callback=initMap'
    // );
  },
  methods: {
    initMap() {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: this.center,
        zoom: 12,
        maxZoom: 35,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
      });

      // const recaptchaScript = document.createElement('script');
      // recaptchaScript.setAttribute(
      //   'src',
      //   'https://maps.googleapis.com/maps/api/js?key=' +
      //     process.env.GOOGLE_MAPS_API_KEY +
      //     '&libraries=visualization&callback=initMap'
      // );
      // document.head.appendChild(recaptchaScript);
    },
    // set marker
    setMarker(locations) {
      this.setMapOnMarkersAll(null);

      this.markers = [];
      this.heatRange = [];
      const data = [];
      locations.forEach((location) => {
        const marker = new window.google.maps.Marker({
          mapTypeId: {
            id: location.vehiclePlate + location.startTime,
          },
          position: { lat: location.startLat, lng: location.startLng },
        });
        data.push({ lat: location.startLat, lng: location.startLng });

        window.google.maps.event.addListener(marker, 'click', () => {
          this.showInfo(location, marker);
        });

        this.markers.push(marker);

        // this.heatRange.push(
        //   new window.google.maps.LatLng(location.startLat, location.startLng)
        // );
        this.heatRange.push({
          location: new window.google.maps.LatLng(
            location.startLat,
            location.startLng
          ),
          weight: 5,
        });
      });
      const markers = this.markers;
      const map = this.map;
      new MarkerClusterer({ markers, map });

      // const heatmap = new HeatmapOverlay(map, {
      //   // radius should be small ONLY if scaleRadius is true (or small radius is intended)
      //   radius: 2,
      //   maxOpacity: 1,
      //   // scales the radius based on map zoom
      //   scaleRadius: true,
      //   // if set to false the heatmap uses the global maximum for colorization
      //   // if activated: uses the data maximum within the current map boundaries
      //   //   (there will always be a red spot with useLocalExtremas true)
      //   useLocalExtrema: true,
      //   // which field name in your data represents the latitude - default "lat"
      //   latField: 'lat',
      //   // which field name in your data represents the longitude - default "lng"
      //   lngField: 'lng',
      //   // which field name in your data represents the data value - default "value"
      //   valueField: 'count',
      // });

      // heatmap.setData({ max: 8, data: this.data });

      const heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: this.heatRange,
        maxIntensity: 10,
        radius: 10,
      });
      heatmap.setMap(this.map);

      // heatmap.set('radius', 20);

      this.setMapOnMarkersAll(this.map);
    },

    // getPoints(location) {
    //   const range = [];
    //   location.forEach((item) => {
    //     range.push(new window.google.maps.LatLng(item.lng, item.startLng));
    //   });
    //   return range;
    // },
    // show info window
    showInfo(location, marker) {
      let showplay = 'none';
      if (location.mediaFullId) {
        showplay = '';
      }
      const ref = this;
      const infowindow = new window.google.maps.InfoWindow({
        content: `
          <div id="content">
            <div style="text-align:center;">
              <h3>${location.vehiclePlate}<h3>
            </div>
            <hr>
            <table style="width:100%">
              <tr>
                <th align="left">${this.$t('Driver')}:</th>
                <td align="left">${location.driverName}</td>
              </tr>
              <tr>
                <th align="left">${this.$t('startTime')}:</th>
                <td align="left">${ref.formatDate(location.startTime)}</td>
              </tr>
              <tr>
                <th align="left">${this.$t('startSpeed')}:</th>
                <td align="left" >${location.startSpeed} </td>
              </tr>
              <tr>
                <th align="left">${this.$t('duration')}:</th>
                <td align="left">${
                  location.duration ? location.duration : ''
                }</td>
              </tr>
              <tr style="display: ${showplay}">
                <th align="center">
                  <button id="play" class="vBtn">
                    <img class="play-icon" src="https://earth.google.com/images/kml-icons/track-directional/track-4.png" />
                    ${this.$t('PlayVideo')}
                  </button>
                </th>
              </tr>
            </table>
          </div>
                  `,
        maxWidth: 280,
      });

      if (lastOpenedInfoWindow) {
        lastOpenedInfoWindow.close();
        document.removeEventListener('click',this.lastClickCallback);
      }
      infowindow.open(this.map, marker);
      lastOpenedInfoWindow = infowindow;


      // click event
      const clickCallback = this.emitPlayVideo('play',location.mediaFullId);
      document.addEventListener('click', clickCallback);
      this.lastClickCallback = clickCallback;
    },
    setMapOnMarkersAll(map) {
      this.markers.forEach((item) => {
        item.setMap(map || null);
      });
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    emitPlayVideo(targetId,fullId) {
      return (event) => {
      if (event.target.id && event.target.id === targetId) {
          this.$emit('playVideo', fullId);
        }
      }
    }
  },
};
