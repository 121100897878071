
import Drawer from '@/components/core/Drawer';
import AppBar from '@/components/core/AppBar';
import View from '@/components/core/View';
import ApiService from '@/services/api-service.js';
import { mapMutations } from 'vuex';

export default {
  components: {
    CoreDrawer: Drawer,
    CoreAppBar: AppBar,
    CoreView: View,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data: () => ({
    load: false,
    expandOnHover: false,
    isAlive: true,
    isAuth: false,
  }),
  watch: {
    $route(val) {
      if (val.params.title) {
        this.title = val.params.title;
      }
      this.pushTitle(val);
    },
  },
  mounted() {
    this.getLang();
    this.isAuth = this.$store.state.app.isAuthenticated;
    if (this.isAuth) {
      this.getAuth();
    } else {
      //! remove on 2022/07/22 , should be done in middleware
      // this.$msal.signIn();
    }
  },
  methods: {
    ...mapMutations('app', [
      'get_Lang',
      'set_User',
      'set_FirstPage',
      'set_Title',
      'set_Menu',
      'set_Function',
      'set_Company',
      'set_Companies',
      'set_isAdmin',
      // 'set_MenuActive',
      // 'set_subMenuActive',
    ]),
    getLang() {
      this.get_Lang();
    },
    reload() {
      console.log('reload');
      this.isAlive = false;
      this.$nextTick(() => {
        this.isAlive = true;
      });
    },
    pushTitle(val) {
      if (val) {
        this.set_Title(val);
      }
    },
    getAuth() {
      console.log('Get Auth');
      ApiService.getUserRights(this.$axios).then((response) => {
        // console.log(response);
        if (response) {
          if (response.isSuspended) {
            this.$router.push('/Suspended');
            return;
          }

          let firstPage = '';
          this.set_Menu(response.menuLinks);
          // this.$store.state.app.menuLinks = response.menuLinks;
          const validPath = [];
          this.$store.state.app.menuLinks.forEach((item) => {
            if (item.active) {
              if (firstPage === '') {
                firstPage = item.to;
              }
              if (item.children === null) {
                validPath.push('/' + item.to);
              } else {
                item.children.forEach((child) => {
                  if (child.active) {
                    validPath.push('/' + child.to.replace(/-/g, '/'));
                  }
                });
              }
            }
          });

          this.set_Companies(response.companyAllow);
          const user = {
            id: response.id,
            role: response.systemRole,
            name: response.username,
          };
          this.set_User(user);
          this.set_FirstPage(firstPage);
          this.set_Title(this.$route);

          //! 2022/07/22
          //! dont know why redirect again, remove
          /*
          if (this.$route.path === '/') {
            this.$router.push(this.$route.path + firstPage);
          } else {
            this.$router.push(this.$route.path);
          }
          */

          //! if first time , company is not available in chrome local storage yet, so store company is undefined, then default to first companyAllow
          if (
            this.$store.state.app.company === undefined ||
            this.$store.state.app.company === null
          ) {
            if (response.companyAllow && response.companyAllow.length > 0)
              this.set_Company(response.companyAllow[0].code);
            console.log(
              'default set company %s',
              this.$store.state.app.company
            );
          }

          response.companyAllow.forEach((item) => {
            if (item.code === this.$store.state.app.company) {
              this.set_Function(item.pageFunctionAllow);
              this.set_isAdmin(item.isAdmin);
            }
          });

          //* when route not valid , will go to first page
          const routewithoutslash =
            this.$route.path.slice(-1) === '/'
              ? this.$route.path.slice(0, -1)
              : this.$route.path;
          if (!validPath.includes(routewithoutslash)) {
            this.$router.push('/' + firstPage);
          }
        }
      });
    },
  },
};
