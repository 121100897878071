
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';

export default {
  name: 'Company',
  components: {
    // downloadExcel: () => {
    //   if (process.client) {
    //     return import('vue-json-excel');
    //   }
    // },
    CompanyDetailDialog: () => {
      if (process.client) {
        return import('@/components/dialog/CompanyDetailDialog.vue');
      }
    },
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  data: () => ({
    // Search field
    Search: '',
    // For datatables
    companyList: [],
    downloadList: [],
    itemsPerPage: 5,

    // Dialog
    addDialog: false,
    updateValue: {},
    componentKey: 0,
    displayDelete: false,
    // Before delete items
    beforeDelete: false,
    itemsToBeDeleted: {},
    showMsg: false,
    msg: '',
  }),
  computed: {
    headers() {
      let header = [];
      if (this.$store.state.app.userRole === 'HQ') {
        header = [
          { text: this.$t('isDistributor'), value: 'isDistributor' },
          { text: this.$t('Distributor'), value: 'distributor' },
        ];
      }
      header.push(
        { text: this.$t('company.code'), value: 'code' },
        { text: this.$t('company.name'), value: 'name' },
        {
          text: this.$t('company.functions'),
          value: 'companyFunctions',
          width: 400,
        },
        { text: this.$t('company.timeZone'), value: 'timeZone' },
        // * features
        { text: this.$t('settingCompanies.SPW'), value: 'SPW' },
        { text: this.$t('settingCompanies.IDL'), value: 'IDL' },
        { text: this.$t('settingCompanies.ADDRESS'), value: 'ADDRESS' },
        { text: this.$t('settingCompanies.BLACKSCREEN'), value: 'BLACKSCREEN' },

        { text: this.$t('isActive'), value: 'isActive' },
        { text: this.$t('crudActions'), value: 'crudActions', sortable: false }
      );
      return header;
      // return [
      //   { text: this.$t('company.code'), value: 'code' },
      //   { text: this.$t('company.name'), value: 'name' },
      //   { text: this.$t('isDistributor'), value: 'isDistributor' },
      //   { text: this.$t('Distributor'), value: 'Distributor' },
      //   {
      //     text: this.$t('company.functions'),
      //     value: 'companyFunctions',
      //     width: 400,
      //   },
      //   { text: this.$t('company.timeZone'), value: 'timeZone' },
      //   { text: this.$t('isActive'), value: 'isActive' },
      //   { text: this.$t('crudActions'), value: 'crudActions', sortable: false },
      // ];
    },
  },
  watch: {
    displayDelete() {
      setTimeout(() => (this.displayDelete = false), 3000);
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setDownloadList();
    },
    // Change company
    // '$store.state.app.company'(val) {
    //   this.getCompanies();
    //   // this.setDownloadList();
    // },
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      this.wrapShowloading(
        ApiService.getCompanies(this.$axios)
          .then((response) => {
            console.log(response);
            if (response) {
              this.companyList = response.items;
              this.setDownloadList();
            }
          })
          .catch({})
      );
    },
    setDownloadList() {
      if (this.companyList.length > 0) {
        this.downloadList = [];
        this.companyList.forEach((item, i) => {
          this.downloadList.push({});
          this.downloadList[i][this.$t('company.code')] = item.code;
          this.downloadList[i][this.$t('company.name')] = item.name;
          this.downloadList[i][this.$t('company.timeZone')] = item.timeZone;

          // * features
          this.downloadList[i][this.$t('settingCompanies.SPW')] =
            item.features.SPW ?? false;
          this.downloadList[i][this.$t('settingCompanies.IDL')] =
            item.features.IDL ?? false;
          this.downloadList[i][this.$t('settingCompanies.ADDRESS')] =
            item.features.ADDRESS ?? false;
          this.downloadList[i][this.$t('settingCompanies.BLACKSCREEN')] =
            item.features.BLACKSCREEN ?? false;

          this.downloadList[i][this.$t('isActive')] = item.isActive;
        });
      }
    },
    updateData(val) {
      this.updateValue = val;
      this.addDialog = true;
    },
    beforeDeleteData(val) {
      console.log(val);
      this.beforeDelete = true;
      this.itemsToBeDeleted = val;
    },
    deleteData(val) {
      console.log(val);
      this.beforeDelete = false;

      // Delete the records in Vuetify datatable and refresh.
      this.companyList = this.companyList.filter((item) => item.id !== val.id);
      this.forceRerender();
      this.displayDelete = true;

      // Delete the records in API
      ApiService.deleteCompanies(this.$axios, val.id).then().catch({});
    },
    downloadCSV() {
      // eslint-disable-next-line
      let csvcontent = JSON.parse(JSON.stringify(this.companyList));
      console.log(csvcontent);

      // Acquire headers
      const csvheaders = this.headers.map((obj) => obj.value);

      // Format companyList as same as display
      csvcontent.forEach((item) => {
        Object.entries(item).forEach(([key]) => {
          // If none of the key in csvcontent satisfies the headers, remove them.
          if (!csvheaders.includes(key)) delete item[key];
        });
      });

      // Possible refactor: Use .reduce() or Object.fromEntries()
      // Pushing the values to a new and renamed object.
      // this.copyList.forEach((item) => {
      //   csvcontent.push({
      //     Code: item.code,
      //     Name: item.name,
      //     'Time Zone': item.timeZone,
      //     isActive: item.isActive,
      //   });
      // });

      return csvcontent;
    },
    addData() {
      this.addDialog = true;
    },
    clearUpdateValue() {
      this.updateValue = {};
    },
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerenderOnSubmit(val) {
      console.log('rerender');
      this.componentKey += 1;
      this.companyList = [];
      this.getCompanies();
      this.showMsg = true;
      this.msg = val.message;
    },
  },
};
