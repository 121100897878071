
import ApiService from '@/services/api-service.js';

/* eslint-disable prettier/prettier */
export default {
  props: {
    value: Boolean,
    userName: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      dialogOk: false,
      valid: true,
      password: '',
      confirmPassword: '',
      rules: {
        // required: (value) => !!value || 'Required.',
        counter: (value) =>
          !value ||
          (value.length <= 8 && value.length >= 16) ||
          this.$t('PasswordHint'),
        password: (value) => {
          const pattern = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&amp;*\-_+=[\]{}|\\:',?/`~&quot;();!]|\.(?!@)){8,16}$/;
          return pattern.test(value) || this.$t('PasswordHint');
        },
      },
      // passwordRules: [
      //   (v) => !!v || this.$t('PasswordRequired'),
      //   (v) => (v || '').length >= 4 || this.$t('PasswordMin'),
      // ],
      confirmPasswordRules: [
        // (v) => !!v || this.$t('PasswordRequired'),
        (v) => v === this.password || this.$t('PasswordNotMatch'),
      ],
      showNew: false,
      showConfirm: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    confirm() {
      if (this.$refs.form.validate()) {
        const prom = this.userName ? ApiService.changeUserPassword(this.$axios, {
          username : this.userName,
          newPassword: this.password,
        }) : ApiService.changePassword(this.$axios, {
          newPassword: this.password,
        })
        
        prom.then((response) => {
          this.password = "";
          this.confirmPassword = "";
          if (response) {
            if (response.success) {
              this.$emit('result', response.success);
              this.show = false;
            }
          }
        });
      }
    },
    reset() {
      this.$refs.form.reset();
      this.show = false;
    },
  },
};
