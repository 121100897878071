
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';
import moment from 'moment';

export default {
  name: 'User',
  components: {
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
    UserDetailDialog: () => {
      if (process.client) {
        return import('@/components/dialog/UserDetailDialog');
      }
    },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
    },
  },
  data: () => ({
    // 搜尋框輸入值
    Search: '',
    // datatable 的欄寬平均
    calculateWidths: true,
    downloadList: [],
    ItemsList: [],
    // 新增功能Dialog
    addDialog: false,
    updateValue: {},
    componentKey: 0,
    // displayDelete: false,
    // Before delete items
    beforeDelete: false,
    itemsToBeDeleted: {},
    chgPwDialog: false,
    changePasswordusername: '',
    UpdateAuth: false,
    showMsg: false,
    msg: '',
  }),
  computed: {
    Headers() {
      return [
        { text: this.$t('User.username'), value: 'username' },
        { text: this.$t('User.displayName'), value: 'displayName' },
        { text: this.$t('User.email'), value: 'email' },
        { text: this.$t('User.companyRole'), value: 'companyRole' },
        { text: this.$t('User.department'), value: 'department' },
        { text: this.$t('User.lang'), value: 'lang' },
        { text: this.$t('User.state'), value: 'isSuspended' },
        { text: this.$t('isViewSelfOnly'), value: 'isViewSelfOnly' },
        { text: this.$t('Setting.lastLoginAt'), value: 'lastLoginAt' },
        { text: this.$t('Setting.lastUpdated'), value: 'updatedAt' },
        {
          text: this.$t('Setting.crudActions'),
          value: 'crudActions',
          sortable: false,
        },
      ];
    },
    userName: {
      get() {
        return this.$store.state.app.userName;
      },
    },
  },
  watch: {
    // displayDelete() {
    //   setTimeout(() => (this.displayDelete = false), 3000);
    // },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      this.setDownloadList();
    },
    // Change company
    '$store.state.app.company'(val) {
      this.getUsers();
    },
    '$store.state.app.userRole'(val) {
      this.checkAuth();
    },
  },
  mounted() {
    this.getUsers();
    this.checkAuth();
  },
  methods: {
    getUsers() {
      this.ItemsLis = [];
      this.wrapShowloading(
        ApiService.getUsers(this.$axios)
          .then((response) => {
            if (response) {
              this.ItemsList = response.items;
              this.setDownloadList();
            }
          })
          .catch({})
      );
    },
    checkAuth() {
      this.UpdateAuth = false;
      const val = this.$store.state.app.userRole;
      if (
        val === 'HQ' ||
        val === 'Administrator' ||
        this.$store.state.app.isAdmin
      ) {
        this.UpdateAuth = true;
      }
    },
    setDownloadList() {
      if (this.ItemsList.length > 0) {
        this.downloadList = [];
        this.ItemsList.forEach((item, i) => {
          this.downloadList.push({});
          this.downloadList[i][this.$t('User.username')] = item.username;
          this.downloadList[i][this.$t('User.displayName')] = item.displayName;
          this.downloadList[i][this.$t('User.email')] = item.email;
          this.downloadList[i][this.$t('User.companyRole')] = item.companyRole
            ? item.companyRole
            : '';
          this.downloadList[i][this.$t('User.department')] = item.department
            ? item.department
            : '';
          this.downloadList[i][this.$t('User.lang')] = item.lang
            ? item.lang
            : '';
          this.downloadList[i][this.$t('User.state')] = !item.isSuspended;
          this.downloadList[i][this.$t('isViewSelfOnly')] = item.isViewSelfOnly;
          this.downloadList[i][this.$t('Setting.lastLoginAt')] =
            item.lastLoginAt
              ? moment(item.lastLoginAt).format('YYYY-MM-DD HH:mm:ss')
              : '';
          this.downloadList[i][this.$t('Setting.lastUpdated')] =
            moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') +
            ' (' +
            item.updatedBy +
            ')';
        });
      }
    },
    updateData(val) {
      this.updateValue = val;
      this.addDialog = true;
    },
    changePassword(val) {
      this.chgPwDialog = true;
      this.changePasswordusername = val.username;
    },
    changePwResult(data) {
      this.showMsg = true;
      this.changePasswordusername = undefined;
      this.msg = data
        ? this.$t('ChangePassowrdSuccess')
        : this.$t('ChangePassowrdfail');
    },
    beforeDeleteData(val) {
      console.log(val);
      this.beforeDelete = true;
      this.itemsToBeDeleted = val;
    },
    deleteData(val) {
      this.beforeDelete = false;

      // Delete the records in Vuetify datatable and refresh.
      this.ItemsList = this.ItemsList.filter((item) => item.id !== val.id);
      this.forceRerender();
      // this.displayDelete = true;

      this.showMsg = false;
      ApiService.deleteUser(this.$axios, val.userId)
        .then((response) => {
          this.showMsg = true;
          this.msg = response.message;
        })
        .catch((error) => {
          this.showMsg = true;
          console.log('ERROR:  ' + JSON.stringify(error.response));
          this.msg = error.response;
        });
    },
    downloadCSV() {
      // eslint-disable-next-line
      let csvcontent = JSON.parse(JSON.stringify(this.ItemsList));

      // Acquire headers
      const csvheaders = this.Headers.map((obj) => obj.value);

      // Special add
      csvheaders.push('updatedBy', 'updatedAt');

      // Format companyList as same as display
      csvcontent.forEach((item) => {
        Object.entries(item).forEach(([key]) => {
          // If none of the key in csvcontent satisfies the headers, remove them.
          if (!csvheaders.includes(key)) delete item[key];
        });
      });
      return csvcontent;
    },
    addData() {
      this.addDialog = true;
    },
    clearUpdateValue() {
      this.updateValue = {};
    },
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerenderOnSubmit(val) {
      this.componentKey += 1;
      this.getUsers();
      this.showMsg = true;
      this.msg = val.message;
    },
    DescArray(array) {
      // eslint-disable-next-line
      let newarray = [];
      array.forEach((item) => {
        newarray.unshift(item);
      });
      return newarray;
    },
  },
};
