import { render, staticRenderFns } from "./LiveStream.vue?vue&type=template&id=04045123"
import script from "./LiveStream.vue?vue&type=script&lang=js"
export * from "./LiveStream.vue?vue&type=script&lang=js"
import style0 from "./LiveStream.vue?vue&type=style&index=0&id=04045123&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Jessibuca: require('/home/vsts/work/1/s/components/Jessibuca.vue').default})
