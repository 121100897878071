
import ApiService from '@/services/api-service.js';
import moment from 'moment';

export default {
  name: 'SpeedingWarningList',
  components: {
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  props: {
    way: {
      type: Object,
      default: () => {},
    },
    query: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedChips: [],
      shownTableName: 'detail',
      apiReturnData: null,
      tabLocalekey: {
        detail: 'SpeedingWarningList.Detail',
        vehicle: 'SpeedingWarningList.SummaryByVehicle',
        driver: 'SpeedingWarningList.SummaryByDriver',
      },
    };
  },
  computed: {
    //* to be used in slot bind
    newTabClickSlotPropAttr: {
      get() {
        // * api change to name for both, instead of driver or plate
        return 'item.name';
        /*
        let name = '';
        if (this.shownTableName === 'vehicle') name = 'plate';
        else {
          name = this.shownTableName;
        }
        return `item.${name}`;
        */
      },
    },

    // from index to the warning type to be used for filter
    selectedChipWarningTypeText: {
      get() {
        const selectedWarning = [];
        for (const s of this.selectedChips) {
          selectedWarning.push(this.distinctWarningsInTable[s][0]);
        }
        return selectedWarning;
      },
    },

    // unique warningTypeText to be used in chips
    distinctWarningsInTable: {
      get() {
        if (!(this.apiReturnData && this.apiReturnData.warnings)) {
          return [];
        }
        return Object.entries(
          this.apiReturnData.warnings.reduce((p, c) => {
            const warningTypeText = c.warningTypeText;
            if (!(warningTypeText in p)) {
              p[warningTypeText] = 0;
            }
            p[warningTypeText]++;
            return p;
          }, {})
        );
      },
    },
    localeKey: {
      get() {
        return this.tabLocalekey[this.shownTableName];
      },
    },
    downloadList: {
      get() {
        return this.tableData.map((item) => {
          return this.tableHeaders.reduce((prev, head) => {
            if (head.value === 'startTime')
              prev[head.text] = this.momentformatDate(item[head.value]);
            else if (head.value === 'warningRate') {
              prev[head.text] = `${parseFloat(
                (item[head.value] * 100).toFixed(2)
              )}%`;
            } else prev[head.text] = item[head.value] ?? '';
            return prev;
          }, {});
        });
      },
    },
    tableHeaders() {
      if (this.tableData === undefined || this.tableData.length === 0)
        return [];
      if (this.shownTableName === 'detail') {
        return [
          {
            text: this.$t('tripId'),
            value: 'tripId',
          },
          {
            text: this.$t('SpeedingWarningList.warningTypeText'),
            value: 'warningTypeText',
          },
          { text: this.$t('startTime'), value: 'startTime' },
          {
            text: this.$t('SpeedingWarningList.vehiclePlate'),
            value: 'vehiclePlate',
          },
          { text: this.$t('driver'), value: 'driver' },
          { text: this.$t('duration'), value: 'duration' },

          { text: this.$t('startSpeed'), value: 'startSpeed' },
          { text: this.$t('endSpeed'), value: 'endSpeed' },
          { text: this.$t('topSpeed'), value: 'topSpeed' },
          { text: this.$t('speedLimit'), value: 'speedLimit' },
        ];
      }
      return Object.keys(this.tableData[0])
        .filter((key) => !['targetId', 'warningRateText'].includes(key))
        .map((key) => {
          let text = this.$t(`SpeedingWarningList.${key}`);
          if (key === 'name' && this.shownTableName === 'vehicle') {
            text = this.$t('SpeedingWarningList.plate');
          } else if (key === 'name' && this.shownTableName === 'driver') {
            text = this.$t('SpeedingWarningList.driver');
          }
          return {
            text,
            value: key,
          };
        });
    },
    tableData() {
      switch (this.shownTableName) {
        case 'detail': {
          if (
            this.apiReturnData === null ||
            this.apiReturnData.warnings === undefined
          ) {
            return [];
          }

          console.log('selected warnings %s', this.selectedChipWarningTypeText);
          const filteredData = this.apiReturnData.warnings.filter((w) =>
            this.selectedChipWarningTypeText.includes(w.warningTypeText)
          );

          return filteredData;
        }
        case 'vehicle':
          return this.apiReturnData.vehicleSummary;
        case 'driver':
          return this.apiReturnData.driverSummary;
      }
      return [];
    },
  },
  watch: {
    selectedChips() {
      this.$emit('dataReady', {
        wayId: this.way.wayId,
        warnings: this.tableData,
      });
    },
    distinctWarningsInTable(val) {
      this.selectedChips = [];
      val.forEach((_, ind) => {
        this.selectedChips.push(ind);
      });
      console.log('set chips %s', this.selectedChips);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    onNewTabClicked({ item, tab }) {
      console.log('%s, show %s: %s', tab, this.shownTableName, item.name);
      this.$emit('newTab', {
        tab,
        type: this.shownTableName,
        id: item.name,
      });
    },
    getData() {
      this.query.WayId = this.way.wayId;
      this.wrapShowloading(
        ApiService.getWayDetail(this.$axios, this.query).then((data) => {
          this.apiReturnData = data;
          //! move to selectedChips because it will be called also when first get data
          /* 
          this.$emit('dataReady', {
            wayId: this.way.wayId,
            warnings: data.warnings,
          });
*/
        })
      );
    },
    showData(tableName) {
      this.shownTableName = tableName;
    },
  },
};
