
/* eslint-disable prettier/prettier */
import axios from 'axios';

export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    download() {
      const url =
        process.env.M3_BASE_API + 'api/tool/manual?lang=' + this.$i18n.locale;
      axios.get(url, { responseType: 'blob' }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'M3-Manual.pdf';
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
  },
};
