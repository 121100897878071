
import moment from 'moment';
import ApiService from '@/services/settings-service.js';

export default {
  filters: {
    formatYear(date) {
      return moment(date).format('YYYY');
    },
  },
  props: {
    value: Boolean,
    warningValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      WarningType: '',
      isActive: false,
      recommendationScore: 0,
      maxScore: 0,
      ScoreWeighting: 0,
      // showMsg: false,
      // msg: '',
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    show(val) {
      this.WarningType = '';
      this.isActive = false;
      this.functions = [];
      this.recommendationScore = 0;
      this.maxScore = 0;
      this.ScoreWeighting = 0;
      if (val) {
        if (Object.keys(this.warningValue).length === 0) {
          this.isAdd = true;
          this.isActive = true;
        } else {
          this.WarningType = this.$t(this.warningValue.warningType);
          this.isActive = this.warningValue.isActive;
          this.recommendationScore = this.warningValue.recommendationScore;
          this.maxScore = this.warningValue.maxScore;
          this.ScoreWeighting = this.warningValue.ScoreWeighting
            ? this.warningValue.ScoreWeighting
            : 0;
        }
      } else {
        this.isAdd = false;
        this.$emit('clearWarningValue');
      }
    },
  },
  methods: {
    submit() {
      const query = {
        WarningType: this.warningValue.warningType,
        RecommendationScore: this.recommendationScore,
        MaxScore: this.maxScore,
        isActive: this.isActive,
        ScoreWeighting: this.ScoreWeighting,
      };
      ApiService.updateWarningList(this.$axios, query)
        .then((response) => {
          if (response.success) {
            this.show = false;
            this.$emit('WarningOnSubmit', response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
