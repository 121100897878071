
/* eslint-disable vue/require-default-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'Chart',
  props: {
    // The canva's width.
    width: {
      type: Number,
      validator: (value) => value > 0,
    },

    // The canvas's height.
    height: {
      type: Number,
      validator: (value) => value > 0,
    },

    // The chart's type
    type: String,

    // The chart's data.labels
    labels: Array,

    // The chart's label in English, for chart Popup use.
    rawLabels: Array,

    // The chart's data.datasets
    datasets: {
      type: Array,
      required: true,
    },

    // The chart's options
    // eslint-disable-next-line vue/require-default-prop
    options: Object,

    gradient: Boolean,
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    options(newOptions) {
      console.log(newOptions);
      this.chart.data.options = newOptions;
      this.chart.update();
    },
    datasets(newDatasets) {
      // Replace the datasets and call the update() method on Chart.js
      // instance to re-render the chart
      this.chart.data.datasets = newDatasets;
      this.chart.update();
    },
    labels(newLabels) {
      this.chart.data.labels = newLabels;
      this.chart.update();
    },
    // height(val) {
    //   //   // this.$refs.myChart.height = 400;
    //   //   // this.chart.height = 600;
    //   this.chart.update();
    // },
  },
  // SURPRESS NO-NEW ESTLINT ERROR
  // DO NOT DELETE ESLINT-DISABLE NO-NEW COMMENT
  /* eslint-disable no-new */
  mounted() {
    this.chart = new Chart(
      this.$refs.myChart,
      {
        plugins: [ChartDataLabels],
        type: this.type,
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: this.options,
      },
      { onClick: this.handle }
    );
    // polar only
    if (this.chart.config.type === 'polarArea') {
      setTimeout(() => {
        this.formatLabel(this.chart);
      }, 300);
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handle(point, event) {
      const item = event[0];
      this.$emit('on-receive', {
        index: item._index,
        backgroundColor: item._view.backgroundColor,
        value: this.values[item._index],
      });
    },
    formatLabel(chart) {
      // chart.options.plugins.datalabels.anchor = 'start';
      // chart.options.plugins.datalabels.align = 'end';
      // chart.options.plugins.datalabels.offset = chart.height / 2;

      // chart.options.plugins.datalabels.formatter = function (value, context) {
      //   return context.chart.data.labels[context.dataIndex];
      // };
      chart.options.onClick = this.sendData;
      chart.update();
    },
    sendData(point, evt) {
      const item = evt[0];
      if (item) {
        const currentData = this.chart.data.datasets[0].data[item.index];
        const currentLabel = this.chart.data.labels[item.index];
        const currentRawLabel = this.rawLabels
          ? this.rawLabels[item.index]
          : null;
        this.currentPoint = {
          score: currentData,
          label: currentLabel,
          rawLabel: currentRawLabel,
          index: item._index,
        };
        this.chart.update();
        const currentPoint = this.currentPoint;
        this.$emit('currentPoint', currentPoint);
      }
    },
  },
};
