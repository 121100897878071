
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  name: 'AutocompleteWithSelectAll',
  components: {
    Treeselect: () => {
      if (process.client) {
        return import('@riophae/vue-treeselect');
      }
    },
  },
  props: {
    landmarkGroups: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
    },
    clazz: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedItems: this.value,
    };
  },
  computed: {
    /*
    selectedItems: {
      get() {
        return this.value;
      },
      set(newVal) {
        console.log(newVal);
        this.$emit('input', newVal);
      },
    },
    */
    cLikesAllItems: {
      get() {
        // console.log('cLikesAllItems', this.selectedItems);
        return this.selectedItems.length === this.items.length;
      },
    },
    cLikesSomeItems() {
      return this.selectedItems.length > 0 && !this.cLikesAllItems;
    },

    cIcon() {
      if (this.cLikesAllItems) return 'mdi-close-box';
      if (this.cLikesSomeItems) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    value(val) {
      this.selectedItems = val;
    },
    selectedItems(newVal) {
      this.$emit('input', newVal);
    },
  },

  methods: {
    onUpdateModelValue(newVal) {
      this.selectedItems = newVal;
    },
    selectAllItems() {
      this.$nextTick(() => {
        const newVal = this.cLikesAllItems
          ? []
          : this.items.map((i) => i.value ?? i);
        this.selectedItems = newVal;
      });
    },
  },
};
