
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/api-service.js';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'WarningOption',
  components: {
    Treeselect: () => {
      if (process.client) {
        return import('@riophae/vue-treeselect');
      }
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      treeselectFieldValid: false,
      requiredRules: [
        (v) => {
          if (Array.isArray(v)) {
            return !!(v && v.length) || this.$t('Required');
          }
          return !!v || this.$t('Required');
        },
      ],
      optionType: [],
      getType: '',
      getOptions: [],
      optionsList: [],
      optionTypeList: [],
      normalizer(node) {
        if (node.children == null || node.children.length === 0) {
          delete node.children;
        }
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        };
      },
    };
  },
  computed: {
    likesAllItems() {
      return this.getOptions.length === this.optionsList.length;
    },
    likesSomeItems() {
      return this.getOptions.length > 0 && !this.likesAllItems;
    },
    icon() {
      if (this.likesAllItems) return 'mdi-close-box';
      if (this.likesSomeItems) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    treeselectClass() {
      return {
        'treeselect-invalid': !this.treeselectFieldValid,
      };
    },
  },
  watch: {
    optionType(val) {
      this.optionsList = [];
      this.getOptions = [];
    },
    options(val) {
      this.optionType = '';
    },
  },
  methods: {
    // validate form , called from page WarningData to validate each form
    formValidate() {
      this.$refs.form.validate();
    },
    validateTreeselect() {
      this.treeselectFieldValid = this.getOptions.length > 0;
    },
    getLang(item) {
      return this.$t(item.text);
    },
    onChange() {
      if (this.optionType.type === 'DropDown') {
        this.getOptionList(this.optionType.url);
      }
    },
    forDecimal($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.getOptions.includes('.'))
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 1 decimal places
      if (
        this.getOptions != null &&
        this.getOptions.includes('.') &&
        this.getOptions.split('.')[1].length > 0
      ) {
        $event.preventDefault();
      }
    },
    getOptionList(url) {
      ApiService.getWarningDataOptionList(this.$axios, url).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            if (item.children) {
              this.optionsList.push({
                id: item.id ? item.id : item,
                name: item.name ? item.name : item,
                children: item.children ? item.children : null,
              });
            } else {
              this.optionsList.push({
                id: item.id ? item.id : item,
                name: item.name ? item.name : this.$t(item),
              });
            }
          });
        }
      });
    },

    selectAll() {
      this.$nextTick(() => {
        if (this.likesAllItems) {
          this.getOptions = [];
        } else {
          this.getOptions = this.optionsList.slice();
        }
      });
    },
  },
};
