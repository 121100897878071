
export default {
  name: 'DashboardCoreFooter',

  data: () => ({
    links: [
      {
        href: '#',
        text: 'Creative Tim',
      },
      {
        href: '#',
        text: 'About Us',
      },
      {
        href: '#',
        text: 'Blog',
      },
      {
        href: '#',
        text: 'Licenses',
      },
    ],
  }),
};
