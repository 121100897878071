
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: () => '',
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
      default: () => '',
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // search: '',
      // dheaders: this.headers,
      // dtableData: this.tableData,
      dSortBy: this.sortBy,
      dSortDesc: this.sortDesc,
    };
  },
  computed: {
    cHeaders() {
      return this.headers;
    },
    cTableData() {
      return this.tableData;
    },
  },
  /*
  watch: {
    headers(val) {
      this.dheaders = val;
    },
    tableData(val) {
      this.dtableData = val;
    },
  },
  */
};
