
/* eslint-disable @typescript-eslint/no-unused-vars */
import ApiService from '@/services/settings-service.js';

export default {
  props: {
    value: Boolean,
    record: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isAdd: false,
      name: '',
      parent: '',
      description: '',
      companyGroupList: [],
      rules: {
        required: (value) => !!value || this.$t('Required'),
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    show(val) {
      this.$refs.form.resetValidation();
      this.name = '';
      this.parent = '';
      this.description = '';
      if (val) {
        if (Object.keys(this.record).length === 0) {
          this.isAdd = true;
        } else {
          this.name = this.record.name;
          this.description = this.record.description;
        }

        this.getCompanyGroups();
      } else {
        this.isAdd = false;
        this.$emit('clearUpdateValue');
      }
    },
  },
  methods: {
    getCompanyGroups() {
      ApiService.getCompanyGroups(this.$axios)
        .then((response) => {
          this.companyGroupList = response.items.map((item) => {
            if (this.record.parentId === item.id) {
              this.parent = item.id;
            }
            return {
              text: item.name,
              value: item.id,
            };
          });
          /*
          response.items.forEach((item) => {
            this.companyGroupList.push({
              text: item.name,
              value: item.id,
            });
            if (this.record.parentId === item.id) {
              this.parent = item.id;
            }
          });
          */
        })
        .catch({});
    },
    submit() {
      if (this.$refs.form.validate()) {
        const query = {
          Name: this.name,
          ParentId: this.parent,
          Description: this.description,
        };
        if (this.isAdd) {
          ApiService.addCompanyGroup(this.$axios, query)
            .then((response) => {
              if (response.success) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          ApiService.updateCompanyGroup(this.$axios, this.record.id, query)
            .then((response) => {
              if (response.success) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        this.show = false;
      }
    },
  },
};
